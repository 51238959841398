$primary-color: #15cdca;
$secondary-color: #fd6a57;
$dark-color: #121212;
$gray-600: #6c757d;
$slate-color: #94a3b8;

#digistall {
  .my-purchase {
    font-weight: 600;

    .btn-search {
      cursor: pointer;
      border: 1px solid $primary-color;
      background-color: $primary-color;
      width: 100%;
      color: white;
      &:hover {
        background-color: #11b1ae;
        border: 1px solid #11b1ae;
      }
    }

    .form-select-sm {
      &:focus {
        outline: none;
      }
    }

    .form-control:active,
    .form-control:focus {
      box-shadow: none !important;
      border: 1px solid $primary-color;
    }

    .content {
      background-color: rgb(248 250 252);
      border-radius: 16px;

      a {
        color: $secondary-color;
      }

      img {
        object-fit: cover;
        width: 65px;
        height: 65px;
      }

      .shop-name {
        color: #64748b;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: $secondary-color;
        }
      }

      .form-check-input:checked {
        background-color: $secondary-color !important;
        border-color: transparent !important;
      }

      .btn-confirm,
      .btn-cancel {
        text-align: center;
        border-radius: 6px;
        font-size: 0.75rem;
        box-shadow: none;
      }

      .button-group {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
      }

      .btn-confirm {
        background-color: $primary-color; // Sử dụng màu chủ đạo của nút search
        color: white;
        border: none;
        padding: 8px 16px;
        transition: background-color 0.3s, color 0.3s;
        margin-top: 0;

        &:hover {
          background-color: #11b1ae; // Màu hover giống nút search
        }
        a {
          color: white !important;
        }
      }

      .btn-cancel {
        color: $gray-600;
        border: 1px solid $gray-600;
        padding: 8px 16px;
        transition: background-color 0.3s, color 0.3s;
        &:hover {
          background-color: $gray-600;
          color: white;
        }
      }

      .badge {
        padding: 4px 12px;
        border-radius: 20px;
        font-size: 0.7rem;
        font-weight: 500;
        letter-spacing: 0.3px;

        &.status-badge {
          text-transform: capitalize;

          &.bg-warning {
            background-color: #fff8e6 !important;
            color: #ffa200;
            border: 1px solid #ffd580;
          }

          &.bg-success {
            background-color: #e6fff2 !important;
            color: #00b74a;
            border: 1px solid #80ffc5;
          }

          &.bg-secondary {
            background-color: #f5f5f5 !important;
            color: #666666;
            border: 1px solid #dddddd;
          }

          &.bg-primary {
            background-color: #e6f4ff !important;
            color: #0d6efd;
            border: 1px solid #80bdff;
          }
        }

        &.fulfillment-badge {
          background-color: #f0f0f0;
          color: #444444;
          border: 1px solid #dddddd;
        }
      }

      .gift-icon {
        font-size: 1.2rem;
      }

      .badge-container {
        display: flex;
        gap: 6px;
        align-items: center;
        flex-wrap: wrap;
      }
    }

    .purchase-item {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      background-color: #fff;
      transition: all 0.3s;
      margin-bottom: 1rem;
      padding: 16px;

      .item-header {
        margin-bottom: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid #eee;
      }

      .badge-container {
        display: flex;
        gap: 8px;
        align-items: center;
        flex-wrap: wrap;
      }

      .order-info {
        margin: 16px 0;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .item-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;
        padding-top: 12px;
        border-top: 1px solid #eee;

        @media (max-width: 576px) {
          flex-direction: column;
          gap: 12px;
          align-items: flex-start;

          .button-group {
            width: 100%;
            justify-content: flex-start;
          }
        }
      }

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
      }

      @media (max-width: 576px) {
        padding: 12px;
        margin-bottom: 0.5rem;

        .item-header {
          margin-bottom: 8px;
          padding-bottom: 8px;
        }

        .badge-container {
          gap: 6px;
        }

        .order-info {
          margin: 12px 0;
          gap: 6px;

          .order-number {
            font-size: 0.75rem;
          }
        }

        .shop-name {
          font-size: 0.75rem !important;
        }
      }
    }

    .bg-primary {
      background-color: rgb(13, 110, 253) !important;
    }

    .date-order {
      font-size: 0.75rem;
      color: $slate-color;
      font-weight: 500;
    }

    .loading-skeleton {
      .skeleton-item {
        animation: skeleton-loading 1s linear infinite alternate;
      }

      .skeleton-text {
        height: 16px;
        background: #e5e7eb;
        border-radius: 4px;
        margin-bottom: 8px;
      }

      .skeleton-badge {
        width: 60px;
        height: 24px;
        background: #e5e7eb;
        border-radius: 12px;
      }

      .skeleton-button {
        width: 80px;
        height: 34px;
        background: #e5e7eb;
        border-radius: 6px;
      }
    }

    @keyframes skeleton-loading {
      0% {
        opacity: 0.6;
      }
      100% {
        opacity: 1;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  #digistall {
    .my-purchase {
      img {
        object-fit: cover;
        width: 40px !important;
        height: 40px !important;
      }

      .date-order {
        font-size: 0.75rem;
      }

      .purchase-item {
        padding: 10px;
      }

      .shop-name {
        font-size: 1.1rem;
      }

      .btn-confirm,
      .btn-cancel {
        padding: 8px 16px;
        font-size: 0.8rem;
      }
    }
  }
}
