.info-account-bank {
  > p {
    margin-bottom: 10px;
  }
}

.modal-header-created {
  .modal-title {
    font-size: 24px;
  }
}

.modal-general-payment {
  .step-2 {
    h3 {
      font-size: 18px;
    }

    .upload-image {
      position: relative;

      .u-file-upload__item {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }

    .upload-image-txt {
      text-align: center;

      .icon-capture {
        margin: 0 auto;
      }
    }
  }

  .btn-facebook:hover,
  .btn-google:hover {
    background-color: #dddddd;
  }

  .bg-gray {
    background-color: #dddddd;
  }

  input::placeholder {
    font-size: 16px;
  }

  .form-control-sm {
    border: 1px solid #dddddd;
  }
}
