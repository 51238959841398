$primary-color: #0badad;
$color-danger: #d63031;
$bg-light: #f1f5f9;
$brand-primary: #2c3e50;

// Variables for dynamic sizing
$base-unit: 1rem;
$grid-gap: calc($base-unit * 0.688); // 11px equivalent
$item-padding: calc($base-unit * 0.188); // 3px equivalent

@import '../../../styles/breakpoints.scss';
@import '../../../styles/font-size-utility.scss';

.general {
  .form-select {
    @extend %text-responsive;
  }

  @include respond-to('mobile') {
    padding: 1rem !important;
  }

  .form-group {
    label {
      font-size: 0.8rem;
    }
  }

  // Add responsive grid layout
  .row {
    @include respond-to('mobile') {
      // flex-direction: column;

      > [class^='col-'] {
        width: 100%;
        padding: 0;
      }
    }

    @include respond-to('tablet') {
      flex-direction: row;

      > [class^='col-'] {
        width: 50%;
        padding: 0 0.5rem;
      }
    }
  }

  // Adjust banner section for mobile
  .banner-section {
    @include respond-to('mobile') {
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;

      .banner-label {
        font-size: 0.75rem !important;
      }

      .banner-action {
        font-size: 0.75rem !important;
        padding: 0.5rem 0.75rem;
      }
    }
  }

  // Adjust image list for mobile
  .image-list {
    @include respond-to('mobile') {
      gap: 8px;

      .image-item {
        flex: 1 1 calc(50% - 8px);
        max-width: calc(50% - 8px);
        padding-bottom: calc(50% - 8px);
      }
    }
  }

  .btn-telegram {
    border-color: $primary-color;
    &.uninstall {
      background-color: white;
      border-color: $color-danger;
      color: $color-danger !important;
      width: 30px !important;
      height: 30px;
      &:hover {
        background-color: $color-danger;
        border-color: $color-danger;
        color: white !important;
      }
    }
    &:hover {
      background-color: $primary-color;
      border-color: $primary-color;
      color: white;
    }
    &:active {
      background-color: #f3f3f3;
      border-color: #f3f3f3;
      color: $primary-color;
    }
    &:focus {
      background-color: #f3f3f3;
      border-color: #f3f3f3;
      color: $primary-color;
    }
    &:disabled {
      background-color: #f3f3f3 !important;
      border-color: #f3f3f3 !important;
      cursor: not-allowed !important;
    }
    &.loading {
      width: 20px;
      aspect-ratio: 1;
      display: grid;
      border-radius: 50%;
      background: linear-gradient(0deg, rgb(0 0 0/50%) 30%, #0000 0 70%, rgb(0 0 0/100%) 0) 50%/8%
          100%,
        linear-gradient(90deg, rgb(0 0 0/25%) 30%, #0000 0 70%, rgb(0 0 0/75%) 0) 50%/100% 8%;
      background-repeat: no-repeat;
      animation: l23 1s infinite steps(12);
    }
    &.loading::before,
    &.loading::after {
      content: '';
      grid-area: 1/1;
      border-radius: 50%;
      background: inherit;
      opacity: 0.915;
      transform: rotate(30deg);
    }
    &.loading::after {
      opacity: 0.83;
      transform: rotate(60deg);
    }
    @keyframes l23 {
      100% {
        transform: rotate(1turn);
      }
    }
  }

  .image-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 13px;
    max-height: 300px; // Set max height
    overflow-y: auto; // Enable vertical scroll
    padding-right: 8px; // Add padding for scrollbar

    // Custom scrollbar styling
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: $bg-light;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba($brand-primary, 0.3);
      border-radius: 3px;

      &:hover {
        background: rgba($brand-primary, 0.5);
      }
    }

    .image-item {
      flex: 1 1 calc(25% - 10px);
      margin-bottom: 10px;
      max-width: calc(25% - 10px);
      height: 0;
      padding-bottom: calc(25% - 10px);
      position: relative;

      .zoom-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  #telegram-tooltip {
    &:active,
    &:focus {
      outline: none;
    }
  }

  .errorMessage {
    color: $color-danger;
    font-size: 0.75rem;
    margin-top: 0.25rem;
  }

  .banner-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    .banner-label {
      font-size: 0.9rem;
      font-weight: 500;
      color: #2d3748;
    }

    .banner-action {
      color: #0badad;
      font-size: 0.85rem;
      font-weight: 500;
      padding: 0.5rem 0.75rem;
      border-radius: 6px;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background: rgba(11, 173, 173, 0.1);
        color: darken(#0badad, 10%);
      }
    }
  }

  // Add new styles for contact section
  .contact-item {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    margin-bottom: 1rem;

    .icon-wrapper {
      flex-shrink: 0;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .input-wrapper {
      flex: 1;
      min-width: 0; // Prevent flex child from overflowing

      .form-control {
        width: 100%;
      }

      .errorMessage {
        display: block;
        margin-top: 0.25rem;
      }
    }

    @include respond-to('mobile') {
      gap: 0.5rem;
    }
  }
}

.modal-telegram {
  .container {
    height: 400px;
    overflow-y: scroll;
  }
}

.add__logo__modal {
  width: 100%;

  &-list-image {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc($base-unit * 5), 1fr));
    gap: $grid-gap;
    margin-top: calc($base-unit);
    padding: $item-padding;
    max-height: calc($base-unit * 13);
    overflow-y: auto;

    // Custom scrollbar style
    &::-webkit-scrollbar {
      width: calc($base-unit * 0.25);
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: calc($base-unit * 0.125);
    }

    &::-webkit-scrollbar-thumb {
      background: #0badad;
      border-radius: calc($base-unit * 0.125);
    }

    &-item {
      position: relative;
      aspect-ratio: 1;
      border-radius: calc($base-unit * 0.313);
      overflow: hidden;
      background: #fff;
      transition: all 0.2s ease;
      height: 90px;
      width: 100%;

      &:hover {
        transform: translateY(calc($base-unit * -0.063));
        box-shadow: 0 calc($base-unit * 0.188) calc($base-unit * 0.5) rgba(0, 0, 0, 0.12);

        .image-overlay {
          transform: translateY(0);
          opacity: 1;
        }

        .btn-remove-image {
          transform: translateY(0);
        }
      }

      .logo-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .image-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: calc($base-unit * 0.5);
        background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
        transform: translateY(100%);
        transition: transform 0.3s ease;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        inset: 0;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1));
        opacity: 0;
        transition: opacity 0.3s ease;
      }

      .btn-remove-image {
        width: calc($base-unit * 1.188);
        height: calc($base-unit * 1.188);
        padding: calc($base-unit * 0.25);
        border-radius: 50%;
        top: calc($base-unit * 0.313);
        right: calc($base-unit * 0.313);
        transition: all 0.2s ease;
        position: absolute;
        background: rgba(255, 255, 255, 0.95);
        transform: translateY(-130%);
        transition: all 0.3s ease;
        z-index: 2;
        cursor: pointer;

        &:hover {
          background: #fff;
          transform: translateY(0) scale(1.1);
        }
      }
    }
  }
}

.add__logo-modal {
  padding: 1rem;

  .modal-header-section {
    text-align: center;

    .modal-title {
      font-size: 28px;
      font-weight: 600;
      color: #2d3748;
      letter-spacing: -0.5px;
    }

    .image-counter {
      .counter-text {
        font-size: 15px;
        color: #4a5568;
        margin-bottom: 8px;
        display: block;
      }

      .counter-indicator {
        width: 200px;
        height: 6px;
        background: #edf2f7;
        border-radius: 3px;
        margin: 0 auto;
        overflow: hidden;

        .progress-bar {
          height: 100%;
          background: linear-gradient(90deg, #0badad, #15cdca);
          border-radius: 3px;
          transition: width 0.3s ease;
        }
      }
    }
  }

  .add-more-image {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  .btn-primary {
    background: #15cdca !important;
    border: 1px solid #15cdca;

    &:hover {
      background: darken(#15cdca, 5%) !important;
      border: 1px solid darken(#15cdca, 5%) !important;
    }

    &:active {
      background: darken(#15cdca, 10%) !important;
      border: 1px solid darken(#15cdca, 10%) !important;
      box-shadow: none;
      color: white;
    }
  }
}

.c-form-modal .upload-image {
  width: 100% !important;
  border-radius: 12px !important; // 16px * 2/3 ≈ 12px
  border: 2px dashed rgba(11, 173, 173, 0.4) !important; // 2.5px * 2/3 ≈ 2px
  background-color: rgba(11, 173, 173, 0.02);
  box-shadow: none !important;
  height: 113px; // 170px * 2/3 ≈ 113px
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover {
    background-color: rgba(11, 173, 173, 0.05);
    border-color: rgba(11, 173, 173, 0.6) !important;
  }

  .upload-image-txt {
    width: 100%;
    text-align: center;
    z-index: 1;
  }

  img {
    width: 48px; // 72px * 2/3 = 48px
    height: 48px; // 72px * 2/3 = 48px
    opacity: 0.85;
  }

  .text-upload {
    font-size: 11px; // 17px * 2/3 ≈ 11px
    color: #0badad;
    font-weight: 600;
    letter-spacing: -0.2px; // -0.3px * 2/3 ≈ -0.2px
    margin-bottom: 5px; // 8px * 2/3 ≈ 5px
  }

  &::before {
    content: 'Drop files here or click to upload';
    position: absolute;
    bottom: 12px; // 18px * 2/3 = 12px
    left: 50%;
    transform: translateX(-50%);
    font-size: 10px; // 15px * 2/3 = 10px
    color: #666;
    font-weight: 500;
    opacity: 0.75;
  }
}
.c-form-modal input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 2;

  &:hover ~ .upload-image-txt img {
    transform: scale(1.1);
  }
}

.c-form-modal {
  @include respond-to('mobile') {
    .upload-image {
      height: 90px;
    }
  }
}

.c-form-modal input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.external__link-list {
  margin-top: 20px;
  width: 100%;
  > p {
    text-align: center;
    color: #ff6903;
    font-size: 1.2rem;
    margin-bottom: 6px;
  }
  &-item {
    display: flex;
    &-text {
      display: flex;
      flex: 1;
      > span {
        white-space: nowrap;
        margin-right: 5px;
      }
      > a {
        display: inline-block;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    > div {
      outline: none;
      background-color: transparent;
      border: none;
      width: 50px !important;
      cursor: pointer;
      > img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.general__profile_image-upload {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal-telegram {
  font-size: 16px;
}

.external-links-section {
  margin-top: 1.5rem;

  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    .section-title {
      font-size: 0.9rem;
      font-weight: 500;
      color: #2d3748;
    }

    .add-link-btn {
      color: #0badad;
      font-size: 0.85rem;
      font-weight: 500;
      cursor: pointer;
      transition: color 0.2s;
      padding: 0.5rem 0.75rem;
      border-radius: 6px;
      display: flex;
      align-items: center;

      &:hover {
        background: rgba(11, 173, 173, 0.1);
        color: darken(#0badad, 10%);
      }
    }
  }

  .links-container {
    background: #f8fafc;
    border-radius: 8px;
    padding: 0.75rem;

    .link-item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem;
      border-radius: 6px;
      border: 1px solid #e2e8f0;
      background: white;
      transition: all 0.2s;

      &:hover {
        border-color: #0badad;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      }

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      .link-content {
        flex: 1;
        min-width: 0; // Prevent flex child from overflowing
        display: flex;
        align-items: center;
        gap: 1rem;
        overflow: hidden; // Add this to ensure content is truncated

        .link-name {
          font-weight: 500;
          color: #2d3748;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 150px; // Limit maximum width
          flex-shrink: 0; // Prevent shrinking
        }

        .link-url {
          color: #718096;
          font-size: 0.85rem;
          text-decoration: none;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          min-width: 0; // Allow truncation
          flex: 1;

          &:hover {
            color: #0badad;
            text-decoration: underline;
          }
        }
      }

      .remove-btn {
        width: 24px !important;
        height: 24px;
        padding: 4px;
        border-radius: 4px;
        border: none;
        background: transparent;
        opacity: 0.5;
        flex-shrink: 0;
        transition: all 0.2s;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          opacity: 1;
        }

        img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  @include respond-to('mobile') {
    .section-header {
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;

      .section-title {
        font-size: 0.75rem;
      }

      .add-link-btn {
        font-size: 0.75rem;
      }
    }

    .links-container {
      .link-item {
        padding: 0.5rem;

        .link-content {
          flex-direction: column;
          gap: 0.25rem;

          .link-name {
            max-width: 100%;
          }
        }
      }
    }
  }
}

// Modal styles
.external-link-modal {
  .modal-content {
    border-radius: 12px;
    border: none;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    padding: 0px;
  }

  .modal-body {
    padding: 1.5rem;
  }

  .modal-title {
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
  }

  @include respond-to('mobile') {
    .modal-body {
      padding: 1rem;
    }
  }
}

// Add these new styles for tablet nav-tabs
@media (max-width: 991px) {
  // Tablet breakpoint
  .setting-page {
    .nav-tabs {
      display: flex;
      flex-direction: row;
      width: 100%;
      overflow-x: hidden;
      border-bottom: 1px solid #dee2e6;

      .nav-item {
        flex: 1 !important;
        text-align: center;
        white-space: nowrap;
        margin-bottom: -1px;

        .nav-link {
          width: 100%;
          border-radius: 0;
          padding: 0.75rem 0.5rem;
          font-size: 0.9rem;
        }
      }
    }
  }
}
