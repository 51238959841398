[data-notify="progressbar"] {
	margin-bottom: 0;
    position: absolute;
    bottom: -1px;
    left: -1px;
    right: -1px;
    height: 4px;
    background: transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}