.subscription-payment {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  // background-color: #f2f2f2;
  border-radius: 8px;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 80px;
}

.subscription-payment h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.payment-options {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.payment-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  transition: border-color 0.3s ease;
  border-width: 1px;
  border-style: solid;
  padding: 8px;
  border-radius: 4px;
  border-color: lightgray;
  background-color: white;
}

.payment-option.selected {
  border-color: #079d9a;
}

.payment-option:hover {
  border-color: #079d9a;
}

.payment-option-image {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border: 2px solid transparent;
  border-radius: 50%;
  object-fit: contain;
}

.payment-option input[type='radio'] {
  margin: 0 10px;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  outline: none;
  transition: border-color 0.3s ease;
}

.payment-option input[type='radio']:checked {
  border-color: #079d9a;
  background-color: #079d9a;
}

.payment-option input[type='radio']:checked::before {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #fff;
  transform: translate(3px, 3px);
}

.payment-option-label {
  font-weight: 600;
  font-size: 16px;
}
