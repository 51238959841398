$text-primary: #2c3e50;
$text-secondary: #64748b;
$warning: #f1c40f;
$danger: #e74c3c;
$aqua: #00d8d6;

.custom-swal-container {
  font-family: inherit;
  z-index: 9999;

  .swal2-popup {
    padding-bottom: 0;
    width: 500px;
  }

  .custom-swal-title {
    color: $text-primary;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .custom-swal-text {
    color: #4a5568;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 0;
    padding: 1rem;
    p {
      margin-bottom: 1rem !important;
      text-align: center;
    }
  }

  .custom-swal-icon {
    margin: 1rem auto;

    &.swal2-warning {
      color: $warning;
      border-color: $warning;
    }

    &.swal2-error {
      color: $danger;
      border-color: $danger;
    }

    &.swal2-success {
      color: $aqua;
      border-color: $aqua;
    }

    &--success__line,
    &--error__line {
      background-color: currentColor;
    }

    &--success__ring,
    &--error__ring {
      border-color: currentColor;
    }
  }

  .custom-swal-buttons {
    .warning.contained {
      background-color: $warning;
      color: #000; // Use black text for better contrast on warning background

      &:hover {
        background-color: darken($warning, 5%);
      }

      &:active {
        background-color: darken($warning, 10%);
      }
    }

    .custom-confirm-button,
    .custom-cancel-button {
      margin-top: 0 !important;
      min-width: 120px;
    }
  }
}
