@import '../../../styles/breakpoints.scss';
@import '../../../styles/font-size-utility.scss';

.noti-list-item {
  display: flex;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid #eee;
  background: #fff;
  transition: background 0.2s ease;

  &:hover {
    background: #f8f9fa;
  }

  .noti-avatar {
    flex-shrink: 0;
  }

  .noti-content {
    flex: 1;
    min-width: 0;

    .notification-header {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 8px;

      .noti-icon {
        flex-shrink: 0;
        font-size: 18px;
        margin-right: 0px !important;

        &.system {
          color: #0bc5ea; // Bright cyan - for system notifications
        }
        &.info {
          color: #4299e1; // Bright blue - for information
        }
        &.warning {
          color: #ed8936; // Bright orange - for warnings
        }
        &.success {
          color: #48bb78; // Bright green - for success
        }
        &.default {
          color: #f1c40f; // Bright purple - for default/others
        }

        // Hover effects
        &:hover {
          &.system {
            color: #00b5d8;
          }
          &.info {
            color: #3182ce;
          }
          &.warning {
            color: #dd6b20;
          }
          &.success {
            color: #38a169;
          }
          &.default {
            color: #f1c40f;
          }
        }

        @include respond-to('mobile') {
          font-size: 14px;
        }
      }

      &--title {
        flex: 1;
        font-weight: 600;
        color: #15cdca;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @extend %text-responsive;
      }

      .noti-date {
        flex-shrink: 0;
        color: #6c757d;
        @extend %text-responsive;
      }
    }

    .text-message {
      color: #6c757d;
      overflow: hidden;
      display: -webkit-box;
      // -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 1.5;
      @extend %text-responsive;
    }
  }
}

.noti-list-item .title,
.noti-list-item .text-message,
.noti-list-item .sender {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
