footer {
  background-color: #f2f0f0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  .menu {
    background-color: #f2f0f0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 3px 0;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 0;
    li {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }
  }
  a {
    display: flex;
    font-size: 0.6875rem;
    height: 61px;
    color: #7c797a;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.active {
      color: #f06a0f !important;
      font-weight: bold;
    }
  }
  &.footer-admin {
    .menu {
      li {
        flex: 50%;
        max-width: 50%;
        width: 50%;
        border-left: 1px solid rgba($color: #868384, $alpha: 0.3);
        &:first-child {
          border-left: none;
        }
      }
    }
  }
  .active {
    color: #f06a0f;
    font-weight: bold;
  }
}

footer,
.section-content {
  flex: 0 0 100%;
  max-width: 100%;
}

.section-content {
  padding-bottom: 60px;
  padding-top: 90px;
  overflow: hidden;
  .container {
    padding-bottom: 20px;
    display: flex;
    height: 100%;
  }

  &--top {
    .container {
      position: relative;

      flex-wrap: wrap;

      > div {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .row-top {
        align-self: flex-start;
      }

      .row--bot,
      .row-bot {
        align-self: flex-end;

        margin-top: 10px;
      }
      .row-bot {
        &.is-fixed {
          position: fixed;
          bottom: 60px;
          background: #fff;
          left: 0;
          right: 0;
          max-width: 640px;
          margin: 0 auto;
          padding-left: 20px;
          padding-right: 20px;
          z-index: 99;
          border-top: 1px solid #f2f0f0;
          border-bottom: 1px solid #f2f0f0;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
    &.is-content-fixed {
      padding-bottom: 300px;
    }
  }

  &--welcome {
    position: relative;

    &:after {
      height: 50%;
      background-color: rgba($color: #868384, $alpha: 0.12);
      bottom: 20px;
      left: 0;
      right: 0;
      content: '';
      position: absolute;
    }

    > * {
      position: relative;
      z-index: 2;
    }
  }

  &--profile {
    padding-top: 92px;

    .c-form.c-form--profile {
      margin-top: -50px;
      margin-bottom: 20px;
    }
  }
}
