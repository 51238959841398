.user-profile-dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: bold;
  font-size: 14px;
  padding-left: 1rem;
  padding-right: 1rem;

  .dropdown-menu {
    &.show {
      left: inherit !important;
      right: 0;
      font-size: 16px;
      transform: translate(0, 50px) !important;
    }

    @media screen and (max-width: 768px) {
      .dropdown-item {
        font-size: 14px;
      }
    }
  }

  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
}
