$primary-color: #15cdca;

@import '../../../styles/breakpoints.scss';
@import '../../../styles/font-size-utility.scss';
@import '../../../styles/padding-utility.scss';

@import './styles/bill.scss';
@import './styles/calendar.scss';
@import './styles/checkbox.scss';
@import './styles/date-picker.scss';
@import './styles/form-contact-details.scss';
@import './styles/render-info.scss';
@import './styles/tab-pickup.scss';
@import './styles/tabs.scss';
@import './styles/form-gift-recipient.scss';
@import './styles/select-payment-method.scss';

#digistall {
  .checkout-wrapper {
    @media screen and (min-width: 1024px) {
      max-width: 1200px;
    }
  }

  .checkout-skeleton {
    max-width: 1024px;
  }

  .checkout {
    padding: 1rem 0;
    background-color: #edfbfb;
    font-size: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    gap: 1rem;

    &__order-summary-section {
      display: flex;
      flex-direction: column;
    }

    @include respond-to('mobile') {
      flex-direction: column;
    }

    @include respond-to('desktop') {
      &__form-section {
        width: 50%;
      }
      &__order-summary-section {
        width: 50%;
      }

      .delivery-contact-skeleton,
      .bill-button-skeleton {
        width: 50%;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      @include respond-to('mobile') {
        margin-bottom: 0.25rem;
      }

      @include respond-to('tablet') {
        margin-bottom: 0.5rem;
      }
    }

    &__title {
      text-transform: uppercase;
      font-weight: 600;
      color: #64748b;

      @extend %title-responsive;
    }

    &__content {
      background-color: #ffffff;
      padding: 1rem;
      margin-bottom: 1rem;
      border-radius: 0.25rem;

      &--disabled {
        pointer-events: none;
        opacity: 0.5;
        filter: grayscale(1);
        cursor: not-allowed;
      }

      @include respond-to('mobile') {
        padding: 0.5rem;
      }
    }
  }
}

// Add these styles at the end of your existing SCSS file
.react-loading-skeleton {
  background-color: #e0e0e0 !important;

  &::after {
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.4),
      transparent
    ) !important;
  }
}

.modal-payment-cod {
  .modal-title {
    transform: translateX(68%);
  }
}
