$primary-color: #15cdca;
$secondary-color: #f06a0f;

@import '../../styles/breakpoints.scss';
@import '../../styles/font-size-utility.scss';
@import '../../styles/padding-utility.scss';

@import './styles/order-management-header.scss';
@import './styles/modals.scss';
@import './styles/status-color.scss';

#digistall {
  .order-records {
    .icon-filter path:first-child {
      fill: white;
    }

    .row-item:hover {
      background-color: #f2f2f2;
    }

    img {
      width: 70px;
      height: 70px;
      object-fit: cover;
    }

    .icon-link {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.874' height='11.376' viewBox='0 0 9.874 11.376'%3E%3Cpath id='Icon_feather-paperclip' data-name='Icon feather-paperclip' d='M11.492,6.946,7.479,11.573a2.384,2.384,0,0,1-3.708,0,3.358,3.358,0,0,1,0-4.274L7.784,2.672a1.589,1.589,0,0,1,2.472,0,2.238,2.238,0,0,1,0,2.849L6.238,10.148a.8.8,0,0,1-1.236,0A1.119,1.119,0,0,1,5,8.723L8.71,4.454' transform='translate(-2.324 -1.582)' fill='none' stroke='%23079d9a' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A");
    }
  }
}

.tooltip-custom .tooltip-inner {
  font-size: 14px;
}

.react-toast-notifications__container {
  z-index: 9999 !important;
}

.reverse-order-detail {
  font-size: 60%;

  th {
    color: $primary-color;
  }

  th,
  td {
    font-size: 1em;
  }

  ul {
    padding-left: 0;
    li {
      list-style: none;
    }
  }

  p {
    margin-bottom: 0;
  }

  img {
    object-fit: contain;
    width: 70px;
  }

  .title {
    color: $secondary-color;
  }

  .text-primary {
    color: $primary-color !important;
  }

  .sticky-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    margin-bottom: 1rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  }
}
