$primary-color: #15cdca;
$text-color: #64748b;
$border-radius: 8px;

@import '../../../styles/breakpoints.scss';
@import '../../../styles/font-size-utility.scss';

#digistall {
  .my-shops {
    a {
      text-decoration: none;
      color: $text-color;
      transition: all 0.2s ease;

      &:hover {
        color: $primary-color;
      }
    }

    h1 {
      color: $text-color;
      font-weight: 600;
    }

    &__item {
      background: white;
      border-radius: $border-radius;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      border: 1px solid rgba(0, 0, 0, 0.08);
      overflow: hidden;

      &:hover {
        transform: translateY(-4px);
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.08);
      }

      a {
        height: 120px;
        width: 100%;
        display: block;
        background-color: #f8f9fa;
        position: relative;
        overflow: hidden;
        @extend %text-responsive;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.02);
          opacity: 0;
          transition: opacity 0.3s ease;
        }

        &:hover::after {
          opacity: 1;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;
      }

      &__info {
        padding: 0.875rem !important; // Reduced from 1.25rem

        &__description {
          font-size: 0.825rem; // Reduced from 0.9rem
          color: #64748b;
          line-height: 1.5;
          margin-bottom: 0.25rem; // Reduced from 0.5rem
        }
      }
    }

    .nav-tabs {
      font-size: 0.9rem;
      gap: 0.75rem !important;
    }

    .nav-item {
      flex: 1;
    }

    .nav-link.my-shop-nav-link {
      padding: 0.5rem 0.25rem;
      border-radius: 8px !important;
      transition: all 0.3s ease;
      font-weight: 500;

      .nav-icon {
        font-size: 0.9rem;
      }

      &.active {
        background-color: $primary-color !important;
        color: white !important;
        border: none !important;
        box-shadow: 0 2px 4px rgba($primary-color, 0.4);
      }

      &:not(.active) {
        background-color: #f8f9fa !important;
        color: $text-color !important;
        border: none !important;

        &:hover {
          background-color: rgba($primary-color, 0.1) !important;
          color: $primary-color !important;
        }
      }
    }

    .create-shop-btn {
      background: none !important;
      color: $text-color !important;
      border: none !important;
      // font-size: 0.875rem;
      font-weight: 500;
      padding: 0;
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      position: relative;
      @extend %text-responsive;

      span {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 2px;
          bottom: -2px;
          left: 0;
          background-color: $primary-color;
          transition: width 0.2s ease;
        }
      }

      &:hover {
        color: $primary-color !important;

        span::after {
          width: 100%;
        }

        .btn-icon {
          transform: translateX(3px);
        }
      }

      .btn-icon {
        font-size: 1rem;
        transition: transform 0.2s ease;
      }
    }
  }
}

.shop-card {
  background: white;
  border-radius: $border-radius;
  overflow: hidden;
  transition: all 0.3s ease;
  border: 1px solid #e2e8f0;
  display: flex; // Add this
  align-items: stretch; // Add this
  margin-bottom: 1rem;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.06);
  }

  &__banner {
    position: relative;
    width: 140px; // Changed from height to width
    min-width: 140px; // Add this to maintain width
    background: #f8fafc;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }

    &:hover img {
      transform: scale(1.05);
    }

    &__placeholder {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(45deg, #15cdca, #16a7a5);

      span {
        font-size: 2.5rem;
        font-weight: 600;
        color: white;
        text-transform: uppercase;
      }
    }
  }

  &__content {
    flex: 1; // Add this
    display: flex; // Add this
    flex-direction: column; // Add this
    padding: 1rem 1.25rem;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 0.5rem;

    button svg {
      margin-right: 0px !important;
    }
  }

  &__title {
    font-size: 1rem;
    font-weight: 600;
    color: $text-color;
    margin: 0;
    transition: color 0.2s ease;

    &:hover {
      color: $primary-color;
    }
  }

  &__description {
    font-size: 0.875rem;
    color: #64748b;
    line-height: 1.5;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 4.5em; // 1.5 (line-height) × 3 lines
  }

  // Update media query
  @media screen and (max-width: 576px) {
    &__banner {
      width: 100px; // Changed from height to width
      min-width: 100px; // Add this
    }

    &__content {
      padding: 0.75rem;
    }
  }
}

@media screen and (max-width: 576px) {
  #digistall {
    .my-shops {
      &__item {
        a {
          height: 100px; // Reduced from 140px
        }

        img {
          height: 150px;
        }

        &__info {
          padding: 0.75rem !important;

          &__title {
            font-size: 0.875rem;
          }

          &__description {
            font-size: 0.75rem;
            line-height: 1.5;
          }
        }

        .btn-action-shop {
          width: 28px;
          height: 28px;

          svg {
            font-size: 0.75rem;
          }
        }
      }

      .nav-tabs {
        font-size: 0.65rem;
        gap: 0.5rem !important;
      }

      .btn-action-shop {
        width: 35px;
        height: 35px;
      }

      .nav-link.my-shop-nav-link {
        padding: 0.6rem 1rem;
      }
    }
  }

  .shop-card {
    &__banner {
      height: 120px;
    }

    &__content {
      padding: 0.875rem 1rem;
    }

    &__title {
      font-size: 0.925rem;
    }

    &__description {
      font-size: 0.8125rem;
    }

    &__delete {
      width: 28px !important;
      height: 28px;

      svg {
        font-size: 0.8rem;
      }
    }
  }
}

// Add these at the end of the file
.react-loading-skeleton {
  background-color: #f1f5f9 !important;

  &::after {
    background-image: linear-gradient(90deg, #f1f5f9, #e2e8f0, #f1f5f9) !important;
  }
}

// Optional: Adjust skeleton animations in dark mode
@media (prefers-color-scheme: dark) {
  .react-loading-skeleton {
    background-color: #1e293b !important;

    &::after {
      background-image: linear-gradient(90deg, #1e293b, #334155, #1e293b) !important;
    }
  }
}
