.calendar__row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  gap: 5px;

  &.mb-3 {
    margin-bottom: 1rem;
  }

  @include respond-to('mobile') {
    margin-bottom: 0.75rem;
  }

  @include respond-to('tablet') {
    margin-bottom: 0.5rem;
  }

  @include respond-to('desktop') {
    margin-bottom: 0.25rem;
  }
}

.calendar__col {
  flex: 1;

  .react-datepicker-wrapper {
    width: 100%;
  }

  &--date {
    position: relative;
  }

  &--time {
    flex: 1;
  }
}

.calendar__label {
  margin-bottom: 0.5rem;
  font-weight: 600;

  @include respond-to('mobile') {
    font-size: $font-size-text-mobile;
  }

  @include respond-to('tablet') {
    font-size: $font-size-text-tablet;
    margin-bottom: 0.3rem;
  }

  @include respond-to('desktop') {
    font-size: $font-size-text-desktop;
    margin-bottom: 0.2rem;
  }
}

.calendar__datepicker {
  border: 1px solid #ccc;
  cursor: pointer;
  width: 100%;
  padding: 0.5rem;
  border-radius: 4px;

  &.border {
    border: 1px solid #ccc;
  }

  &.cursor-pointer {
    cursor: pointer;
  }

  &.custom-datepicker {
    width: 100%;
  }

  &.w-100 {
    width: 100%;
  }

  @include respond-to('mobile') {
    padding: 0.4rem;
  }

  @include respond-to('tablet') {
    padding: 0.3rem;
  }

  @include respond-to('desktop') {
    padding: 0.2rem;
  }
}
