.render-info {
  &--container {
    margin-bottom: 1rem;
    background-color: #edfbfb;
    color: #679e9c;
    padding: 1rem;
    border-radius: 0.25rem;

    @include respond-to('mobile') {
      padding: 0.5rem;
    }

    @include respond-to('tablet') {
      padding: 1rem;
    }
  }

  &__charge-title {
    margin-bottom: 0;
    font-weight: 600;

    @extend %text-responsive;
  }

  &__text {
    color: #59687c;
    margin-bottom: 0px;

    @extend %text-responsive;
  }

  &__instructions-title {
    margin-bottom: 0;
    font-weight: 600;
  }

  &__instructions {
    color: #666;

    @extend %text-responsive;
  }

  &__public-holiday {
    font-weight: 500;
    color: #f06a0f;
    margin-bottom: 0px;

    @extend %text-responsive;
  }
}
