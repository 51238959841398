//
// general.scss
//
body {
  font-family: $font-primary;
  color: $dark;
  background: $white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary;
}

.landing-page {
  a {
    text-decoration: none !important;
    outline: none;
    color: $primary;
  }

  p {
    line-height: 1.6;
  }
}
