$font-size-text-mobile: 0.6rem;
$font-size-text-tablet: 0.7rem;
$font-size-text-desktop: 0.8rem;

$font-size-small-text-mobile: 0.5rem;
$font-size-small-text-tablet: 0.6rem;
$font-size-small-text-desktop: 0.7rem;

$font-size-title-mobile: 1rem;
$font-size-title-tablet: 1.125rem;
$font-size-title-desktop: 1.125rem;

$font-size-subtitle-mobile: 0.875rem;
$font-size-subtitle-tablet: 1rem;
$font-size-subtitle-desktop: 1.125rem;

@mixin font-size-responsive($mobile, $tablet, $desktop) {
  @include respond-to('mobile') {
    font-size: $mobile;
  }

  @include respond-to('tablet') {
    font-size: $tablet;
  }

  @include respond-to('desktop') {
    font-size: $desktop;
  }
}

// Extend các biến kích thước font cho các phần tử cụ thể
%small-text-responsive {
  @include font-size-responsive(
    $font-size-small-text-mobile,
    $font-size-small-text-tablet,
    $font-size-small-text-desktop
  );
}

%text-responsive {
  @include font-size-responsive(
    $font-size-text-mobile,
    $font-size-text-tablet,
    $font-size-text-desktop
  );
}

%title-responsive {
  @include font-size-responsive(
    $font-size-title-mobile,
    $font-size-title-tablet,
    $font-size-title-desktop
  );
}

%subtitle-responsive {
  @include font-size-responsive(
    $font-size-subtitle-mobile,
    $font-size-subtitle-tablet,
    $font-size-subtitle-desktop
  );
}

@mixin truncate-text($lines: 1) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  white-space: normal;
}
