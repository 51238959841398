$slate-color: #536980;

@import '../../../../styles/breakpoints.scss';
@import '../../../../styles/font-size-utility.scss';

.btn-back {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  border: none;
  background: none;
  color: $slate-color;
  cursor: pointer;
  padding: 0;
  position: relative;
  text-decoration: none;

  @extend %text-responsive;

  svg {
    width: 18px;
    height: 18px;

    @include respond-to('mobile') {
      width: 14px;
      height: 14px;
    }

    @include respond-to('tablet') {
      width: 16px;
      height: 16px;
    }
  }
}
