@import '../../styles/breakpoints.scss';
@import '../../styles/font-size-utility.scss';

.dashboard__desktop {
  padding: 1rem;

  &-head {
    @include respond-to('mobile') {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    &-title {
      margin-bottom: 0;
      color: #ff6903;
      font-weight: bold;
      @include respond-to('mobile') {
        font-size: 1.5rem;
      }
    }
    &-sync {
      @include respond-to('mobile') {
        width: 100%;
        flex-direction: row; // Changed from column to row
        gap: 0.5rem;
        justify-content: space-between; // Added to create space between date and week selector
        align-items: center; // Added to center items vertically
      }

      display: flex;
      &-date {
        display: flex;
        padding: 0px 12px;
        border-radius: 4px;
        border: 1px solid #e2e8f0;
        transition: all 0.2s ease;
        color: #59687c;
        align-items: center;
        cursor: pointer;
        @extend %text-responsive;

        @include respond-to('mobile') {
          width: 48%; // Changed from 100% to allow space between items
          justify-content: center;
          padding: 8px 12px;
        }

        @include respond-to('tablet') {
          padding: 5px 12px;
        }

        &-start {
          margin-right: 6px;
        }
        &-end {
          margin-left: 6px;
        }

        &:hover {
          border-color: #15cdca;
        }
      }
      &-week {
        @include respond-to('mobile') {
          width: 48%; // Changed from 100% to match date width
          margin-left: 0;
        }

        margin-left: 12px;
        box-shadow: rgb(0 0 0 / 20%) 0px 0px 20px;
        .form-select {
          border: none;
          color: #a9a7a7;
          background-color: #ffffff;
          font-size: 0.8rem;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }

  &-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-top: 1rem;

    @include respond-to('mobile') {
      grid-template-columns: 1fr;
    }

    &-item {
      background-color: white;
      border-radius: 8px;
      padding: 1rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

      canvas {
        width: 100% !important;
        height: auto !important;
      }

      .chart-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }

  &-chart {
    @include respond-to('mobile') {
      overflow-x: hidden;
    }
  }

  .recharts-wrapper {
    @include respond-to('mobile') {
      width: 100% !important;
      height: auto !important;
      margin: 0 auto;
    }
  }

  .dashboard__desktop-sale-chart,
  .dashboard__desktop-order-chart,
  .dashboard__desktop-average-chart,
  .dashboard__desktop-topProduct-chart {
    @include respond-to('mobile') {
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      background: #ffffff;

      > div {
        min-width: 300px; // Minimum width on mobile
        padding: 8px;
      }
    }
  }

  .product-selected-label {
    @include respond-to('mobile') {
      font-size: 12px;
      flex: 0 0 calc(50% - 1rem); // 2 items per row on mobile
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .recharts-text {
    font-size: 12px;
  }
}
.chart-layout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2%;
  margin-top: 20px;

  @include respond-to('mobile') {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  > div {
    padding: 16px;
    border-radius: 8px;
    background: #ffffff;
    border: 1px solid rgba(226, 232, 240, 0.8);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    }

    > p {
      margin-bottom: 12px;
      color: #1a202c;
      font-weight: 600;
      font-size: 1rem;
    }
  }
}
.datePicker {
  &__startDay,
  &__endDay {
    position: relative;
    margin-bottom: 32px; // Tăng margin để có chỗ cho error message

    .datepicker-container {
      input {
        font-size: 16px !important;
      }

      svg {
        font-size: 16px;
      }
    }

    b {
      margin-bottom: 8px;
      display: block;
    }
  }
}
.sync-data-btn {
  > button {
    width: 320px;
    margin: 0 auto;
  }
}

.modal-sync-data {
  .modal-content {
    border-radius: 12px;
    border: none;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
    padding: 0;
  }

  .modal-header {
    border-bottom: 1px solid #eee;
    padding: 16px 24px;

    .sync-data-title {
      font-size: 18px;
      font-weight: 600;
      color: #333;
    }

    .close {
      padding: 0;
      margin: 0;
      opacity: 0.5;
      transition: opacity 0.2s;

      &:hover {
        opacity: 1;
      }
    }
  }

  .modal-body {
    padding: 1rem;

    .form-control-sm {
      transition: all 0.2s ease;

      &:hover {
        border-color: #15cdca !important;
      }
    }

    .datePicker {
      &__startDay,
      &__endDay {
        margin-bottom: 20px;

        b {
          display: block;
          margin-bottom: 8px;
          color: #555;
          font-size: 14px;
        }

        .react-datepicker-wrapper {
          width: 100%;

          input {
            width: 100%;
            padding: 8px 12px;
            border: 1px solid #ddd;
            border-radius: 6px;
            font-size: 14px;
            color: #333;
            transition: border-color 0.2s;
            cursor: pointer;

            &:focus {
              outline: none;
              border-color: #15cdca;
            }
          }
        }
      }
    }

    .sync-data-btn {
      text-align: center;
      margin-top: 16px;

      .btn--org {
        padding: 10px 24px;
        font-size: 14px;
        font-weight: 500;
        border-radius: 6px;
        transition: all 0.2s;
        background: #ff6903;
        color: white;
        border: none;
        width: 100%;

        &:hover {
          background: darken(#ff6903, 5%);
          transform: translateY(-1px);
        }

        &:active {
          transform: translateY(0);
        }
      }
    }
  }

  .datepicker-container {
    position: relative;
    width: 100%;

    .calendar-icon {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      color: #666;
      pointer-events: none;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }
  }
}

.datepicker-container {
  position: relative;
  width: 100%;

  .datepicker-input-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px; // Thêm margin để tạo khoảng cách với error message

    .react-datepicker-wrapper {
      width: 100%;

      input {
        width: 100%;
        padding: 8px 12px;
        border: 1px solid #ddd;
        border-radius: 6px;
        font-size: 14px;
        color: #333;
        transition: all 0.2s ease;
        cursor: pointer;

        &:focus {
          outline: none;
          border-color: #15cdca;
        }

        // Thêm style cho trạng thái lỗi
        &.is-invalid {
          border-color: #dc3545 !important;
          background-color: #fff8f8;

          &:focus {
            border-color: #dc3545 !important;
          }
        }
      }
    }

    // Thêm style cho icon calendar khi input có lỗi
    .calendar-icon-wrapper {
      position: absolute;
      right: 12px;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      pointer-events: none;

      .calendar-icon {
        color: #666;
        font-size: 16px;
      }
    }
  }

  .invalid-feedback {
    position: absolute;
    bottom: -18px; // Điều chỉnh vị trí error message
    left: 0;
    font-size: 12px;
    color: #dc3545;
    margin: 0;
  }
}
