// Brand Colors
$brand-primary: #2c3e50;
$brand-secondary: #ff6903; // $secondary-color

// Text Colors
$text-primary: #2c3e50;
$text-secondary: #64748b;
$text-muted: #94a3b8;
$text-light: #7c797a;

// Background Colors
$bg-white: #fff;
$bg-light: #f1f5f9;
$bg-placeholder: #536980;

// Social Media Colors
$social-whatsapp: #25d366;
$social-instagram: #e1306c;
$social-facebook: #1778f2;
$social-domain: #0ea5e9;

// Shadow Colors
$shadow-light: rgba(0, 0, 0, 0.06);
$shadow-medium: rgba(0, 0, 0, 0.1);
$shadow-dark: rgba(0, 0, 0, 0.12);
$shadow-overlay: rgba(0, 0, 0, 0.4);

// Border Colors
$border-light: #e5e7eb;
