#digistall {
  .delivery {
    .delivery-container {
      @include respond-to('mobile') {
        flex-direction: column-reverse;
      }
    }

    .delivery-list {
      @include respond-to('mobile') {
        &__map-container {
          margin-top: 1rem;
        }
        &__map-placeholder {
          padding: 1rem;
        }
      }
    }
  }
}
