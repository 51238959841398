#digistall {
  .virtual-shop {
    font-size: 18px;
    .title {
      font-weight: bold;
      color: #ff6903;
      font-size: 24px;
    }

    #shop-domain {
      color: #15cdca;
    }

    .add-more-image,
    #shop-domain {
      font-size: 0.65rem;
    }

    .form-control:active,
    .form-control:focus {
      box-shadow: none;
    }

    &.create-items-page {
      align-items: inherit;
    }

    .upload-image {
      border-radius: 0;
      border: 1px dashed gray;
      box-shadow: none;
    }

    .add-items.create-items-page img {
      width: 40px;
    }

    .errorMessage {
      color: red;
    }

    .list-image {
      // flex-wrap: wrap;

      .upload-image {
        position: relative;
        width: 107px;
        height: 107px;

        .main-image {
          object-fit: contain;
          height: 100%;
        }

        .cancel-btn {
          width: 16px;
          height: 16px;
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  #digistall {
    .virtual-shop {
      font-size: 14px;

      .title {
        font-size: 18px;
      }
    }
  }
}
