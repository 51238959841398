// InputTime.scss
$primary-color: #15cdca;
$hover-color: lighten($primary-color, 40%);
$border-color: #e0e0e0;

@import '../../../styles/breakpoints.scss';
@import '../../../styles/padding-utility.scss';
@import '../../../styles/font-size-utility.scss';

.time-picker {
  position: relative;
  width: 100%;

  input {
    cursor: pointer;
  }

  .wrapper-input {
    position: relative;
    cursor: pointer;

    .time-icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: #6c757d;
    }
  }

  .time-picker-popup {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    z-index: 1000;
    animation: fadeIn 0.2s ease-in-out;
    width: 300px;

    .time-columns {
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }

    .time-column {
      flex: 1;
      text-align: center;

      @extend %text-responsive;

      .column-header {
        color: #666;
        padding-bottom: 10px;
        border-bottom: 1px solid $border-color;
        margin-bottom: 10px;
      }

      .column-items {
        max-height: 200px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0.1);
          border-radius: 3px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.3);
          border-radius: 3px;
          transition: background-color 0.2s;

          &:hover {
            background-color: rgba(0, 0, 0, 0.5);
          }
        }
      }

      .time-item {
        padding: 8px;
        cursor: pointer;
        transition: all 0.2s ease;
        border-radius: 4px;
        margin: 2px 0;

        &:hover {
          background-color: $hover-color;
        }

        &.selected {
          background-color: $primary-color;
          color: white;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
