#digistall {
  .event-list-order {
    a.paid-combined {
      color: #51aaaa;
      &::before {
        content: '';
        display: inline-block;
        width: 30px;
        height: 20px;
        vertical-align: middle;
        background-position: left, right;
        background-repeat: no-repeat, no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.363' height='14.422' viewBox='0 0 15.363 14.422'%3E%3Cpath id='Icon_feather-paperclip' data-name='Icon feather-paperclip' d='M17.16,8.176l-6.693,5.8a4.864,4.864,0,0,1-6.184,0,3.439,3.439,0,0,1,0-5.355l6.693-5.8a3.243,3.243,0,0,1,4.122,0,2.293,2.293,0,0,1,0,3.57l-6.7,5.8a1.621,1.621,0,0,1-2.061,0,1.146,1.146,0,0,1,0-1.785l6.184-5.349' transform='translate(-2.503 -1.409)' fill='none' stroke='%23079d9a' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A"),
          url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.363' height='14.422' viewBox='0 0 15.363 14.422'%3E%3Cpath id='Icon_feather-paperclip' data-name='Icon feather-paperclip' d='M17.16,8.176l-6.693,5.8a4.864,4.864,0,0,1-6.184,0,3.439,3.439,0,0,1,0-5.355l6.693-5.8a3.243,3.243,0,0,1,4.122,0,2.293,2.293,0,0,1,0,3.57l-6.7,5.8a1.621,1.621,0,0,1-2.061,0,1.146,1.146,0,0,1,0-1.785l6.184-5.349' transform='translate(-2.503 -1.409)' fill='none' stroke='%23079d9a' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A");
      }
    }

    .order-comment {
      min-height: auto;
      height: 15px;
    }

    a.paid::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: middle;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.363' height='14.422' viewBox='0 0 15.363 14.422'%3E%3Cpath id='Icon_feather-paperclip' data-name='Icon feather-paperclip' d='M17.16,8.176l-6.693,5.8a4.864,4.864,0,0,1-6.184,0,3.439,3.439,0,0,1,0-5.355l6.693-5.8a3.243,3.243,0,0,1,4.122,0,2.293,2.293,0,0,1,0,3.57l-6.7,5.8a1.621,1.621,0,0,1-2.061,0,1.146,1.146,0,0,1,0-1.785l6.184-5.349' transform='translate(-2.503 -1.409)' fill='none' stroke='%23079d9a' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A");
    }
  }
}

.modal-warning {
  color: #7c797a;
}

.modal-detail-order {
  color: #7c797a;

  .modal-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .c-image-edit {
      position: relative;
      .c-image {
        height: 107px;
        width: 107px;

        #userPhotoUrl {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          margin: 0 auto;
          display: block;
        }
      }

      .icon-whatapp {
        position: absolute;
        bottom: -10px;
        right: -8px;
        cursor: pointer;
      }
    }

    .c-txt-14 {
      font-size: 0.875rem;
      color: #f06a0f;
      margin-top: 1rem;
    }
  }

  .detail-list-news__des {
    font-size: 16px;

    span:first-child {
      color: #f06a0f;
    }
  }

  .modal-footer {
    font-size: 1rem;
    p {
      font-size: inherit;
      margin-bottom: 5px;
      span {
        margin-left: 1rem;
      }
    }

    .t-done {
      color: #51aaaa;
    }
  }
}

.modal-order-comment {
  color: #7c797a;

  .modal-title {
    margin: 0 auto;
    color: #f06a0f;
    font-weight: 400;
  }

  .btn-submit {
    background: #f06a0f !important;
    border: 1px solid #f06a0f !important;
    color: #fff;
    border-radius: 30px;
    width: 70%;
    font-size: 0.625rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    height: 37px;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: darken(#f06a0f, 5%) !important;
    }

    &:active {
      background: darken(#f06a0f, 10%) !important;
    }
  }
}

.modal-event-payment {
  .modal-header {
    position: relative;
    display: inline-block;
  }

  button.close {
    position: absolute;
    top: 12px;
    right: 19px;
  }

  .modal-title {
    font-weight: 400;
  }

  .list-order-combined-ref {
    color: #7c797a;
  }
}
