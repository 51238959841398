//
// variables.scss
//

@import url('https://fonts.googleapis.com/css?family=Assistant:200,300,400,500,600,700,800|Rubik:400,500,700&display=swap');

// Color variables
$white: #ffffff;
$black: #000000;

$primary: #ff735c;
$secondary: #6c757d;
$success: #2dd6b7;
$info: #1097bd;
$warning: #ffbb13;
$danger: #f43958;
$dark: #64748b;
$muted: #9b9bae;
$light: #fbfbfd;

$theme-colors: (
  'primary-landing-page': $primary,
  'secondary-landing-page': $secondary,
  'success-landing-page': $success,
  'info-landing-page': $info,
  'warning-landing-page': $warning,
  'danger-landing-page': $danger,
  'dark-landing-page': $dark,
  'muted-landing-page': $muted,
  'light-landing-page': $light,
  'black-landing-page': $black,
  'white-landing-page': $white,
);

$gradient: linear-gradient(to right, $primary 0%, rgba($primary, 0.6) 100%);

// Shadow
$shadow: 2px 2px 12px -2px rgba($black, 0.08);

// Font
$font-primary: 'Assistant', sans-serif;
$font-secondary: 'Rubik', sans-serif;
