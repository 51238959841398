#digistall {
  .manage-products {
    // ----BUTTON----
    button {
      font-weight: 600;
      &.btn-outline-secondary {
        box-shadow: none;
        color: gray;
        border-color: gray;

        &:hover {
          background: $secondary-color !important;
          border: 1px solid $secondary-color !important;
          color: white;
        }

        &:not(:disabled):not(.disabled).active {
          color: white !important;
          background-color: $secondary-color;
          border-color: $secondary-color;
        }
      }

      &:disabled {
        border: 1px solid gray !important;
        color: white;
        cursor: not-allowed;
      }

      &.btn-save-variant:disabled {
        border: 1px solid transparent !important;
      }
    }

    .button-platform {
      box-shadow: none;

      &.active {
        background: $secondary-color;
        border-color: $secondary-color;
      }
    }

    .btn-save:disabled {
      border-color: #0badad !important;
    }
    // ----END BUTTON----
  }
}
