$primary-color: #15cdca;
$secondary-color: #f06a0f;

#digistall {
  .setting-page {
    background-color: #f2fafa;
    font-size: 16px;

    // .form-control {
    //   font-size: 16px;
    //   &:active,
    //   &:focus {
    //     box-shadow: none;
    //     border-color: $primary-color;
    //   }
    // }

    #shop-domain {
      color: #15cdca;
    }

    .add-more-image,
    #shop-domain {
      font-size: 0.65rem;
    }

    .form-check-input:not(:checked) {
      border: 1px solid #dee2e6;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0, 0, 0, 0.25)'/%3e%3c/svg%3e");
    }

    .form-check-input:checked {
      background-color: $primary-color;
      border-color: $primary-color;
    }

    button {
      width: 212px;
    }

    .btn ~ .btn {
      margin-top: 0;
      width: 200px;
    }

    .btn-cancel {
      border: 1px solid;
    }

    .title {
      color: $secondary-color;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .nav-item {
      flex: 1;
      min-width: 120px; // Minimum width for each tab
    }

    .nav-link {
      cursor: pointer;
      text-align: center;
      border: 1px solid #dee2e6 !important;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      transition: all 0.3s ease-in-out;
      width: 100%;
      font-weight: 600;

      &:hover {
        color: $secondary-color !important;
        background-color: #dee2e6 !important;
      }
    }

    .nav-link:not(.active) {
      background-color: white;
      color: $secondary-color;
    }

    .nav-link.active {
      background-color: #88d0ce !important;
      color: white !important;
    }

    .box-add {
      border: 1px dashed $secondary-color;
    }

    .nav-tabs {
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    // fulfillment
    .fulfillment {
      .delivery-options {
        background-color: #f7f6f7;
      }
    }
  }

  .errorMessage {
    color: #dc3545;
    font-size: 12px;
  }

  .list__link_external {
    display: flex;
    flex-direction: column;
    &-item {
      display: flex;
      margin-top: 6px;
      > a {
        width: 400px;
        color: #70acdb !important ;
        text-decoration: underline !important;
        font-size: 0.8rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        // position: absolute;
      }
      > img {
        width: 24px;
        height: 24px;
        margin-left: 4px;
        cursor: pointer;
      }
    }
  }
}
