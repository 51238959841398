.modal-congratulation-create-shop {
  .modal-content {
    border-radius: 12px;
  }

  .custom-input {
    background-color: white;
  }

  .generated-domain-box {
    text-align: center;
    padding: 24px;

    .generated-domain-check-icon {
      color: #28a745;
      font-size: 48px;
      margin-bottom: 16px;
    }

    .generated-domain-name {
      .txt-congratulation {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 16px;
        display: block;
      }

      p {
        margin-bottom: 8px;
        font-weight: 500;
      }

      .txt-link {
        color: #007bff;
        word-break: break-all;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .generated-domain-share {
      border: none;
      margin-top: 32px !important;

      .list-group-item {
        border: none;
        padding: 16px;
        margin-bottom: 8px;
        border-radius: 8px;
        background-color: #f8f9fa;
        transition: all 0.3s ease;

        &:hover {
          background-color: #f0f0f0;
        }

        .item-content {
          display: flex;
          align-items: center;
          gap: 12px;
          color: #495057;

          .text {
            font-size: 0.75rem;
            font-weight: 500;
          }

          .icon-wrapper {
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;

            &.facebook {
              background-color: rgba(24, 119, 242, 0.1);
              color: #1877f2;
            }

            &.whatsapp {
              background-color: rgba(37, 211, 102, 0.1);
              color: #25d366;
            }

            &.link {
              background-color: rgba(253, 164, 96, 0.1);
              color: #fda460;
            }
          }
        }

        .social-share-btn {
          min-width: 100px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          border-radius: 6px;
          transition: all 0.3s ease;

          &.facebook-share {
            --btn-color: #1877f2;
            background-color: transparent;
            color: var(--btn-color);
            border: 1px solid var(--btn-color);

            &:hover {
              background-color: var(--btn-color);
              color: white;
            }
          }

          &.whatsapp-share {
            --btn-color: #25d366;
            background-color: transparent;
            color: var(--btn-color);
            border: 1px solid var(--btn-color);

            &:hover {
              background-color: var(--btn-color);
              color: white;
            }
          }

          svg {
            font-size: 18px;
          }
        }

        .input-wrapper {
          flex: 1;
          margin: 0 16px;

          .text {
            font-size: 0.75rem;
            margin-right: auto;
            display: flex;
          }

          .form-group {
            margin: 0;
          }
        }

        .box.link {
          background-color: #fda460;
          padding: 8px;
          border-radius: 4px;
        }

        .facebook {
          color: #1877f2;
        }

        .whatsapp {
          color: #25d366;
        }
      }
    }
  }
}
