$primary-color: #15cdca;
$secondary-color: #f06a0f;

#digistall {
  .add-items.create-items-page {
    min-height: auto;
    display: block;
    font-size: 16px;
    margin-top: 68px;
    padding-bottom: 50px;
    padding-top: 1rem;

    .txt-add-sub-item {
      display: block;
    }

    .product-name,
    .product-description,
    .product-price {
      flex: 8;
    }

    .title {
      color: $secondary-color;
      font-size: 20px;
    }

    .product-item {
      .upload-image-square {
        margin-bottom: 1.2rem;
        position: relative; // Add this

        .upload-image {
          box-shadow: none;
          border-radius: 0;
          border: 1px dashed;
          height: 105px;
        }

        .btn-remove-image {
          position: absolute;
          top: 1px; // Thay đổi từ -8px thành 8px
          right: 8px; // Thay đổi từ -8px thành 8px
          background: rgba(255, 255, 255, 0.9); // Thêm độ trong suốt
          border: none;
          color: #64748b;
          border-radius: 50%;
          width: 22px;
          height: 22px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.2s ease;
          z-index: 999;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

          &:hover {
            background: rgba(255, 255, 255, 1);
            color: #dc3545;
            transform: scale(1.1);
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
          }

          svg {
            display: block;
            width: 14px;
            height: 14px;
          }
        }
      }
    }

    .detail-list-news__item {
      color: $primary-color;
    }

    img {
      width: 35px;
    }

    .upload-image-txt {
      position: inherit;
      padding-top: 0;
      display: contents;
    }

    .btn-action {
      justify-content: end;
      div {
        margin-right: 1rem;
        cursor: pointer;
      }

      .arrow-drap {
        position: inherit;
        transform: translateY(0);
      }
    }

    .btn-cancel {
      border: 1px solid $primary-color;
      color: $primary-color;
      &:hover {
        background-color: #f8f8f8;
      }
    }

    .image-uploaded {
      position: absolute;
      width: 100%;
      object-fit: cover;
      height: 100%;
      top: 0;
      left: 0;
    }

    .sub-item-info,
    .sub-item-div {
      cursor: pointer;
      .form-control:focus,
      .form-control:active {
        box-shadow: none !important;
        outline: none;
      }
      .form-group {
        width: 100%;
      }
    }

    .c-form .slider-toogle input:checked + .slider:before {
      transform: translate(22px, -9px);
    }

    input.is-error {
      .form-group {
        padding-bottom: 20px;
      }
    }

    .form-group {
      position: relative;
      margin-bottom: 0.5rem;
    }

    .sub-item-option {
      // Add relative positioning to container
      position: relative;
      margin-bottom: 20px; // Add space for error message

      .sub-item-info {
        // Adjust sub-item info containers
        &.col-2 {
          position: relative;

          .form-group {
            margin-bottom: 0;

            // Adjust error message positioning for price field
            .error-message {
              left: 0;
              white-space: nowrap; // Prevent text wrapping
              font-size: 12px;
              line-height: 1.2;
              width: max-content; // Allow text to extend beyond container
              z-index: 1; // Ensure error shows above other elements
              overflow: hidden; // Truncate text
              text-overflow: ellipsis; // Add ellipsis for truncated text
            }
          }
        }
      }
    }

    .sub-item.c-form {
      color: #15cdca;
    }
  }

  .content {
    font-size: 16px !important;
  }

  .focus-change-border:focus {
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    outline: none;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }

  .btn-add-item {
    border: 1px solid;
    padding: 5px 10px;
    border-radius: 5px;
    color: #15cdca;
    border-color: #15cdca;
    font-size: 14px;

    &:hover {
      transition: all 0.2s ease-out;
      color: #15cdca;
      background-color: #f8f8f8 !important;
    }
  }
}

.modal-product-setting {
  .form-control-sm {
    &:focus,
    &:active {
      outline: none;
    }
  }

  label {
    color: #64748b !important;
  }
}

@media screen and (max-width: 576px) {
  #digistall {
    .add-items {
      padding-left: 12px;
      padding-right: 12px;
    }

    .add-items.create-items-page {
      .upload-image {
        width: 80px !important;
        height: 80px !important;
      }
    }

    .add-items.create-items-page {
      .product-item {
        .upload-image-square {
          margin-bottom: 1.8rem;
        }
      }

      .product-price {
        .error-message {
          display: none;
        }
      }

      .upload-image-txt {
        font-size: 10px !important;
      }
    }

    .sub-item-option {
      .sub-item-info.col-2 {
        .form-group .error-message {
          font-size: 10px;
        }
      }
    }

    .custom-button {
      height: auto;
    }
  }
}
