@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.shop-detail-skeleton {
  .skeleton-base {
    background: linear-gradient(to right, #f6f7f8 8%, #edeef1 18%, #f6f7f8 33%);
    background-size: 2000px 100%;
    animation: shimmer 2s linear infinite;
    border-radius: 4px;
  }

  // Banner skeleton
  .banner-skeleton {
    margin-bottom: 1.5rem;

    &__header {
      margin-bottom: 1rem;

      .skeleton-title {
        @extend .skeleton-base;
        height: 34px;
        width: 50%;
      }
    }

    &__media {
      @extend .skeleton-base;
      height: 500px;
      margin-bottom: 1.5rem;

      @media screen and (max-width: 768px) {
        height: 300px;
      }
    }

    &__description {
      .skeleton-text {
        @extend .skeleton-base;
        height: 16px;
        margin-bottom: 0.5rem;

        &:first-child {
          width: 80%;
        }

        &:last-child {
          width: 60%;
        }
      }
    }
  }

  // View selector skeleton
  .view-selector-skeleton {
    display: flex;
    gap: 0.75rem;
    margin-bottom: 1.5rem;

    .skeleton-button {
      @extend .skeleton-base;
      width: 100px;
      height: 40px;
    }
  }

  // Products grid skeleton
  .products-skeleton {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    margin-bottom: 3rem;

    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .product-skeleton {
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    &__image {
      @extend .skeleton-base;
      height: 200px;

      @media screen and (max-width: 425px) {
        height: 120px;
      }
    }

    &__content {
      padding: 1rem;

      .skeleton-title {
        @extend .skeleton-base;
        height: 20px;
        width: 80%;
        margin-bottom: 0.5rem;
      }

      .skeleton-text {
        @extend .skeleton-base;
        height: 16px;
        width: 100%;
        margin-bottom: 0.5rem;
      }

      .skeleton-price {
        @extend .skeleton-base;
        height: 24px;
        width: 40%;
        margin-bottom: 1rem;
      }

      .skeleton-button {
        @extend .skeleton-base;
        height: 40px;
        width: 100%;
      }
    }
  }

  // Checkout button skeleton
  .checkout-skeleton {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .skeleton-button {
      @extend .skeleton-base;
      width: 50%;
      height: 50px;
    }
  }
}
