@import '../../../assets/css/base/functions';
@import '../../../assets/css/base/variables';
@import '../../../assets/css/base/mixins';

@import '../../../styles/breakpoints.scss';
@import '../../../styles/font-size-utility.scss';

//Color Variables
$sidebar-background-color: #313443;
$active-sidebar-link-color: #22252e;
$hover-sidebar-link-color: $active-sidebar-link-color;
$active-link-color: #98d7ec;
$tooltip-background-color: $sidebar-background-color;
$primary-color: #15cdca;
$orange-color: #fd7e14;

#digistall {
  .sidebar {
    color: #1ca5a2;
    position: fixed; // Fix cứng vị trí
    top: 64px; // Căn với chiều cao của header
    left: 0;
    bottom: 0; // Kéo dài đến cuối trang
    width: 280px; // Hoặc width phù hợp với thiết kế
    background: #fff; // Màu nền sidebar
    z-index: 100; // Đảm bảo hiển thị trên các phần tử khác
    transition: all 0.3s ease;
    border-right: 1px solid #dee2e6;
    transition: width 0.3s ease;

    // Khi sidebar collapse
    &--collapsed {
      width: 125px; // Width khi thu gọn

      .menu-item {
        width: fit-content;
      }
    }

    &.loading .menu-item:hover {
      background-color: inherit;
    }
    &.loading .menu-item a:hover {
      color: inherit !important;
    }
    &.loading .menu-item a .icons {
      color: inherit;
    }
    &.loading .menu-item .tooltip {
      visibility: hidden;
      opacity: 0;
    }

    .list-menu {
      display: inline-block;
      width: 100%;

      .menu-item {
        cursor: pointer;
        transition: all ease-out 120ms;
        font-weight: 600;
        // font-size: 14px;
        margin: 4px 0;
        border-radius: 6px;

        &:hover {
          background-color: rgba(253, 126, 20, 0.1);
        }

        a {
          display: flex;
          align-items: center;
          padding: 5px 12px;
          text-decoration: none;
          width: 100%;
          @extend %small-text-responsive;

          // Default color if no custom theme
          color: #1ca5a2;

          .icons {
            font-size: 20px;
            margin-right: 12px;
            color: currentColor;
            transform: translateY(-2px);
          }

          &:hover {
            color: $orange-color !important;

            .icons {
              color: $orange-color;
            }
          }

          &.text-orange {
            color: $orange-color !important;

            .icons {
              color: $orange-color;
            }
          }
        }

        .tooltip {
          display: inline-block;
          position: absolute;
          background-color: #fd7e14;
          padding: 8px 15px;
          border-radius: 3px;
          margin-top: -3px;
          left: 65px;
          opacity: 0;
          visibility: hidden;
          font-size: 13px;
          width: max-content;
          letter-spacing: 0.5px;
          color: white !important;

          &:before {
            content: '';
            display: block;
            position: absolute;
            left: -4px;
            top: 12px;
            transform: rotate(45deg);
            width: 10px;
            height: 10px;
            background-color: inherit;
          }
        }

        &:hover {
          .tooltip {
            visibility: visible;
            opacity: 1;
          }
        }

        &.active {
          background-color: black;
        }
      }
    }

    .style-menu {
      width: 25px;
      max-height: 433px;
      border-bottom: 25px solid transparent;
      border-right: 25px solid #f2f0f0;
      position: relative;
      cursor: pointer;
      z-index: 10;

      .group span {
        color: #707070;
      }

      span:nth-child(1) {
        position: absolute;
        top: 20px;
        font-weight: 600;
        right: -33px;
        font-size: 0.75rem;
        display: inline-block;
        transform: rotate(-90deg);
      }

      span:nth-child(2) {
        position: absolute;
        top: 60px;
        font-size: 18px;
        left: 6px;
      }
    }

    .prev-style-menu {
      width: 25px;
      height: 330px;
      border-top: 25px solid transparent;
      border-left: 25px solid #f2f0f0;
      position: relative;
      cursor: pointer;
      z-index: 10;

      span {
        color: #707070;
        position: absolute;
        width: 100px;
        top: 13%;
        font-size: 0.75rem;
        left: -65px;
        font-weight: 600;
        display: inline-block;
        transform: rotate(-90deg);
      }
    }

    .react-loading-skeleton {
      background-color: rgba(255, 255, 255, 0.1);

      &::after {
        background-image: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0.2),
          rgba(255, 255, 255, 0.1)
        );
      }
    }
  }
}
