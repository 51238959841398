// Variables
$primary-color: #0badad;
$color-danger: #d63031;
$border-color: #e2e8f0;
$disabled-color: #ebebe4;
$placeholder-color: #a0aec0;
$label-color: #2d3748;

@import '../../styles/breakpoints.scss';
@import '../../styles//font-size-utility.scss';

// Form group styles
.form-group {
  margin-top: 0;
  position: relative;

  label {
    display: block;
    margin-bottom: 0.25rem;
    font-size: 0.75rem;
    font-weight: 500;
    color: $label-color;
  }

  .input-invalid {
    border: 1px solid $color-danger !important;
    background-color: rgba($color-danger, 0.1) !important;
  }

  .text-danger {
    font-size: 12px;
  }

  // Add to style.scss
  .wrapper-input {
    position: relative;
    display: flex;
    align-items: center;

    .input-icon {
      position: absolute;
      left: 12px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;

      i,
      svg {
        color: #6c757d;
        font-size: 20px;

        &.icon-search {
          height: 16px;
          width: 16px;
        }
      }
    }

    &.has-icon {
      input {
        padding-left: 28px;
      }
    }

    &.icon-search {
      width: 100%;

      input {
        padding-left: 35px;
      }
    }

    .form-control {
      width: 100%;
    }
  }
}

// Input and textarea shared styles
%form-control-shared {
  border: 1px solid $border-color;
  border-radius: 0.25rem;
  padding: 0.5rem;
  transition: all 0.15s ease-in-out;
  box-shadow: none !important;
  height: 34px;

  @extend %text-responsive;

  @media screen and (max-width: 480px) {
    // padding: 0.25rem;
    height: auto;
  }

  &:hover:not(:focus):not(:disabled) {
    border-color: $primary-color;
  }

  &:focus:not(:disabled) {
    border-color: $primary-color;
    box-shadow: 0 0 0 3px rgba($primary-color, 0.1);
    outline: none;
  }

  &::placeholder {
    color: $placeholder-color;
    @extend %text-responsive;
  }

  &:disabled {
    background-color: $disabled-color;
    cursor: not-allowed;
    pointer-events: all !important;
    opacity: 0.7;
    border-color: $border-color;

    &:hover {
      border-color: $border-color;
    }
  }

  &.is-invalid {
    border: 1px solid $color-danger;
    // background-color: rgba($color-danger, 0.1);
    background: rgba($color-danger, 0.1);

    &:focus {
      box-shadow: 0 0 0 3px rgba($color-danger, 0.1);
    }
  }
}

.custom-input.form-control {
  @extend %form-control-shared;
}

textarea.form-control {
  @extend %form-control-shared;
  min-height: 100px;
  resize: vertical;
}

// Switch toggle styles
.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 18px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #dee2e6;
    border: 1px solid #ced4da;
    transition: 0.3s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: '';
      height: 12px;
      width: 12px;
      top: 50%;
      transform: translate(2.5px, -50%);
      background-color: white;
      transition: 0.3s;
      border-radius: 50%;
    }
  }

  input:checked + .slider {
    background-color: $primary-color;
    border: 1px solid $primary-color;

    &:before {
      transform: translate(calc(100% + 3.5px), -50%);
    }
  }

  &.disabled {
    cursor: not-allowed !important;
    background-color: transparent !important;
    pointer-events: all !important;

    .slider {
      border-color: $disabled-color;
      background-color: $disabled-color;
      cursor: not-allowed;
    }
  }
}

// Radio button styles
.radio {
  display: flex !important;
  align-items: center;
  margin-bottom: 0px !important;

  input {
    opacity: 0;
    position: absolute;
  }

  .radio-mark {
    position: relative;
    height: 18px; // Increased size
    width: 18px; // Increased size
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;

    &:after {
      content: '';
      position: absolute;
      display: none;
      top: 50%;
      left: 50%;
      width: 10px; // Increased size
      height: 10px; // Increased size
      background: $primary-color;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }

  input:checked + .radio-mark {
    border-color: $primary-color;

    &:after {
      display: block;
    }
  }

  .radio-label {
    cursor: pointer;
    font-size: 0.75rem; // Increased font size
  }

  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;
    pointer-events: all !important;

    .radio-mark {
      background-color: $disabled-color;
      cursor: not-allowed;
    }

    .radio-label {
      cursor: not-allowed;
      color: $placeholder-color;
    }
  }
}

// Checkbox styles
.checkbox {
  display: flex !important;
  align-items: center;
  cursor: pointer;

  input {
    opacity: 0;
    position: absolute;
  }

  .checkbox-mark {
    position: relative;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 3px;
    margin-right: 5px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 5px;
      top: 2px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  input:checked + .checkbox-mark {
    background-color: $primary-color;
    border-color: $primary-color;

    &:after {
      display: block;
    }
  }

  .checkbox-label {
    cursor: pointer;
    font-size: 0.75rem;
    color: $label-color;
  }

  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;
    pointer-events: all !important;

    .checkbox-mark {
      background-color: $disabled-color;
      border-color: #ced4da;
      cursor: not-allowed;
    }

    .checkbox-label {
      cursor: not-allowed;
      color: $placeholder-color;
    }
  }
}

// Add these styles at the end of the file
.react-date-picker {
  width: 100%;

  .react-date-picker__wrapper {
    border: 1px solid $border-color;
    border-radius: 0.25rem;
    height: 34px; // Reduced height
    padding: 0.25rem; // Reduced padding
    font-size: 0.75rem !important;
    transition: all 0.15s ease-in-out;

    &:hover:not(:focus-within) {
      border-color: $primary-color;
    }

    &:focus-within {
      border-color: $primary-color;
      box-shadow: 0 0 0 3px rgba($primary-color, 0.1);
      outline: none;
    }
  }

  .react-date-picker__inputGroup {
    padding: 0;
  }

  .react-date-picker__button {
    padding: 0 4px;

    &:hover .react-date-picker__button__icon {
      stroke: $primary-color;
    }
  }

  &--disabled {
    background-color: $disabled-color;
    opacity: 0.7;

    .react-date-picker__wrapper {
      border-color: $border-color;

      &:hover {
        border-color: $border-color;
      }
    }
  }
}

// Style for time input
input[type='time'].form-control {
  @extend %form-control-shared;
  min-width: 120px;

  &::-webkit-calendar-picker-indicator {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}
