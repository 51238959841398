.logo {
  height: 185px;
  img {
    width: 70%;
    display: block;
    margin: 0 auto;
  }

  small {
    font-size: 12px;
    color: #324e59;
    display: inline-block;
    transform: translate(30%, -80px);
    letter-spacing: 2px;
  }
}

@media screen and (max-width: 768px) {
  .logo {
    height: 100px;
    margin-bottom: 3rem;

    img {
      width: 50%;
      max-height: 100px;
      object-fit: contain;
    }

    small {
      transform: translate(20%, -40px);
      font-size: 10px;
    }
  }
}

@media screen and (max-width: 480px) {
  .logo {
    height: 100px;

    img {
      width: 50%;
      max-height: 100px;
    }
  }
}
