@import '../../../styles/breakpoints.scss';
@import '../../../styles/font-size-utility.scss';

.modal-fulfillment-date {
  .rdrCalendarWrapper {
    width: 100%;
    @extend %text-responsive;

    .rdrMonth {
      width: 100%;
      font-size: 0.7rem;
      padding: 0;
    }

    .rdrStartEdge,
    .rdrInRange,
    .rdrEndEdge {
      background: $primary-color;
    }

    .rdrDayHovered {
      border-color: $primary-color !important;
    }
  }
}

.modal-payment-screen {
  color: gray;

  .modal-title {
    text-align: center;
    flex-grow: 1;
    transform: translateX(5%);

    .c-txt-gra {
      color: #ff6903;
      font-size: 1.25rem;
      font-weight: 400;
    }
  }
}
