$padding-mobile: 0.5rem;
$padding-tablet: 0.75rem;
$padding-desktop: 1rem;

@mixin padding-responsive($mobile, $tablet, $desktop) {
  @include respond-to('mobile') {
    padding: $mobile;
  }

  @include respond-to('tablet') {
    padding: $tablet;
  }

  @include respond-to('desktop') {
    padding: $desktop;
  }
}

// Extend các biến padding cho các phần tử cụ thể
%padding-responsive {
  @include padding-responsive($padding-mobile, $padding-tablet, $padding-desktop);
}
