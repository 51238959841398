.payment-code {
  .contact-details {
    &__info {
      background-color: white;
      border-radius: 0.25rem;
      margin-bottom: 1rem;
      color: #64748b;

      @extend %padding-responsive;
    }

    &__row {
      display: flex;
      margin-bottom: 0px;
    }

    &__label {
      font-weight: 600;

      @extend %text-responsive;
    }

    &__value {
      word-break: break-word;
      @extend %text-responsive;
    }
  }
}
