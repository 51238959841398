$primary-card-color: #079d9a;

@import '../../styles/breakpoints.scss';

.manage-delivery-zones {
  height: 100%;

  .title {
    color: #f06a0f;
  }

  .delivery-zones-list {
    display: grid;
    grid-template-columns: 1fr; // Default for mobile: 1 column
    gap: 1rem;

    @include respond-to('tablet') {
      grid-template-columns: repeat(2, 1fr); // 2 columns for tablet
    }

    @include respond-to('desktop') {
      grid-template-columns: repeat(2, 1fr); // 2 columns for desktop
    }
  }

  .cardContainer {
    background-color: #f8f9fb;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;

    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
      padding: 0;
      background-color: transparent;
      border-bottom: 0;
    }

    .avatar {
      background-color: #e2e2e2;
      color: white;
      border-radius: 50%;
      width: 45px !important;
      height: 45px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: bold;
      object-fit: cover;
    }

    .info {
      display: flex;
      flex-direction: column;

      .accountName {
        font-weight: bold;
        font-size: 0.75rem;
      }

      .groupName {
        font-size: 12px;
        color: gray;
      }
    }

    .deliveryStatus {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      color: $primary-card-color;

      span {
        font-size: 0.6875rem;
      }
    }

    .body {
      border: 2px solid #edf2f9;
      border-radius: 5px;
      padding: 10px;
      text-align: center;
      // color: $primary-card-color;
      font-weight: bold;
      font-size: 14px;
    }

    .react-loading-skeleton {
      background-color: rgba(7, 157, 154, 0.1);

      &::after {
        background-image: linear-gradient(
          90deg,
          rgba(7, 157, 154, 0.1),
          rgba(7, 157, 154, 0.2),
          rgba(7, 157, 154, 0.1)
        );
      }
    }
  }
}
