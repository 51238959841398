.payment-code__qr-code {
  .group-list--event {
    .detail-list-news--org {
      @extend %padding-responsive;

      .detail-list-news__title,
      .detail-list-news__phone,
      .detail-list-news__instruction,
      .detail-list-news__order-number,
      .detail-list-news__qr-box,
      .detail-list-news__verification-info,
      .detail-list-news__form {
        text-align: center;
      }

      .detail-list-news__title {
        color: #939091;
        @extend %subtitle-responsive;
      }

      .detail-list-news__phone,
      .detail-list-news__order-number {
        color: #981c84;
        font-weight: bold;
      }

      .detail-list-news__instruction {
        margin-bottom: 2rem;
        @extend %subtitle-responsive;
      }

      .detail-list-news__order-number {
        margin-bottom: 3rem;
        @extend %title-responsive;
      }

      .detail-list-news__qr-box {
        .detail-list-news__qr-code {
          display: flex;
          justify-content: center;
          align-items: center;

          canvas {
            display: none;
          }

          img {
            display: block;
            width: 256px;
            height: 256px;
          }
        }

        .detail-list-news__reload-button {
          text-align: right;
          margin-top: 15px;

          button {
            background-color: transparent;
            border: none;
            color: #939091;
          }
        }
      }
    }
  }

  .detail-list-news__verification-info {
    @extend %text-responsive;
  }

  .detail-list-news__form {
    margin-bottom: 1.5rem;

    .detail-list-news__uploaded-image,
    .detail-list-news__preview-image,
    .detail-list-news__upload-image {
      margin: auto;
      border-radius: 0;
      box-shadow: none;

      &.position-relative {
        position: relative;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }

      .upload-image.upload-screenshot {
        box-shadow: none;
        border-radius: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
      }
    }

    .detail-list-news__reupload-button {
      width: 100%;
      position: relative;

      label {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin: 3rem 0 0;

        .w-auto {
          box-shadow: none;
          margin-top: 0;
        }
      }

      input {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
      }
    }
  }
}
