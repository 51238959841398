$primary-color: #15cdca;

.modal-delivery-zones {
  font-size: 16px;
  max-width: 600px;

  label {
    font-weight: 500;
    margin-bottom: 0.25rem;
  }

  .modal-title {
    width: 100%;
  }

  .modal-content {
    padding: 0px;
  }

  .modal-body {
    height: 350px;
    overflow-y: auto;

    .delivery-options-container {
      margin-bottom: 1rem;
    }

    .grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 1rem;
    }

    .grid-item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .date-picker {
      color: black;

      &:focus {
        color: black;
        box-shadow: 2px 2px 12px -2px rgba(0, 0, 0, 0.08) !important;
      }
    }

    .react-datepicker-wrapper {
      width: 100%;
      position: relative;
    }

    .react-datepicker-wrapper .react-datepicker__input-container {
      display: flex;
      align-items: center;
      // .form-control {
      //   font-size: 16px;
      // }
    }

    .react-datepicker-wrapper .react-datepicker__input-container input {
      width: 100%;
      padding-right: 30px; /* Đảm bảo có đủ không gian cho icon */
    }

    .react-datepicker-wrapper .react-datepicker__calendar-icon {
      position: absolute;
      right: 10px;
      pointer-events: none;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .btn-submit {
    &:active,
    &:focus {
      box-shadow: none;
    }
  }

  // Add to style.scss
  .react-datepicker-wrapper {
    position: relative;
    width: 100%;
    min-width: 100px;
    font-size: 0.75rem;
    user-select: none;

    .date-picker {
      width: 100%;
      padding: 8px 12px;
      background: white;
      border: 1px solid #e2e8f0 !important;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.2s ease;
      color: #59687c !important;
      font-size: 0.75rem;
      min-width: 236px;

      &::placeholder {
        color: #94a3b8;
      }

      &:hover {
        border-color: $primary-color !important;
      }

      &:focus {
        border-color: $primary-color;
        box-shadow: 0 0 0 1px $primary-color;
        outline: none;
      }

      &.input-invalid {
        border: 1px solid #d63031 !important;
      }
    }

    .react-datepicker-wrapper {
      width: 100%;
    }

    .react-datepicker__calendar-icon {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      color: #59687c;
      pointer-events: none;
    }

    &.disabled {
      opacity: 0.6;
      pointer-events: none;

      .date-picker {
        background-color: #f1f5f9;
        cursor: not-allowed;
        border-color: #e2e8f0;
        color: #64748b;

        &:hover {
          border-color: #e2e8f0;
        }
      }

      .react-datepicker__calendar-icon {
        color: #94a3b8;
      }
    }
  }
}

.delivery-fee-section {
  .delivery-fee-wrapper {
    background: #fff;

    .delivery-zone-row {
      .zone-number {
        font-weight: 500;
        color: #64748b;
        min-width: 24px;
      }

      .currency-symbol {
        color: #64748b;
        font-weight: 500;
      }

      &__wrap-input-icon {
        position: relative;

        .icon-money {
          position: absolute;
          left: 5px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .add-zone-btn {
      margin-top: 0;
      height: 34px;
      font-size: 0.875rem;

      svg {
        font-size: 0.75rem;
      }
    }
  }
}
