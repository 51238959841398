$secondary-color: #f06a0f;
$slate: #64748b;

.no-orders-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  margin: 60px auto;
  max-width: 600px;
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;

  .icon {
    color: $slate;
    font-size: 64px;
    opacity: 0.8;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    color: $slate !important;
    margin-bottom: 12px;
  }

  .subtitle {
    font-size: 16px;
    color: #95a5a6;
    line-height: 1.5;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
