.tab-pickup__select-box {
  margin-bottom: 20px;

  @include respond-to('mobile') {
    margin-bottom: 10px;
  }

  @include respond-to('tablet') {
    margin-bottom: 15px;
  }

  @include respond-to('desktop') {
    margin-bottom: 25px;
  }
}

.tab-pickup__error {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 5px;
}

.tab-pickup__address-label {
  display: block;
  margin-top: 20px;
  font-weight: 600;

  @include respond-to('mobile') {
    margin-top: 10px;
    font-size: $font-size-text-mobile;
  }

  @include respond-to('tablet') {
    margin-top: 15px;
    font-size: $font-size-text-tablet;
  }

  @include respond-to('desktop') {
    margin-top: 25px;
    font-size: $font-size-text-desktop;
  }
}

.tab-pickup__address {
  margin-top: 5px;
  font-size: $font-size-text-mobile;
  color: #64748b;
  margin-bottom: 0px;

  @include respond-to('tablet') {
    font-size: $font-size-text-tablet;
  }

  @include respond-to('desktop') {
    font-size: $font-size-text-desktop;
  }
}

.tab-pickup__location {
  @include respond-to('tablet') {
    border-radius: 6px;
  }

  @include respond-to('desktop') {
    border-radius: 8px;
  }
}
