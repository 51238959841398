$secondary-color: #f06a0f;
$primary-color: #15cdca;

@import '../../styles/breakpoints.scss';
@import '../../styles/font-size-utility.scss';
@import '../../styles/padding-utility.scss';

@import './styles/modals.scss';
@import './styles/delivery-actions.scss';
@import './styles/delivery-list.scss';

#digistall {
  .delivery {
    font-size: 14px;
    padding: 1rem;

    .title,
    strong {
      color: $secondary-color;
      font-weight: bold;
    }

    .title {
      @include respond-to('mobile') {
        font-size: 1.5rem;
      }
    }

    .dropdown-toggle::after {
      transform: translateX(10px);
    }
  }
  .delivery-list__table-container {
    // Add height constraint to table container
    height: calc(100vh - 100px); // Adjust based on your layout
    overflow: hidden; // Hide overflow at container level

    .custom-table {
      height: 62vh; // Take full height of container
      @include respond-to('desktop') {
        height: 78vh;
      }
    }
  }

  // Ensure dropdowns in the table are not cut off
  .custom-table {
    .select-status {
      .dropdown-menu,
      .select-options {
        max-height: 200px;
        overflow-y: auto;
      }
    }

    a.paid {
      &::before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        vertical-align: middle;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.363' height='14.422' viewBox='0 0 15.363 14.422'%3E%3Cpath id='Icon_feather-paperclip' data-name='Icon feather-paperclip' d='M17.16,8.176l-6.693,5.8a4.864,4.864,0,0,1-6.184,0,3.439,3.439,0,0,1,0-5.355l6.693-5.8a3.243,3.243,0,0,1,4.122,0,2.293,2.293,0,0,1,0,3.57l-6.7,5.8a1.621,1.621,0,0,1-2.061,0,1.146,1.146,0,0,1,0-1.785l6.184-5.349' transform='translate(-2.503 -1.409)' fill='none' stroke='%23079d9a' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A");
      }
    }

    a.paid-combined {
      color: #51aaaa;

      &::before {
        content: '';
        display: inline-block;
        width: 30px;
        height: 20px;
        vertical-align: middle;
        background-position: left, right;
        background-repeat: no-repeat, no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.363' height='14.422' viewBox='0 0 15.363 14.422'%3E%3Cpath id='Icon_feather-paperclip' data-name='Icon feather-paperclip' d='M17.16,8.176l-6.693,5.8a4.864,4.864,0,0,1-6.184,0,3.439,3.439,0,0,1,0-5.355l6.693-5.8a3.243,3.243,0,0,1,4.122,0,2.293,2.293,0,0,1,0,3.57l-6.7,5.8a1.621,1.621,0,0,1-2.061,0,1.146,1.146,0,0,1,0-1.785l6.184-5.349' transform='translate(-2.503 -1.409)' fill='none' stroke='%23079d9a' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A"),
          url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.363' height='14.422' viewBox='0 0 15.363 14.422'%3E%3Cpath id='Icon_feather-paperclip' data-name='Icon feather-paperclip' d='M17.16,8.176l-6.693,5.8a4.864,4.864,0,0,1-6.184,0,3.439,3.439,0,0,1,0-5.355l6.693-5.8a3.243,3.243,0,0,1,4.122,0,2.293,2.293,0,0,1,0,3.57l-6.7,5.8a1.621,1.621,0,0,1-2.061,0,1.146,1.146,0,0,1,0-1.785l6.184-5.349' transform='translate(-2.503 -1.409)' fill='none' stroke='%23079d9a' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A");
      }
    }

    .order-number-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      svg {
        fill: #a6dfde;
        height: 24px;
        width: 24px;

        &:hover {
          fill: darken(#a6dfde, 15%);
        }
      }
    }
  }
}
