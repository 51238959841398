:root {
  /* Custom image zoom */
  --rmiz-overlay-bg: rgba(0, 0, 0, 0.85);
  --rmiz-btn-bg: rgba(255, 255, 255, 0.8);
  --rmiz-btn-color: #333;
  --rmiz-transition: 0.3s ease;
}

[data-rmiz] {
  height: 100%;
}

[data-rmiz-content] {
  height: 100%;
}

[data-rmiz-ghost] {
  position: absolute;
  pointer-events: none;
}

/* Zoom buttons styling */
[data-rmiz-btn-zoom] {
  display: none;
}

[data-rmiz-btn-unzoom] {
  background-color: var(--rmiz-btn-bg);
  border-radius: 50%;
  border: none;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
  color: var(--rmiz-btn-color);
  height: 2rem;
  width: 2rem;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  transition: all var(--rmiz-transition);

  svg {
    height: 1rem;
    width: 1rem;
  }

  &:hover {
    transform: scale(1.05);
    background-color: #fff;
  }
}

[data-rmiz-btn-zoom] {
  position: absolute;
  inset: 0.625rem 0.625rem auto auto;
  cursor: zoom-in;

  &:hover {
    transform: scale(1.05);
    background-color: #fff;
  }
}

[data-rmiz-btn-unzoom] {
  position: absolute;
  inset: 1.25rem 1.25rem auto auto;
  cursor: zoom-out;
  z-index: 1;
}

[data-rmiz-modal] {
  display: none;

  &[open] {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    overflow: hidden;
  }
}

/* Modal styling */
[data-rmiz-modal-overlay] {
  position: absolute;
  inset: 0;
  transition: background-color var(--rmiz-transition);
  backdrop-filter: blur(0.125rem);

  &[data-rmiz-modal-overlay='hidden'] {
    background-color: transparent;
  }

  &[data-rmiz-modal-overlay='visible'] {
    background-color: var(--rmiz-overlay-bg);
  }
}

[data-rmiz-modal-content] {
  position: relative;
  width: 100%;
  height: 100%;
}

[data-rmiz-modal-img] {
  position: absolute;
  cursor: zoom-out;
  image-rendering: high-quality;
  transform-origin: top left;
  transition: transform var(--rmiz-transition);
  border-radius: 0.25rem;
}

/* Container styling */
[data-rmiz-content='found'] {
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
  }

  &::before {
    /* Use SVG magnifying glass with plus icon */
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3Cline x1='11' y1='8' x2='11' y2='14'%3E%3C/line%3E%3Cline x1='8' y1='11' x2='14' y2='11'%3E%3C/line%3E%3C/svg%3E");
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    font-size: 2rem;
    color: white;
    z-index: 1;
    transition: transform 0.3s ease;
    pointer-events: none;
    width: 32px;
    height: 34px;
  }

  img {
    object-fit: contain;

    &:hover {
      transform: scale(1.05);
      filter: brightness(0.8);
    }
  }

  &:hover {
    &::after {
      opacity: 1;
    }

    &::before {
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  [data-rmiz-modal-overlay],
  [data-rmiz-modal-img] {
    transition-duration: 0.01ms !important;
  }
}
