@import '../styles/breakpoints.scss';
@import '../styles/font-size-utility.scss';

.landing-page {
  .header-copyright {
    &__text {
      text-align: end;
      padding: 0.5rem;
      color: #64748b;
      font-size: 16px;
    }
  }

  #home {
    h1 {
      font-size: 1.25rem;
    }
  }

  #pricing {
    .title {
      color: #121212;
      font-weight: 600;
    }
  }

  @include respond-to('mobile') {
    .header-copyright {
      &__text {
        padding: 0.25rem;
        font-size: 0.75rem;
        text-align: center;
      }
    }

    .logo {
      margin: 0px;
    }

    .section {
      padding-top: 0px;
    }

    #home {
      padding-top: 1rem;

      h4 {
        font-size: 24px;
      }

      h1 {
        font-size: 18px;
      }

      .row {
        padding: 1rem;
      }

      .custom-button {
        font-size: 1rem;
      }
    }

    #features {
      padding-top: 2rem;
    }

    #pricing {
      .subscription {
        padding: 1rem 10px !important;

        .card-footer {
          height: auto !important;
        }
      }
    }

    .about-us {
      flex-direction: column-reverse;

      h4 {
        margin-bottom: 1rem;
      }

      img {
        width: 200px !important;
        margin: 0 auto;
      }
    }
  }
}
