.checkout {
  .bill {
    &__title {
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 0.5rem;
      color: #64748b;

      @extend %title-responsive;
    }

    &__container {
      background-color: white;
      border-radius: 0.25rem;
      height: 100%;

      @extend %padding-responsive;

      .product__item.row {
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin-bottom: 0.25rem;

        .product__name {
          overflow-wrap: anywhere;
        }

        .product__price {
          @extend %text-responsive;
        }

        .product__quantity,
        .product__total-price {
          text-align: right;
          @extend %text-responsive;
        }

        .product__total-price {
          font-weight: bold;
          // color: #15cdca;
        }

        .toc-list {
          list-style: none;
          padding: 0;
          margin: 0;
          line-height: 1.3;

          li {
            @extend %text-responsive;

            .product__item {
              font-size: 0.75rem;
              margin-top: 5px;
              display: block;

              @media screen and (max-width: 480px) {
                font-size: 0.65rem;
              }
            }
          }
        }

        .text-secondary {
          color: #6c757d;
        }

        .fw-bold {
          font-weight: bold;
        }

        .text-end {
          text-align: end;
        }

        .text-break {
          word-break: break-word;
        }
      }
    }

    &__divider {
      background-color: black;
      height: 1px;
      border: none;
    }

    &__total-price {
      color: black;

      @include respond-to('mobile') {
        font-size: $font-size-text-mobile;
      }

      @include respond-to('tablet') {
        font-size: $font-size-text-tablet;
      }

      @include respond-to('desktop') {
        font-size: $font-size-text-desktop;
      }
    }

    &__fees-info {
      border-radius: 0.25rem;

      & > p {
        font-weight: 600;
        color: #121212;
      }

      &__item {
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #64748b;

        @extend %text-responsive;

        &--price {
          font-weight: bold;
          // color: #15cdca;
          margin-left: auto;
        }
      }

      &__discount,
      &__transaction-fee {
        color: #f06a0f;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @extend %text-responsive;
      }

      &__divider {
        background-color: black;
        height: 1px;
        border: none;
        margin: 1rem 0;
      }

      &__total {
        font-weight: bold;
        color: #15cdca;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @extend %text-responsive;
      }
    }
  }
}
