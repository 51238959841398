#digistall {
  .color-picker-body {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .color-picker-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .color-picker-label {
    margin-bottom: 0.5rem;
  }

  .color-picker-input {
    width: 100%;
    max-width: 200px;
    height: 45px;
    padding: 3px;
    background: #fff;
    border: 2px solid #e2e8f0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: #94a3b8;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
      transform: translateY(-1px);
    }

    &:focus {
      outline: none;
      border-color: #64748b;
      box-shadow: 0 0 0 3px rgba(100, 116, 139, 0.2);
    }

    &::-webkit-color-swatch-wrapper {
      padding: 0;
    }

    &::-webkit-color-swatch {
      border: none;
      border-radius: 6px;
      width: 100%;
      height: 100%;
    }

    &::-moz-color-swatch {
      border: none;
      border-radius: 6px;
    }
  }

  .color-picker-footer {
    display: flex;
    margin-top: 1rem;
    gap: 1rem;
  }

  @media screen and (max-width: 480px) {
    .color-picker-footer {
      gap: 1rem;
    }
  }

  @media (min-width: 768px) {
    .color-picker-item {
      width: calc(50% - 1rem);
      align-items: flex-start;
    }

    .color-picker-footer {
      justify-content: flex-end;
    }
  }

  @media (min-width: 1024px) {
    .color-picker-item {
      width: calc(33.333% - 1rem);
      align-items: flex-start;
    }

    .color-picker-footer {
      justify-content: flex-end;
      gap: 1rem;
    }
  }
}
