$orange: #ff6903;

.black__list {
  height: 300px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  background: #fafafa;
  padding: 16px;
  overflow-y: auto;

  &-box {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 8px 0;
  }

  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(148, 163, 184, 0.05);

    span {
      color: #2d3748;
      font-size: 14px;
      font-weight: 500;
    }

    &:hover {
      background: #f7fafc;
      border-color: #cbd5e0;
      box-shadow: 0 4px 6px rgba(148, 163, 184, 0.1);
      transform: translateY(-2px);

      span {
        color: #1a202c;
      }

      .remove-icon {
        color: #e53e3e;
        opacity: 1;
      }
    }

    .remove-icon {
      font-size: 18px;
      color: #718096;
      opacity: 0.6;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        opacity: 1;
        transform: scale(1.2);
      }
    }
  }
}

.color-picker {
  .btn-primary:disabled {
    border-color: #0badad !important;
    opacity: 0.5;
  }
}

.advance {
  .form-check-input {
    margin-left: 0;
  }
}

.animated-underline {
  position: relative;
  text-decoration: none !important;
  cursor: pointer;
  color: $orange;
  font-size: 16px;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $orange;
    transition: width 0.3s ease-in-out;
  }

  &:hover::after {
    width: 100%;
  }
}

.form-privacy-mode {
  display: flex;
  align-items: center;
  gap: 1rem;

  .form-group {
    width: 50%;
  }

  .wrapper-btn-white-list {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .advance {
    p {
      font-size: 0.75rem;
      margin-bottom: 0.25rem;

      &.fw-bold {
        font-size: 0.85rem;
      }
    }
  }

  .privacy-mode {
    .col-10 {
      width: 100%;
      margin-bottom: 0.5rem;
    }

    .col-2 {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }

    .col-12.d-flex {
      flex-direction: row;
      align-items: flex-start;

      .form-group {
        width: 100%;
      }

      .wrapper-btn-white-list {
        display: flex;
        margin-top: 0px;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}

// Add responsive styles
@media screen and (max-width: 480px) {
  .advance {
    padding: 1rem !important;

    .row {
      margin-bottom: 1.5rem !important;

      .col-10 {
        width: 100%;
        margin-bottom: 0.5rem;

        p {
          font-size: 0.75rem;
          margin-bottom: 0.25rem;

          &.fw-bold {
            font-size: 1rem;
          }
        }

        small {
          font-size: 0.65rem;
        }

        .animated-underline {
          font-size: 0.875rem;
        }
      }

      .col-2 {
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }
    }

    .colors-scheme {
      .color-picker {
        padding: 0.75rem !important;
      }
    }

    .privacy-mode {
      .col-10 {
        width: 100%;
        margin-bottom: 0.5rem;

        p {
          font-size: 0.875rem;
          margin-bottom: 0.25rem;

          &.fw-bold {
            font-size: 1rem;
          }
        }
      }

      .col-2 {
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }

      .col-12.d-flex {
        flex-direction: column;
        align-items: flex-start;

        .form-group {
          width: 100%;
        }

        .wrapper-btn-white-list {
          display: flex;
          margin-top: 0px;
          width: 100%;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
  }
}
