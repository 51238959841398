$button-primary: #2c3e50;

@import '../../styles/breakpoints.scss';

.subscription {
  background: #f8f8f8;

  &--mobile {
    padding: 1rem;
  }

  &--desktop {
    padding: 3rem 1rem;
  }

  &__title {
    color: #f06a0f;
    font-weight: bold;
  }

  &__special-deals-icon {
    width: 60px;
    height: 60px;
    right: -16px;
    position: absolute;
    top: -28px;
  }

  &__package-footer {
    height: 250px;
  }

  .btn ~ .btn {
    margin-top: 0 !important;
  }

  button:disabled,
  button.disabled {
    background: $button-primary;
    color: #fff;
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

  button {
    @include respond-to('mobile') {
      font-size: 1rem;
    }

    @include respond-to('tablet') {
      font-size: 0.65rem;
    }

    @include respond-to('desktop') {
      font-size: 0.85rem;
    }
  }

  .btn-outline-midnight-blue.disabled {
    background: #fff !important;
    color: $button-primary !important;
    cursor: not-allowed;

    &:active,
    &:focus {
      background-color: #fff !important;
      color: $button-primary;
    }
  }

  .features-content {
    li {
      margin-bottom: 10px;
      list-style-image: url('../../assets/images/checkmark.svg');
    }
  }

  .special-deal {
    position: absolute;
    width: 60px;
    height: 60px;
    right: -15px;
    top: -25px;
  }

  // Button variants styles
  .btn-midnight-blue {
    background: $button-primary;
    color: #fff;
    border: 1px solid $button-primary;
    transition: all 0.3s ease;

    &:hover {
      background: darken($button-primary, 5%);
      border-color: darken($button-primary, 5%);
    }

    &:active {
      background: darken($button-primary, 10%) !important;
      border-color: darken($button-primary, 10%) !important;
      color: #fff;
    }
  }

  .btn-outline-midnight-blue {
    background: transparent;
    color: $button-primary;
    border: 1px solid $button-primary;
    transition: all 0.3s ease;

    &:hover:not(.disabled) {
      background: $button-primary;
      color: #fff;
    }

    &.disabled {
      opacity: 0.65;
      background: transparent !important;
      border-color: $button-primary !important;
      color: $button-primary !important;

      &:hover {
        background: transparent !important;
        color: $button-primary !important;
        cursor: not-allowed;
      }
    }
  }

  // Price styling
  .package-price {
    .fw-bold {
      font-size: 16px;
    }

    sup strike {
      font-size: 12px;
      margin-left: 4px;
    }
  }
}

.modal-subscription-payment-request {
  .modal-title {
    width: 100%;
  }

  .border-primary {
    border-color: #15cdca !important;
  }

  .btn-primary {
    border-color: #15cdca;
    background: #15cdca;

    &:hover {
      background: #13b4b2;
      border-color: #13b4b2;
    }

    &:active {
      background: #109896 !important;
      border-color: #109896 !important;
      outline: 0 !important;
      -webkit-box-shadow: none;
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  .form-check-input:checked {
    border-color: #15cdca;
    background-color: #15cdca;
  }

  .btn-outline-secondary:hover {
    color: white !important;
  }
}
