#digistall {
  .delivery {
    &-actions {
      .dropdown-menu.show {
        font-size: 0.75rem;
        z-index: 1010;
      }

      .dropdown-item {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      @include respond-to('mobile') {
        flex-direction: column;
        gap: 16px;

        &__input {
          width: 100%;
        }

        &__button,
        &__event-button,
        &__export,
        &__clear-button {
          width: 100% !important;
          margin: 0 !important;
        }

        .btn-group.tab-event {
          width: 100%;
          margin-right: 0px;

          .dropdown {
            width: 100%;
          }

          .dropdown-menu {
            width: 100%;

            & > div {
              width: 100%;
            }
          }
        }

        &__buttons {
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 1rem;
          width: 100%;
        }
      }

      @include respond-to('tablet') {
        flex-wrap: wrap;
        gap: 1rem;
        flex-direction: row;

        &__input {
          margin-bottom: 12px;
          width: 50%;
        }

        &__button,
        &__event-button,
        &__export,
        &__clear-button {
          flex: 1;
          min-width: 120px;
          margin: 0 !important;
        }

        &__buttons {
          flex-direction: row;
        }
      }

      @include respond-to('desktop') {
        gap: 4rem;

        &__input {
          width: 100%;
        }

        &__buttons {
          display: flex;
          gap: 1rem;
        }

        .dropdown-menu {
          width: 100%;
        }
      }
    }
  }
}
