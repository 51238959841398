.sub-items {
  list-style: none;
  padding-left: 0;
  margin: 0.5rem 0 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  li {
    font-size: 0.6rem !important;
    color: #6e84a3;
    background-color: #f8fafc;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    padding: 0.25rem 0.5rem;
    margin: 0;
    display: inline-flex;
    align-items: center;
    white-space: normal;
    word-wrap: break-word;
    font-weight: normal;
    text-wrap: wrap;

    @media screen and (max-width: 768px) {
      font-size: 0.5rem !important;
    }

    @media screen and (max-width: 480px) {
      font-size: 0.4rem !important;
    }

    &:hover {
      background-color: #f1f5f9;
    }
  }
}
