$secondary-color: #f06a0f;
$primary-color: #15cdca;
$border-radius: 12px;
$box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);

@import '../../../styles/breakpoints.scss';
@import '../../../styles/font-size-utility.scss';

.list-packages {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    box-sizing: border-box;
    margin: 10px 10px 0 0;

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-child(-n + 2) {
      margin-top: 0;
    }
  }

  &::after {
    content: '';
    flex: auto;
  }
}

.wrap-button-invoice {
  button {
    background-color: #e9ecf1;
    border-color: #e9ecf1;
    color: #565e6b;
    border-right: 1px solid #c1c4c9;
    width: 135px;
    margin-right: 2px;

    &:hover {
      background-color: #15cdca;
      border-color: transparent;
    }

    &:last-child {
      border-right: 0;
    }

    &:active,
    &.active {
      background-color: #15cdca !important;
      border-color: #15cdca !important;
    }
  }
}

.payment-history {
  padding: 2rem 0;
  max-width: 1024px;

  .page-title {
    color: #2c3345;
    margin-bottom: 2.5rem;
    font-weight: 700;
    font-size: 2rem;
    position: relative;
    text-align: center;

    &::after {
      content: '';
      position: absolute;
      bottom: -12px;
      left: 50%;
      transform: translateX(-50%);
      width: 60px;
      height: 4px;
      background: $primary-color;
      border-radius: 2px;
    }
  }

  .section-header {
    padding: 1.5rem;
    border-bottom: 1px solid #edf2f9;
    background: #fff;

    h3 {
      font-size: 1.25rem;
      font-weight: 600;
      color: #2c3345;
      margin-bottom: 1rem;
    }
  }

  .payment-card {
    background: #fff;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    border: 1px solid #edf2f9;
    overflow: hidden;

    .card-header {
      background: #fff;
      border-bottom: 1px solid #edf2f9;
      padding: 1rem 1.5rem;
    }

    .card-body {
      padding: 0;
    }
  }

  table {
    margin-bottom: 0;
    table-layout: fixed;
    width: 100%;

    thead th {
      background: #f8f9fa;
      border-bottom: 2px solid #edf2f9;
      color: #6e84a3;
      font-weight: 600;
      font-size: 0.75rem;
      white-space: nowrap;
    }

    td {
      vertical-align: middle;
      color: #2c3345;
      font-size: 0.675rem;
      border-bottom: 1px solid #edf2f9;
      white-space: normal;
      word-break: break-word;

      .text-no-action {
        color: #6e84a3;
        font-size: 0.75rem;
      }

      .btn-payment {
        padding: 0.4rem 0.8rem;
        font-size: 0.75rem;
        min-width: 90px;

        svg {
          font-size: 14px;
          margin-right: 4px;
        }
      }

      .btn-secondary {
        padding: 0.4rem 0.8rem !important;
        font-size: 0.75rem;
        min-width: 80px;
      }
    }

    th[scope='row'] {
      color: #6e84a3;
    }
  }

  .common-status {
    padding: 0.35rem 0.7rem;
    border-radius: 20px;
    font-size: 0.675rem;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 90px;

    &.badge {
      &.bg-success {
        background: #dcf5f0 !important;
        color: #00864e;
      }
      &.bg-warning {
        background: #fff4e6 !important;
        color: #f06a0f;
      }
      &.bg-info {
        background: #e8f4ff !important;
        color: #0f5abd;
      }
      &.bg-secondary {
        background: #edf2f9 !important;
        color: #6e84a3;
      }
      &.bg-danger {
        background: #ffe8e8 !important;
        color: #dc3545;
      }
    }
  }

  .btn-action {
    padding: 0.4rem 1rem;
    font-size: 0.875rem;
    border-radius: $border-radius;
    font-weight: 600;

    &.btn-payment {
      background: $primary-color;
      color: #fff;
      border: none;

      &:hover {
        background: darken($primary-color, 5%);
      }

      svg {
        margin-right: 0.5rem;
      }
    }

    &.btn-remove {
      background: #edf2f9;
      color: #6e84a3;
      border: none;

      &:hover {
        background: darken(#edf2f9, 5%);
      }
    }
  }

  .info-button {
    color: #6e84a3;
    transition: color 0.2s;

    &:hover {
      color: $primary-color;
    }
  }

  .popover {
    max-width: 300px;

    .popover-header {
      font-size: 0.875rem;
      padding: 0.5rem 1rem;
    }

    .popover-body {
      font-size: 0.75rem;
      padding: 0.5rem 1rem;

      p {
        margin-bottom: 0.25rem;

        strong {
          margin-left: 0.25rem;
        }
      }
    }
  }

  @include respond-to('mobile') {
    table {
      // Make table scrollable horizontally
      display: block;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      width: 100%;

      thead {
        th {
          font-size: 11px;
          padding: 0.5rem;
          white-space: nowrap;

          // Adjust column widths for mobile
          &:nth-child(1) {
            width: 30px;
          } // #
          &:nth-child(2) {
            width: 90px;
          } // Name
          &:nth-child(3) {
            width: 70px;
          } // Status
          &:nth-child(4) {
            width: 80px;
          } // Payment Method
          &:nth-child(5) {
            width: 60px;
          } // Info
          &:nth-child(6) {
            width: 90px;
          } // Action
        }
      }

      tbody {
        td {
          font-size: 10px;
          padding: 0.5rem;
          white-space: nowrap;

          // Style buttons in cells
          .btn-payment,
          .btn-secondary {
            padding: 0.25rem 0.5rem;
            font-size: 10px;
            min-width: 60px;

            svg {
              font-size: 12px;
            }
          }

          button {
            padding: 0.25rem 0.5rem;
            height: auto;
            font-size: 0.5rem;
            svg {
              font-size: 12px;
            }
          }
        }
      }

      // Adjust status badges
      .common-status {
        font-size: 9px;
        padding: 0.25rem 0.5rem;
        min-width: 60px;
        max-width: 70px;
      }

      // Action buttons
      .btn-action {
        padding: 0.25rem 0.5rem;
        font-size: 10px;
        min-width: 70px;
      }
    }
  }

  @include respond-to('tablet') {
    min-width: 800px;
    padding: 1rem 0;

    .page-title {
      font-size: 1.5rem;
      margin-bottom: 2rem;

      &::after {
        width: 40px;
        height: 3px;
        bottom: -8px;
      }
    }

    .section-header {
      padding: 1rem;

      h3 {
        font-size: 1rem;
        margin-bottom: 0.75rem;
      }
    }

    .payment-card {
      overflow-x: auto; // Move scroll to container
      -webkit-overflow-scrolling: touch;
      width: 100%;
    }

    table {
      display: table;
      width: 100%;
      min-width: 768px; // Force minimum width to prevent squishing
      table-layout: fixed;

      thead {
        th {
          font-size: 12px;
          padding: 0.75rem;
          white-space: nowrap;

          &:nth-child(1) {
            width: 5%;
          }
          &:nth-child(2) {
            width: 20%;
          }
          &:nth-child(3) {
            width: 15%;
          }
          &:nth-child(4) {
            width: 15%;
          }
          &:nth-child(5) {
            width: 10%;
          }
          &:nth-child(6) {
            width: 20%;
          }
        }
      }

      tbody td {
        font-size: 11px;
        padding: 0.75rem;
        white-space: nowrap;

        button {
          padding: 0.25rem 0.5rem;
          height: auto;
          font-size: 0.5rem;
          svg {
            font-size: 14px;
          }
        }
      }

      .common-status {
        font-size: 8px;
        white-space: normal;
        padding: 0.25rem 0.5rem;
        font-size: 0.5rem;
      }

      .btn-action {
        white-space: nowrap;
      }
    }

    .btn-payment,
    .btn-secondary {
      padding: 0.3rem 0.6rem !important;
      font-size: 0.7rem !important;
      min-width: auto !important;
    }

    .text-no-action {
      font-size: 0.5rem !important;
    }
  }

  @include respond-to('desktop') {
    min-width: 1024px;

    table {
      display: table; // Reset from mobile/tablet
      table-layout: fixed;
      overflow: visible; // Reset from mobile/tablet

      thead th {
        // padding: 1rem 1.5rem;
        font-size: 0.875rem;

        // Adjust widths to fill space
        &:nth-child(1) {
          width: 5%;
        }
        &:nth-child(2) {
          width: 25%;
        }
        &:nth-child(3) {
          width: 15%;
        }
        &:nth-child(4) {
          width: 20%;
        }
        &:nth-child(5) {
          width: 15%;
        }
        &:nth-child(6) {
          width: 35%;
        }
      }

      tbody td {
        padding: 0.5rem 1rem;
        white-space: normal;

        button {
          font-size: 0.75rem;
        }
      }
    }
  }
}

.select-tab-invoice {
  &__button {
    font-size: 0.75rem; // Mobile default
    width: 100px;

    @include respond-to('tablet') {
      font-size: 0.85rem;
      width: 120px;
    }

    @include respond-to('desktop') {
      font-size: 0.85rem;
      width: 120px;
    }
  }
}
