@import '../../../styles/breakpoints.scss';
@import '../../../styles/font-size-utility.scss';

.drawer-sidebar {
  position: fixed;
  top: 0;
  left: -100%;
  width: 80%; // Thay đổi width xuống 80%
  height: 100vh;
  background: white;
  z-index: 1000;
  transition: all 0.3s ease;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

  @media (min-width: 576px) {
    left: -320px;
    width: 320px;
  }

  &--open {
    left: 0;
  }

  @include respond-to('mobile') {
    .selected-value {
      font-size: 0.8rem;
    }
  }

  &__header {
    height: 34px; // Match với header height
    padding: 0 16px;
    background: #15cdca;
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    // Style for select box
    .custom-select-box {
      .select__control {
        border: 1px solid rgba(255, 255, 255, 0.2);
        background: rgba(255, 255, 255, 0.1);

        .select__single-value {
          color: #fff;
        }

        .select__placeholder {
          color: rgba(255, 255, 255, 0.8);
        }

        .select__input {
          color: #fff;
        }

        &:hover {
          border-color: rgba(255, 255, 255, 0.3);
        }
      }

      .select__menu {
        margin-top: 4px;
        background: white;
        border: none;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);

        .select__option {
          padding: 12px 16px;
          color: #333;
          cursor: pointer;

          &--is-selected {
            background: rgba(21, 205, 202, 0.1);
            color: #15cdca;
          }

          &:hover {
            background: rgba(21, 205, 202, 0.05);
          }
        }
      }
    }
  }

  &__close {
    width: 32px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;
    padding: 0;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    &:active {
      transform: scale(0.95);
    }
  }

  &__content {
    height: calc(100% - 56px); // Adjust height based on new header height
    overflow-y: auto;
    padding: 16px 0;

    // Custom scrollbar
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }

    // Hide style menu elements
    .style-menu,
    .prev-style-menu {
      display: none;
    }

    // Menu items styling
    .list-menu {
      .menu-item {
        // padding: 0 20px;
        margin: 4px 0;
        font-weight: 500;

        a {
          padding: 0px 16px;
          display: flex;
          align-items: center;
          // font-size: 0.85rem;
          color: #1e1e1e;
          border-radius: 8px;
          transition: all 0.2s ease;
          width: 100%;
          height: 34px;
          @extend %text-responsive;

          @include respond-to('mobile') {
            font-size: 0.8rem;
          }

          .icons {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: currentColor;
            font-size: 1rem;

            @include respond-to('mobile') {
              width: 18px;
              height: 18px;
              margin-right: 5px;
            }
          }

          &:hover,
          &.text-orange {
            background: rgba(253, 126, 20, 0.08);
            color: #fd7e14;
          }
        }

        .tooltip {
          display: none;
        }
      }
    }

    @media screen and (max-width: 480px) {
      .wrapper-select-shop {
        margin-top: 0px !important;
      }
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
    backdrop-filter: blur(2px);

    &--visible {
      opacity: 1;
      visibility: visible;
    }
  }
}

// Thêm style cho mobile drawer overlay
.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  backdrop-filter: blur(2px);

  &--visible {
    opacity: 1;
    visibility: visible;
  }
}

// Hide on desktop
@media (min-width: 1024px) {
  .drawer-sidebar,
  .drawer-overlay {
    display: none;
  }
}
