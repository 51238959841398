.custom-datepicker {
  width: 100%;
  min-width: 100px;
  font-size: 0.75rem;

  .react-datepicker__input-container {
    width: 100%;

    input {
      width: 100%;
      color: #59687c;
      font-size: 0.75rem;
    }
  }
}

// Additional datepicker popup styling if needed
.react-datepicker {
  font-size: 0.75rem;
  font-family: inherit;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);

  .react-datepicker__header {
    background-color: #15cdca;
    border-bottom: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-top: 12px;

    .react-datepicker__current-month {
      color: white;
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 8px;
    }

    .react-datepicker__day-names {
      margin-top: 4px;
      background-color: rgba(255, 255, 255, 0.1);
      padding: 8px 0;

      .react-datepicker__day-name {
        color: white;
        font-weight: 500;
        width: 2rem;
        margin: 0 0.2rem;
      }
    }
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__navigation {
    top: 18px;

    .react-datepicker__navigation-icon::before {
      border-color: white;
      border-width: 2px 2px 0 0;
      width: 8px;
      height: 8px;
    }

    &:hover .react-datepicker__navigation-icon::before {
      border-color: rgba(255, 255, 255, 0.7);
    }
  }

  .react-datepicker__day {
    margin: 0.2rem;
    width: 2rem;
    line-height: 2rem;
    border-radius: 4px;
    font-weight: 500;

    &:hover {
      background-color: rgba(#15cdca, 0.1);
    }

    &--selected {
      background-color: #15cdca;
      color: white !important;
      font-weight: 600;

      &:hover {
        background-color: darken(#15cdca, 5%);
      }
    }

    &--disabled {
      color: #ccc !important;
      background-color: #f0f0f0 !important;
      cursor: not-allowed !important;

      &:hover {
        background-color: #f0f0f0 !important;
      }
    }

    &--today {
      font-weight: 600;
      color: #15cdca;

      &::after {
        content: '';
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translateX(-50%);
        width: 4px;
        height: 4px;
        background-color: #15cdca;
        border-radius: 50%;
      }
    }
  }

  .react-datepicker__month {
    margin: 0.8rem;
  }
}
