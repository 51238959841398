// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `$input-focus-border-color` variable.
//
// We highly encourage you to not gcustomize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.
@mixin form-control-focus() {
  &:focus {
    color: $input-focus-color;
    // background-color: $input-focus-bg;
    // border-color: $input-focus-border-color;
    outline: 0;
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      // box-shadow: $input-box-shadow, $input-focus-box-shadow;
    } @else {
      // box-shadow: $input-focus-box-shadow;
    }
  }
}

@mixin form-validation-state($state, $color) {
  .#{$state}-feedback {
    display: none;
    width: 100%;
    margin-top: $form-feedback-margin-top;
    font-size: $form-feedback-font-size;
    color: $color;
  }

  .#{$state}-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%; // Contain to parent when possible
    padding: 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1;
    color: $white;
    background-color: rgba($color, 0.8);
    border-radius: 0.2rem;
  }

  .form-control,
  .custom-select {
    .was-validated &:#{$state},
    &.is-#{$state} {
      border-color: $color;

      &:focus {
        border-color: $color;
        box-shadow: 0 0 0 $input-focus-width rgba($color, 0.25);
      }

      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: block;
      }
    }
  }

  .form-check-input {
    .was-validated &:#{$state},
    &.is-#{$state} {
      ~ .form-check-label {
        color: $color;
      }

      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: block;
      }
    }
  }

  .custom-control-input {
    .was-validated &:#{$state},
    &.is-#{$state} {
      ~ .custom-control-label {
        color: $color;

        &::before {
          background-color: lighten($color, 25%);
        }
      }

      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: block;
      }

      &:checked {
        ~ .custom-control-label::before {
          @include gradient-bg(lighten($color, 10%));
        }
      }

      &:focus {
        ~ .custom-control-label::before {
          box-shadow: 0 0 0 1px $body-bg, 0 0 0 $input-focus-width rgba($color, 0.25);
        }
      }
    }
  }

  // custom file
  .file-ui-input {
    .was-validated &:#{$state},
    &.is-#{$state} {
      ~ .file-ui-label {
        border-color: $color;

        &::before {
          border-color: inherit;
        }
      }

      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: block;
      }

      &:focus {
        ~ .file-ui-label {
          box-shadow: 0 0 0 $input-focus-width rgba($color, 0.25);
        }
      }
    }
  }
}

.custom-checkbox {
  input[type='checkbox'] + label {
    display: block;
    cursor: pointer;
    position: relative;
    padding-top: 5px;
    padding-left: 36px;
    font-size: rem(12);
    color: #7c797a;
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + label:before {
    content: '';
    border: 0.15em solid #7c797a;
    border-radius: 4px;
    display: inline-block;
    width: 25px;
    height: 25px;
    padding-left: 0.2em;
    padding-bottom: 0.3em;
    margin-right: 0.2em;
    vertical-align: bottom;
    color: transparent;
    transition: 0.2s;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -12px;
  }

  input[type='checkbox'] + label:active:before {
    transform: scale(0);
  }

  input[type='checkbox']:checked + label:before {
    background-color: white;
    border-color: #7c797a;
    color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cg id='Group_77' data-name='Group 77' transform='translate(0 0)'%3E%3Crect id='Rectangle_460' data-name='Rectangle 460' width='16' height='16' transform='translate(0 0)' fill='none'/%3E%3Cpath id='Checkbox' d='M6.345,11.034,0,4.69,1.287,3.4,6.345,8.368,14.713,0,16,1.287Z' transform='translate(0 2)' fill='%23d85539'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
  }

  input[type='checkbox']:disabled + label:before {
    transform: scale(1);
    border-color: #aaa;
  }

  input[type='checkbox']:checked:disabled + label:before {
    transform: scale(1);
    background-color: #bfb;
    border-color: #bfb;
  }
}
.custom-radio {
  margin-bottom: 10px;
  input[type='radio'] + label {
    display: block;
    cursor: pointer;
    position: relative;
    padding-left: 36px;
    font-size: rem(12);
    color: #7c797a;
  }

  input[type='radio'] {
    display: none;
  }

  input[type='radio'] + label:before {
    content: '';
    border: 0.1em solid #7c797a;
    border-radius: 4px;
    display: inline-block;
    width: 25px;
    height: 25px;
    padding-left: 0.2em;
    padding-bottom: 0.3em;
    margin-right: 0.2em;
    vertical-align: bottom;
    color: transparent;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -12px;
    border-radius: 50%;
  }

  input[type='radio'] + label:active:before {
    transform: scale(0);
  }

  input[type='radio']:checked + label:before {
    background-color: white;
    border-color: #7c797a;
    color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Ccircle id='Ellipse_1' data-name='Ellipse 1' cx='10' cy='10' r='10' fill='%2315cdca'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-size: 13px;
    background-position: 5px 4px;
    background-repeat: no-repeat;
    @include media-breakpoint-down(md) {
      background-position: center;
    }
  }

  input[type='radio']:disabled + label:before {
    transform: scale(1);
    border-color: #aaa;
  }

  input[type='radio']:checked:disabled + label:before {
    transform: scale(1);
    background-color: #bfb;
    border-color: #bfb;
  }
}
