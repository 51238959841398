@import '../../../styles/breakpoints.scss';

.setting-page {
  // Payment
  .payment {
    // height: 450px;

    @media (max-width: 767px) {
      padding: 1rem;
    }

    @media (min-width: 768px) and (max-width: 1023px) {
      padding: 2rem;
    }

    @media (min-width: 1024px) {
      padding: 3rem;
    }

    &__section {
      @media (max-width: 767px) {
        flex-direction: column;

        align-items: flex-start;
      }

      @media (min-width: 768px) and (max-width: 1023px) {
        flex-direction: row;
        align-items: center;
      }

      @media (min-width: 1024px) {
        flex-direction: row;
        align-items: center;
      }

      .info-icon {
        cursor: pointer;
        color: #6c757d;
        font-size: 16px;

        &:hover {
          color: #495057;
        }
      }

      &-content {
        @media (max-width: 767px) {
          width: 100%;
          margin-bottom: 1rem;
        }

        @media (min-width: 768px) and (max-width: 1023px) {
          width: 80%;
        }

        @media (min-width: 1024px) {
          width: 80%;
        }
      }

      &-input {
        @media (max-width: 767px) {
          width: 100%;
          margin-bottom: 1rem;
        }

        @media (min-width: 768px) and (max-width: 1023px) {
          width: 20%;
        }

        @media (min-width: 1024px) {
          width: 20%;
        }
      }
    }

    &__transaction-fees {
      position: relative;

      .position-relative {
        position: relative;
        width: 400px;

        @include respond-to('mobile') {
          width: 100%;
        }
      }

      .custom-input {
        padding-right: 100px; // Make space for the button
      }

      .save-button {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
  }
}
