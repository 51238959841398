$color-my-purchase: #2ecc71;
$color-admin-console: #3498db;
$bg-white: #fff;
$text-primary: #2c3e50;
$shadow-medium: rgba(0, 0, 0, 0.1);

@import '../../styles/breakpoints.scss';
@import '../../styles/font-size-utility.scss';

.float-buttons-wrapper {
  position: fixed;
  bottom: 40px;
  right: 25px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 1000;

  .float-button {
    transition: transform 0.3s ease;

    &.btn-my-purchase {
      // Thêm transition cho transform
      transform: translateY(0);

      // Khi wrapper có class has-admin-console
      .float-buttons-wrapper.has-admin-console & {
        transform: translateY(-56px); // Di chuyển lên trên bằng khoảng cách button + gap
      }
    }
  }
}

.float-button {
  position: relative; // Change from fixed to relative
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 1000;
  border: none;
  transition: all 0.3s ease;
  gap: 8px;
  font-weight: 500;

  &:active {
    transform: translateY(0);
  }

  span {
    font-size: 14px;
  }

  svg {
    transition: transform 0.3s ease;
  }

  &:hover svg {
    transform: scale(1.1);
  }

  &.btn-admin-console {
    background-color: $color-admin-console;
    box-shadow: 0 4px 12px rgba(52, 152, 219, 0.3);

    &:hover {
      box-shadow: 0 6px 16px rgba(52, 152, 219, 0.4);
    }

    &:active {
      box-shadow: 0 2px 8px rgba(52, 152, 219, 0.4);
    }
  }

  &.btn-my-purchase {
    background-color: $color-my-purchase;
    box-shadow: 0 4px 12px rgba(46, 204, 113, 0.3);

    &:hover {
      box-shadow: 0 6px 16px rgba(46, 204, 113, 0.4);
    }

    &:active {
      box-shadow: 0 2px 8px rgba(46, 204, 113, 0.4);
    }
  }

  &.skeleton-wrapper {
    background: transparent;
    box-shadow: none;

    .react-loading-skeleton {
      line-height: 1;
    }
  }
}

