$primary: #15cdca;
$dark: #64748b;

@import '../../styles/breakpoints.scss';
@import '../../styles/font-size-utility.scss';

.custom-select {
  position: relative;
  width: 100%;
  min-width: 100px;
  user-select: none;
  // font-size: 0.75rem;

  @extend %text-responsive;

  &.disabled {
    opacity: 0.6;
    pointer-events: none;

    .select-header {
      background-color: #f1f5f9;
      cursor: not-allowed;
      border-color: #e2e8f0;

      &:hover {
        border-color: #e2e8f0;
      }
    }

    .selected-value {
      color: #64748b;
    }

    .arrow {
      color: #94a3b8;
    }
  }

  &.has-error {
    .select-header {
      border-color: #dc3545;
      background-color: #fff5f5;

      &:hover {
        border-color: #dc3545;
      }

      &.open {
        border-color: #dc3545;
        box-shadow: 0 0 0 1px #dc3545;
      }
    }
  }

  .select-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px;
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    height: 34px;

    &:hover {
      border-color: $primary;
    }

    &.open {
      border-color: $primary;
      box-shadow: 0 0 0 1px $primary;
    }
  }

  .selected-value {
    color: #59687c;
    @include truncate-text(1);

    &:empty::before {
      content: attr(placeholder);
      color: #94a3b8;
    }
  }

  .arrow {
    margin-left: 8px;
    display: flex;
    align-items: center;
    transition: transform 0.2s ease;

    &:not(.no-rotate) {
      &.open {
        transform: rotate(180deg);
      }
    }

    &.no-rotate {
      transform: none;
    }

    svg {
      color: #59687c;
    }
  }

  .options-container {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow-y: auto;
    z-index: 1012; // Increase z-index to ensure it appears above the table header
    animation: slideDown 0.2s ease;

    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 10px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &.reversed {
      top: auto;
      bottom: calc(100% + 4px);
    }
  }

  .option {
    padding: 5px 12px;
    cursor: pointer;
    transition: all 0.15s ease;
    color: $dark;
    @include truncate-text(1);

    &:hover {
      background-color: rgba($primary, 0.1);
    }

    &.selected {
      background-color: #e8f0fe;
      color: $primary;
    }
  }

  &.time-select {
    .select-header {
      height: 45px;
      background: #fff;
      border: 1px solid #ced4da;
      box-shadow: rgba(0, 0, 0, 0.1) 0 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
      font-size: 16px;

      &:hover {
        border-color: #15cdca;
      }

      &.open {
        border-color: #15cdca;
        box-shadow: 0 0 0 0.2rem rgba(21, 205, 202, 0.25);
      }

      .selected-value {
        color: #495057;
        font-size: 16px;

        &:empty::before {
          color: #6c757d;
        }
      }

      .arrow {
        svg {
          width: 20px;
          height: 20px;
          color: #15cdca;
        }
      }
    }

    .options-container {
      margin-top: 2px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      max-height: 250px;

      .option {
        padding: 12px 16px;
        font-size: 16px;
        color: #495057;
        border-bottom: 1px solid #f8f9fa;

        &:hover {
          background-color: #f8f9fa;
          color: #15cdca;
        }

        &.selected {
          background-color: rgba(21, 205, 202, 0.1);
          color: #15cdca;
          font-weight: 500;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
