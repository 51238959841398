.checkout {
  .ribbon-2 {
    --f: 10px; /* control the folded part*/
    --r: 15px; /* control the ribbon shape */
    --t: 15px; /* the top offset */

    @extend %text-responsive;

    @media screen and (max-width: 480px) {
      --f: 5px;
      --r: 10px;
      --t: 10px;
    }

    overflow: hidden;
    position: absolute;
    inset: var(--t) calc(-1 * var(--f)) auto auto;
    padding: 0 10px var(--f) calc(10px + var(--r));
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - var(--f)),
      calc(100% - var(--f)) 100%,
      calc(100% - var(--f)) calc(100% - var(--f)),
      0 calc(100% - var(--f)),
      var(--r) calc(50% - var(--f) / 2)
    );
    background: #cc3333;
    box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
    color: white;
  }

  @-webkit-keyframes flow {
    0% {
      left: -20px;
      opacity: 0;
    }
    50% {
      left: 100px;
      opacity: 0.3;
    }
    100% {
      left: 180px;
      opacity: 0;
    }
  }

  @keyframes flow {
    0% {
      left: -20px;
      opacity: 0;
    }
    50% {
      left: 100px;
      opacity: 0.3;
    }
    100% {
      left: 180px;
      opacity: 0;
    }
  }

  .glow {
    background: rgb(255, 255, 255);
    width: 40px;
    height: 100%;
    z-index: 999;
    position: absolute;
    -webkit-animation: flow 1.5s linear infinite;
    -moz-animation: flow 1.5s linear infinite;
    -webkit-transform: skew(20deg);
    -moz-transform: skew(20deg);
    -o-transform: skew(20deg);
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 1%,
      rgba(255, 255, 255, 1) 100%
    ); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0%, rgba(255, 255, 255, 0)),
      color-stop(1%, rgba(255, 255, 255, 0)),
      color-stop(100%, rgba(255, 255, 255, 1))
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 1%,
      rgba(255, 255, 255, 1) 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 1%,
      rgba(255, 255, 255, 1) 100%
    ); /* Opera 11.10+ */
    background: -ms-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 1%,
      rgba(255, 255, 255, 1) 100%
    ); /* IE10+ */
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 1%,
      rgba(255, 255, 255, 1) 100%
    ); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
    border-left: 1px solid #fff;
  }

  .form-gift-recipient {
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    background-color: white;
    position: relative;
    border: 1px solid lightseagreen;

    @extend %padding-responsive;

    &__ribbon {
      @extend .ribbon-2;
    }

    &__glow {
      @extend .glow;
    }

    &__title {
      font-weight: 600;
      text-transform: uppercase;
      @extend %subtitle-responsive;
    }

    &__details {
      margin-top: 1rem;
    }
  }
}
