@import '../../styles/breakpoints.scss';
@import '../../styles/font-size-utility.scss';

$orange: #ff6903;
$warning: #f1c40f; // Add new warning color
$aqua: #0badad;
$slate: #64748b;
$gray: gray;
$light-gray: #e2e8f0;
$disabled-color: #e9ecef;
$danger: #f44336;
$dark-slate-gray: #385a64;
$whats-app: #25d366;
$facebook: #1877f2;

$colors: (
  'orange': $orange,
  'warning': $warning,
  // Add warning color
  'aqua': $aqua,
  'slate': $slate,
  'gray': $gray,
  'light-gray': $light-gray,
  'danger': $danger,
  'dark-slate-gray': $dark-slate-gray,
  'whats-app': $whats-app,
  'facebook': $facebook,
);

.custom-button {
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-weight: 500;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  position: relative;
  transition: all 0.15s ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
  height: 34px;
  margin-top: 0.75rem;
  outline: none !important;

  // @include respond-to('mobile') {
  //   font-size: 0.75rem;
  // }

  // @include respond-to('tablet') {
  //   font-size: 0.75rem;
  // }

  // @include respond-to('desktop') {
  //   font-size: 0.75rem;
  // }
  @extend %text-responsive;

  // Button disabled state styles
  &:disabled,
  &.disabled {
    cursor: not-allowed;
    color: rgba($gray, 0.75) !important;
    background-color: $disabled-color !important;
    border: 1px solid $disabled-color !important;

    svg {
      color: rgba($gray, 0.75) !important;
    }
  }

  // Variants
  &.outlined {
    background-color: transparent;
    border: 1px solid;
  }

  &.contained {
    border: 1px solid transparent;
    color: white;
  }

  &.text {
    border: none;
    background-color: transparent;
    padding: 0.4rem 0.5rem;
  }

  &.border-dash {
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      color: #fff;
    }

    &:disabled {
      border-color: $disabled-color;
      color: $disabled-color;
      cursor: not-allowed;
    }
  }

  &-icon {
    font-size: 1rem;
  }

  &.button-delete {
    color: $slate !important;
    &:hover {
      background-color: rgba($danger, 0.1) !important;
      color: $danger !important;
    }

    &:active {
      background-color: darken($danger, 5%) !important;
      border-color: darken($danger, 5%) !important;
      color: white !important;
    }
  }

  &.button-landing-page {
    background-color: $dark-slate-gray;
  }

  // Colors
  @each $name, $color in $colors {
    &.#{$name} {
      // Outlined variant
      &.outlined {
        border-color: $color;
        color: $color;

        &:hover {
          background-color: rgba($color, 0.1);
          color: $color;
        }

        &:active {
          @if $name == 'gray' {
            background-color: darken($orange, 5%);
            border-color: darken($orange, 5%);
            color: white;
          } @else if $name == 'slate' {
            background-color: rgba($color, 0.2);
          } @else {
            background-color: darken($color, 5%);
            color: #fff;
          }
        }
      }

      // Contained variant
      &.contained {
        background-color: $color;

        &:hover {
          @if $name == 'slate' {
            background-color: rgba($color, 0.1);
          } @else {
            background-color: darken($color, 5%);
            color: #fff;
          }
        }

        &:active {
          background-color: darken($color, 15%);
        }
      }

      // Text variant
      &.text {
        color: $color;

        &:hover {
          background-color: transparent; // Override to remove background on hover
          color: darken($color, 15%); // Darken text color instead
        }

        &:active {
          background-color: transparent; // Keep transparent on active
          color: darken($color, 25%);
        }
      }

      // Border dash variant
      &.border-dash {
        border: 1px dashed $color;
        background-color: transparent;
        color: $color;

        &:hover {
          background-color: rgba($color, 0.1);
          color: $color;
        }

        &:disabled {
          border-color: $disabled-color;
          color: $disabled-color;
          cursor: not-allowed;
        }
      }
    }
  }

  // Active
  @each $name, $color in $colors {
    &.active.#{$name} {
      color: #fff;
      transition: color 0.15s ease-in-out;

      @if $name == 'gray' {
        background-color: $orange;
        border-color: $orange;
      } @else {
        background-color: $color;
        border-color: $color;
      }

      &:hover {
        color: #fff;

        @if $name == 'gray' {
          background-color: darken($orange, 5%);
          border-color: darken($orange, 5%);
        } @else {
          background-color: darken($color, 5%);
          border-color: darken($color, 5%);
        }
      }

      &:active {
        @if $name == 'gray' {
          background-color: darken($orange, 10%);
          border-color: darken($orange, 10%);
        } @else {
          background-color: darken($color, 10%);
          border-color: darken($color, 10%);
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    svg {
      margin-right: 3px !important;
    }
  }
}
