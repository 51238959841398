.modal-product-volume {
  h5 {
    font-size: 20px;
  }

  button.btn-submit {
    background: $primary-color;
    color: white;
    border: 1px solid $primary-color;
    font-weight: normal !important;
  }
}

.modal-create-product {
  width: 600px;
  @include respond-to('desktop') {
    width: 600px !important;
  }
}

// Consolidate common modal styles
.modal-create-product,
.modal-edit-product,
.modal-add-category,
.modal-setting,
.modal-variation,
.modal-push-wix {
  // width: 600px;
  max-width: 90%;
  margin: auto;
  font-size: 16px;

  @include respond-to('desktop') {
    width: 500px;
  }

  @include respond-to('tablet') {
    width: 80%;
    padding: 1rem;
  }

  @include respond-to('mobile') {
    width: 100%;
    padding: 0.5rem;
  }

  .modal-content {
    // width: 600px;
    border-radius: 8px;
    padding: 0;

    @include respond-to('tablet') {
      padding: 15px;
    }

    @include respond-to('mobile') {
      width: 100%;
    }
  }

  .text-primary {
    color: $primary-color !important;
  }

  .btn-submit {
    background-color: $primary-color;
    color: white;
    border: 1px solid $primary-color;
    text-transform: uppercase;
    font-weight: normal !important;

    &:hover {
      background-color: darken($primary-color, 10%) !important;
      border: 1px solid darken($primary-color, 10%) !important;
    }

    &:active,
    &:focus {
      box-shadow: none;
      background-color: $primary-color;
      color: white;
      border: 1px solid $primary-color;
    }
  }

  .form-control-sm {
    outline: none;
  }

  .btn-retry {
    &:hover {
      color: $primary-color;
      transition: all 0.3s ease-in-out;
    }
  }

  .modal-header {
    padding: 15px 20px;
    border-bottom: 1px solid #dee2e6;

    @include respond-to('tablet') {
      padding: 12px 15px;
    }

    @include respond-to('mobile') {
      padding: 8px 10px;
    }

    .modal-title {
      width: 100%;
      @extend %title-responsive;
    }

    .float-end {
      svg {
        width: 24px;
        height: 24px;

        @include respond-to('tablet') {
          width: 22px;
          height: 22px;
        }

        @include respond-to('mobile') {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .modal-body {
    padding: 20px;

    @include respond-to('tablet') {
      padding: 15px;
    }

    @include respond-to('mobile') {
      padding: 10px !important;
    }

    .form-create-product {
      .form-control,
      .custom-button {
        width: 100%;
      }
      input::placeholder,
      select,
      textarea {
        font-size: 14px;
      }
    }
  }
}

.modal-setting {
  transform: translateY(50%) !important;
}

.modal-create-product {
  &__title {
    font-weight: 600;
  }

  .form-upload-image {
    width: 100% !important;
    border-radius: 12px !important;
    border: 2px dashed rgba(11, 173, 173, 0.4) !important;
    background-color: rgba(11, 173, 173, 0.02);
    box-shadow: none !important;
    height: 200px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    flex-direction: column;

    &:hover {
      background: #f1f5f9;
    }

    &::before {
      content: 'Drop files here or click to upload';
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.5rem;
      color: #666;
      font-weight: 500;
      opacity: 0.75;
    }

    .text-upload {
      font-size: 0.75rem;
      color: #0badad;
      font-weight: 600;
      letter-spacing: -0.2px;
      margin-bottom: 0.5rem;
    }

    .upload-image-txt img {
      width: 100px;
      height: 100px;
      object-fit: contain;
    }

    .c-upload input {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
    }
  }

  .product-info {
    background: #fafafa;
    border-radius: 8px;
    padding: 1rem;
    position: relative;
    margin-top: 1rem;

    .badge-product-info {
      padding: 0.25rem 0.5rem;
      font-weight: 500;
      font-size: 0.75rem;
      letter-spacing: 0.025em;
      top: -10px;
    }

    .form-group {
      margin-bottom: 1.5rem;

      label {
        display: block;
        font-size: 0.75rem;
        font-weight: 500;
        color: #334155;
      }
    }
  }

  .btn-submit {
    width: 100%;
    padding: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
}

.modal-edit-product {
  input[type='radio'] {
    &:checked,
    &:focus {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }

  transform: translate(0, 25%) !important;
}

.form-create-product {
  input::placeholder,
  select,
  textarea {
    font-size: 14px;
  }
}

.modal-upgrade-subscription {
  .modal-content {
    padding: 0;
  }

  .btn-submit {
    background-color: $primary-color;
    border: 1px solid $primary-color;
    color: #fff;

    &:hover {
      background-color: darken($primary-color, 10%);
      border-color: darken($primary-color, 10%);
    }
  }

  .btn-cancel:hover {
    background: #efefef !important;
  }
}
