%shared-fixed-table {
  width: 100%;
  -webkit-overflow-scrolling: touch;

  table {
    margin: 0;
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    user-select: none;

    thead {
      height: 55px;
      position: relative;
      z-index: 1001;

      th {
        background: #f8f9fa;
        border-bottom: 2px solid #edf2f9;
        color: #6e84a3;
        font-weight: 600;
        font-size: 0.8rem;
        padding: 1rem;
        text-align: left;
        top: 0;
        z-index: 10;
      }
    }

    th,
    td {
      vertical-align: middle;
      padding: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.col-image {
        width: 35%;
        min-width: 120px;

        @include respond-to('mobile') {
          width: 41%;
        }

        .wrap-arrow {
          .arrow-up,
          .arrow-down {
            svg {
              width: 24px;
              height: 24px;

              @include respond-to('mobile') {
                width: 18px;
                height: 18px;
              }

              @include respond-to('tablet') {
                width: 20px;
                height: 20px;
              }
            }
          }
        }

        .upload-image {
          width: 100px;
          height: 100px;

          @include respond-to('mobile') {
            width: 100%;
          }

          @include respond-to('tablet') {
            width: 100%;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px;
          }
        }
      }

      &.col-name {
        width: 25%;
        min-width: 150px;
        padding: 0.75rem;
        white-space: normal;
      }

      &.col-price,
      &.col-inventory,
      &.col-purchase {
        width: 20%;
        text-align: right;
      }

      &.col-description {
        width: 40%;
        min-width: 200px;
        padding: 0.75rem;
        white-space: normal; // Thêm dòng này để cho phép text wrap
        cursor: pointer;
      }

      &.col-active {
        width: 15%;
        .form-group {
          width: fit-content;
        }

        @include respond-to('mobile') {
          width: 20%;
        }
      }

      &.col-setting {
        width: 30%;
        min-width: 120px;
        text-align: center;

        @include respond-to('mobile') {
          width: 37%;
        }
      }

      .btn-toggle-sub-item {
        background: transparent;
        border: 1px solid lightgray;
        color: black;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background 0.3s ease-in-out;

        &:hover {
          background: lightgray;
        }
      }

      // Styles for product-controls__toggle-section
      .product-controls__toggle-section {
        display: flex;
        align-items: center;

        &--right {
          justify-content: flex-end;
        }

        &--left {
          justify-content: flex-start;
        }

        @include respond-to('desktop') {
          flex-direction: row;

          .product-controls__toggle-label {
            margin-right: 0.5rem;
            font-size: 0.75rem;
          }

          .product-controls__toggle-btn {
            font-size: 1.5rem;
          }
        }

        @include respond-to('tablet') {
          flex-direction: column;
          align-items: flex-start;

          .product-controls__toggle-label {
            margin-bottom: 0.5rem;
            font-size: 0.9rem;
          }

          .product-controls__toggle-btn {
            font-size: 1.2rem;
          }
        }

        @include respond-to('mobile') {
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;

          .product-controls__toggle-label {
            margin-bottom: 0px;
            font-size: 0.65rem;
          }

          .product-controls__toggle-btn {
            font-size: 1rem;
          }
        }
      }
    }

    td {
      color: #2c3345;
      font-size: 0.8rem;
      border-bottom: 1px solid #edf2f9;
      background: inherit !important;
    }

    .text-content {
      max-width: 100%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 5px;
      padding: 5px;
      font-size: 0.8rem;
      cursor: pointer;

      &:hover {
        background-color: #dddddd;
        transition: background-color 0.3s ease-in-out;
      }

      &.multiline {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    tfoot {
      background: #fff;
      position: sticky;
      bottom: 0;

      td {
        padding: 1rem;
        border-top: 2px solid #edf2f9;
      }
    }

    tr:hover {
      background-color: rgba(243, 243, 243, 0.5);

      // Đảm bảo flash vẫn hoạt động khi hover
      &.flash {
        animation: flashBackground 0.6s ease-out !important;
      }
    }

    tfoot tr:hover {
      background-color: initial !important;
    }

    .btn-settings {
      color: #7fc7c6;
      transition: color 0.2s ease-in-out;
      font-size: 0.8rem;
      margin-top: 5px;
      &:hover {
        color: darken(#7fc7c6, 10%);
      }
    }

    @include respond-to('mobile') {
      min-width: 900px;

      th,
      td {
        font-size: $font-size-text-mobile;
        padding: 0.75rem;
      }

      .col-description {
        -webkit-line-clamp: 1;
      }
    }

    @include respond-to('tablet') {
      min-width: 1000px;

      th,
      td {
        font-size: $font-size-text-tablet;
        padding: 0.75rem;
      }
    }
  }

  // Thêm style chung cho cả description và name
  .product-description__text,
  .product-name__text {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal !important;
    text-overflow: ellipsis;
    width: 100%;
    cursor: pointer;
    word-break: break-all;
  }
}

#digistall {
  .manage-products {
    .table-header {
      color: #099292;
    }

    .list-product {
      width: 100%;
      overflow-x: auto;
      padding-top: 0px !important;

      .my_fixed_table {
        @extend %shared-fixed-table;
      }

      @include respond-to('mobile') {
        padding: $padding-mobile;

        .title {
          font-size: $font-size-title-mobile;
        }

        .my_fixed_table {
          margin: 0 -$padding-mobile;

          table {
            min-width: 900px;

            th {
              font-size: $font-size-subtitle-mobile;
              line-height: 1.2;
            }

            td {
              font-size: $font-size-text-mobile;
              padding: 1rem 1.5rem;
            }
          }
        }

        button.btn-outline-secondary,
        input,
        .form-control-sm {
          font-size: $font-size-text-mobile;
        }

        .form-control-sm {
          height: 34px;
        }
      }

      @include respond-to('tablet') {
        .title {
          font-size: $font-size-title-tablet;
        }

        .my_fixed_table {
          table {
            min-width: 1000px;

            th,
            td {
              padding: 10px;
            }
          }
        }
      }

      @media screen and (max-width: 600px) {
        .my_fixed_table {
          font-size: 13px;
        }
      }
    }
  }
}

.product-controls {
  position: relative;
  transition: all 0.3s ease;

  &__cell {
    background-color: #f8f9fa;
    border-bottom: 1px solid #edf2f9;
    padding: 0;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 1rem;

    @include respond-to('mobile') {
      flex-direction: row;
      align-items: stretch;
    }
  }

  &__save-btn {
    min-width: 120px;
    width: auto;
    margin-top: 0px;

    @include respond-to('mobile') {
      font-size: 0.75rem;
      padding: 0.25rem 0.5rem;
      height: 36px !important;
      margin: 0px;
    }
  }

  &__toggle-section {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: all 0.3s ease;

    &--right {
      margin-left: auto;
    }

    @include respond-to('mobile') {
      justify-content: space-between;
    }
  }

  &__toggle-label {
    color: #6e84a3;
    font-size: 0.875rem;
    font-weight: 500;

    @include respond-to('mobile') {
      font-size: 0.813rem;
    }
  }

  &__toggle-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border: 1px solid #e3e8ef;
    border-radius: 6px;
    padding: 0.25rem;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background-color: #f1f4f8;
      border-color: #d1d9e6;
    }

    svg {
      color: #099292;
      transition: transform 0.2s ease;

      @include respond-to('mobile') {
        width: 24px;
        height: 24px;
      }
    }

    &:hover svg {
      transform: translateY(-1px);
    }
  }
}

// Thêm animation flash cho toàn bộ row
@keyframes flashBackground {
  0%,
  100% {
    background-color: transparent !important;
  }
  50% {
    background-color: rgba(9, 146, 146, 0.2) !important;
  }
}

// Thay đổi style cho flash
tr.flash,
tr.flash td {
  animation: flashBackground 0.6s ease-out !important;
  animation-iteration-count: 2 !important;
}
