@import './breakpoints.scss';
@import './font-size-utility.scss';

.arrow-drap {
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  // position: absolute;
  // transform: translateY(-50%);
  // right: 20px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.8);
  }
  &img {
    max-width: inherit;
    width: 40px;
    max-height: 40px;
  }
  &-up {
    top: 40%;
    background-image: url('../assets/images/up-arrow.png');
  }
  &-down {
    top: 60%;
    background-image: url('../assets/images/down-arrow.png');
  }
}
.cursor-pointer {
  cursor: pointer !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.modal-content,
.rdrMonth {
  padding: 0;
}

.dropdown-item {
  padding: 0.5rem 1rem;
  @extend %text-responsive;
}

.modal-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  @extend %title-responsive;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 100%;
  cursor: default; // Add this to indicate text is hoverable
}

/* Custom tooltip styles */
.custom-tooltip {
  background-color: #ffffff !important; // Standard white background
  padding: 8px 12px !important;
  border-radius: 4px !important;
  max-width: 200px !important;

  .tooltip-inner {
    background-color: #ffffff !important;
    border-radius: 4px !important;
    color: #333333 !important; // Standard dark text
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important; // Subtle shadow
    font-size: 12px;
  }

  &.my-purchase .tooltip-inner {
    background-color: #2ecc71 !important; // Material Blue
    color: #ffffff !important;
  }

  &.admin-console .tooltip-inner {
    background-color: #3498db !important;
    color: #ffffff !important;
  }

  &.event .tooltip-inner {
    background-color: #f44336 !important; // Material Red
    color: #ffffff !important;
  }

  /* Arrow colors matching container */
  .bs-tooltip-top .arrow::before,
  .bs-tooltip-auto[x-placement^='top'] .arrow::before {
    border-top-color: white !important;
  }

  .bs-tooltip-bottom .arrow::before,
  .bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
    border-bottom-color: white !important;
  }

  .bs-tooltip-left .arrow::before,
  .bs-tooltip-auto[x-placement^='left'] .arrow::before {
    border-left-color: white !important;
  }

  .bs-tooltip-right .arrow::before,
  .bs-tooltip-auto[x-placement^='right'] .arrow::before {
    border-right-color: white !important; // Fixed inconsistent red color
  }
}
