$slate: #64748b;
$secondary-color: #fd6a57;

.pagination-container {
  display: flex;
  list-style-type: none;
  justify-content: center;

  .pagination-item {
    height: 34px;
    text-align: center;
    margin: auto 4px;
    color: $slate;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.3);
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
    transition: all 0.2s ease-in-out;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:hover {
      // background-color: rgba(0, 0, 0, 0.04);
      // border-color: rgba(0, 0, 0, 0.04);
      color: $secondary-color;
      cursor: pointer;
    }

    &.selected {
      background-color: $secondary-color;
      border-color: $secondary-color;
      color: white;
    }

    &.disabled {
      pointer-events: none;
      color: white;
      background-color: #0000001f;
      border: 1px solid #0000001f;

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
