$primary-color: #15cdca;
$secondary-color: #f06a0f;

@import '../../styles/breakpoints.scss';
@import '../../styles/font-size-utility.scss';
@import '../../styles/padding-utility.scss';

@import './styles/table.scss';
@import './styles/buttons.scss';
@import './styles/modals.scss';

#digistall {
  .manage-products {
    padding: 1rem;
    .admin-search-input {
      width: 50%;

      @include respond-to('mobile') {
        width: 100%;
        margin-right: 1rem;
      }

      @include respond-to('tablet') {
        width: 50%;
      }
    }

    .read-more {
      padding-left: 5px !important;
    }

    .title {
      color: $secondary-color;
      font-weight: bold;
      @include respond-to('mobile') {
        font-size: 1.5rem;
      }
    }

    .list-variants {
      // max-width: 600px;
      max-height: 400px;
      // overflow: auto;

      .form-check.form-switch {
        padding-left: 2rem;
      }

      .form-check-input:checked {
        background-color: $primary-color;
        border: 1px solid $primary-color;
      }
    }

    .btn-export {
      color: $secondary-color;
      &:hover {
        background-color: $secondary-color !important;
        color: white;
      }
    }

    .inputFile {
      font-size: 12px;
      padding: 5px 5px 5px 12px;
    }

    .flash {
      background-color: $primary-color;
      -webkit-animation: blink 800ms infinite;
      animation: blink 800ms infinite;
      opacity: 0.75;
      color: #fff !important;
    }

    .lazada-icon {
      margin-bottom: 20px;
    }

    .btn-add-product {
      @include respond-to('desktop') {
        width: auto;
        margin-left: auto;
      }
    }

    @-webkit-keyframes blink {
      50% {
        background-color: #71c9ce;
      }
    }

    @keyframes blink {
      50% {
        background-color: #71c9ce;
      }
    }
  }
}
