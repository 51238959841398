$primary-color: #15cdca;
$orange-color: #f06a0f;
$slate-color: #64748b;

.payment-code {
  .bill__container {
    background-color: white;
    border-radius: 0.25rem;
    flex: 1;
    height: inherit;

    @extend %padding-responsive;
  }

  .bill {
    &__row {
      color: $slate-color;
      justify-content: space-between;
      @extend %text-responsive;
    }

    &__col {
      text-align: end;
    }

    &__subtotal-row,
    &__delivery-fee-row,
    &__pickup-fee-row,
    &__transaction-fee-row {
      margin-bottom: 0.25rem;
      @extend %text-responsive;
    }

    &__subtotal-label,
    &__delivery-fee-label,
    &__pickup-fee-label,
    &__transaction-fee-label {
      text-align: start;
    }

    &__transaction-fee-label {
      color: $orange-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
    }

    &__subtotal-value,
    &__delivery-fee-value,
    &__pickup-fee-value,
    &__transaction-fee-value {
      text-align: end;
      font-weight: bold;
      // color: $primary-color;
    }

    &__transaction-fee-row {
      color: $orange-color;
    }

    &__total-row {
      font-weight: bold;
      color: #15cdca;
      @extend %text-responsive;
    }

    &__total-label {
      text-align: start;
    }

    &__total-value {
      text-align: end;
    }

    &__product-name {
      color: $slate-color;
    }

    &__toc-list {
      padding-left: 1rem;
      margin-bottom: 0px;
    }

    &__text-break {
      word-break: break-word;
    }

    &__product-item {
      color: $slate-color;
    }

    &__product-quantity {
      color: $slate-color;
    }

    &__product-total-price {
      font-weight: bold;
      // color: $primary-color;
    }
  }
}
