.generated-domain {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80vh;
  width: 90%;
  margin: 30px auto 0 auto;
  &-box {
    height: 170px;
    border-radius: 4px;
    border: 1px solid #73d042;
    position: relative;
  }
  &-check-icon {
    color: #73d042;
    font-size: 1.6rem;
    position: absolute;
    top: -5.5%;
    left: 46%;
    background-color: white;
  }
  &-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 12px;
    span {
      font-size: 0.8rem;
    }
    p {
      font-size: 0.8rem;
      font-weight: 500;
      margin: 6px 0;
    }
    a {
      text-decoration: underline;
      font-size: 1rem;
      color: #15cdca;
    }
  }
  &-mess {
    text-align: center;
    padding: 0 20%;
    color: black;
  }
  &-actions {
    // width: 80%;
    margin: 0 auto;
    text-align: center;
    span {
      font-size: 0.6rem;
    }
    button {
      outline: none;
      border: 1px solid #15cdca;
      background-color: transparent;
      width: 100%;
      padding: 1rem;
      color: #079d9a;
      font-weight: 600;
      margin: 8px 0;
      border-radius: 4px;
    }
  }
}

.input-delivery-date {
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 10px;

  .form-control {
    border: none !important;
  }
}

.group-hour-delivery {
  display: flex;
  justify-content: space-between;

  @media (max-width: 340px) {
    flex-direction: column;

    .input-delivery-hour {
      margin-top: 0.2rem;

      input {
        width: 100%;
      }
    }

    .icon-cross {
      display: none;
    }
  }

  .input-delivery-hour {
    div {
      font-size: 0.775rem;
    }

    input {
      box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
      border-radius: 10px;
      padding: 0.375rem 0.75rem !important;
      border: none !important;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background-position: 90% center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px'%0Awidth='18' height='18'%0AviewBox='0 0 172 172'%0Astyle=' fill:%23000000;'%3E%3Cg fill='none' fill-rule='nonzero' stroke='none' stroke-width='1' stroke-linecap='butt' stroke-linejoin='miter' stroke-miterlimit='10' stroke-dasharray='' stroke-dashoffset='0' font-family='none' font-weight='none' font-size='none' text-anchor='none' style='mix-blend-mode: normal'%3E%3Cpath d='M0,172v-172h172v172z' fill='none'%3E%3C/path%3E%3Cg fill='%23495057'%3E%3Cpath d='M86,14.33333c-39.49552,0 -71.66667,32.17115 -71.66667,71.66667c0,39.49552 32.17115,71.66667 71.66667,71.66667c39.49552,0 71.66667,-32.17115 71.66667,-71.66667c0,-39.49552 -32.17115,-71.66667 -71.66667,-71.66667zM86,28.66667c31.74921,0 57.33333,25.58412 57.33333,57.33333c0,31.74921 -25.58412,57.33333 -57.33333,57.33333c-31.74921,0 -57.33333,-25.58412 -57.33333,-57.33333c0,-31.74921 25.58412,-57.33333 57.33333,-57.33333zM78.83333,43v45.96744l30.76628,30.76628l10.13411,-10.13411l-26.56706,-26.56706v-40.03256z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
      border-radius: 10px;
      padding: 0.375rem 2.3rem 0.375rem 0.75rem !important;
      border: none !important;
      color: #495057;

      &:focus {
        outline: none;
        border: none;
      }
    }
  }
}

.input-delivery-group {
  select {
    color: #495057;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    border-radius: 10px;
    padding: 0.375rem 0.75rem !important;
    border: none !important;
    width: 100%;

    &:focus {
      border: none !important;
      outline: none;
    }
  }
}

.opening-hours {
  font-size: 16px;

  label {
    cursor: pointer;
    user-select: none;
  }

  input:checked + span::before {
    content: '';
  }

  .date-open {
    width: 10px;
    height: 10px;
    background-color: lightblue;
    border: 2px solid gray;
    margin-right: 50px;

    &::after {
      content: 'Open';
      position: absolute;
      top: -8px;
      left: 13px;
    }
  }

  .date-close {
    width: 10px;
    height: 10px;
    background-color: gray;
    border: 1px solid gray;
    margin-right: 40px;

    &::after {
      content: 'Close';
      position: absolute;
      top: -8px;
      left: 13px;
    }
  }

  .date-of-week {
    border: 2px solid black;
    height: 40px;
    color: black;
    font-weight: 600;
  }
}

@media screen and (max-width: 768px) {
  .generated-domain button {
    font-size: 14px;
  }

  // .generated-domain-check-icon {
  //   top: -15%;
  // }
}
