.shop-detail {
  .product-container {
    &--grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr); // Default to 1 column
      gap: 1rem;
      margin-bottom: 1rem;

      @include respond-to('mobile') {
        grid-template-columns: repeat(2, 1fr); // 2 columns for mobile
      }

      @media screen and (min-width: 320px) and (max-width: 375px) {
        grid-template-columns: repeat(1, 1fr); // 2 columns for mobile
      }

      @include respond-to('tablet') {
        grid-template-columns: repeat(3, 1fr); // 3 columns for tablet
        margin-bottom: 2rem;
      }

      @include respond-to('desktop') {
        grid-template-columns: repeat(4, 1fr); // 4 columns for desktop
        margin-bottom: 2rem;
      }

      @media screen and (max-width: 425px) {
        gap: 0.5rem;
      }
    }

    &--list {
      @include flex-center;
      flex-direction: column;
      gap: 1rem;

      @media screen and (max-width: 425px) {
        gap: 0.5rem;
      }
    }
  }
}
