@import '../../styles/breakpoints.scss';
@import '../../styles/font-size-utility.scss';

$sticky-column-width: 60px;
$max-sticky-columns: 5;

.custom-table {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  // Add specific styles for table-delivery-list
  &.table-delivery-list {
    table {
      td.col-order {
        max-width: 250px; // Add max-width constraint
        white-space: normal;
        word-break: break-word;
        overflow: hidden;
      }
    }
  }

  .table-wrapper {
    flex: 1;
    width: 100%;
    overflow-x: auto; // Chỉ cho phép scroll ngang
    position: relative;
    cursor: grab;
    user-select: none;
    min-height: 350px;
    // custom scrollbar
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    // Ensure dropdown menus appear above the table
    .select-status {
      position: relative;
      // z-index: 1010; // Higher than table headers

      .dropdown-menu,
      .select-options {
        position: absolute;
        z-index: 1011; // Higher than the select itself
      }
    }

    // // Thêm styles cho payment status cells
    // .col-total-amount {
    //   cursor: pointer;
    //   pointer-events: auto !important; // Force enable pointer events

    //   // Cho phép click vào các phần tử con
    //   * {
    //     pointer-events: auto !important;
    //   }
    // }

    &::-webkit-scrollbar {
      width: 0; // Ẩn thanh scroll dọc
      height: 6px; // Chiều cao của thanh scroll ngang
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &.grabbing {
      cursor: grabbing;

      table {
        pointer-events: none;

        .col-booker-status,
        .col-payment-status,
        .col-status {
          pointer-events: auto;
        }

        // Add specific rules for action buttons
        .action-button,
        .order-number-container {
          pointer-events: auto;
          cursor: pointer;

          svg {
            pointer-events: auto;
            cursor: pointer;
          }
        }

        // Add this to allow clicking on buttons
        button,
        .custom-button,
        select,
        .select-status,
        .col-total-amount {
          pointer-events: auto;
          cursor: pointer;

          &:hover {
            cursor: pointer;
          }
        }

        // Thêm style cho payment status cells khi dragging
        .col-total-amount {
          * {
            pointer-events: auto;
          }
        }
      }
    }

    // Adjust table header to be sticky relative to wrapper
    thead {
      position: sticky;
      top: 0;
      z-index: 1009;
    }
  }

  // Xóa các styles cho scrollbar của container chính
  &::-webkit-scrollbar {
    display: none;
  }

  table {
    margin: 0;
    table-layout: auto; // Changed from fixed to auto to respect min-width
    width: 100%;
    border-collapse: collapse;
    user-select: none;

    thead {
      height: 55px;
      background: #f8f9fa;

      th {
        background: #f8f9fa;
        border-bottom: 2px solid #edf2f9;
        color: #6e84a3;
        font-weight: 600;
        font-size: 0.8rem;
        padding: 1rem;
        text-align: left;
        white-space: nowrap;
        width: auto; // Change to auto
        min-width: fit-content;
        box-sizing: border-box;
      }
    }

    th,
    td {
      vertical-align: middle;
      padding: 1rem;
      overflow: hidden;
      word-wrap: break-word;
    }

    // Add specific classes for columns that need text wrapping
    td.col-order,
    td.col-fulfillment {
      white-space: normal; // Allow text wrapping
      word-break: break-word;
      min-width: 200px; // Ensure minimum width for readability
    }

    // Keep nowrap for columns that should stay on one line
    td.col-order-no,
    td.col-status,
    td.col-booker-status,
    td.col-fulfill-date {
      white-space: nowrap;
    }

    td.col-status {
      overflow: visible;
    }

    td {
      color: #2c3345;
      font-size: 0.8rem;
      border-bottom: 1px solid #edf2f9;
      // background: inherit;
    }

    tr {
      // using import to overwrite the class name background-color of bootstrap
      &:hover {
        background-color: #f3f3f3 !important;

        td.sticky-col {
          background-color: #f3f3f3 !important;
        }
      }

      &.bg-light td.sticky-col {
        background-color: #f8f9fa;
      }

      &.bg-white td.sticky-col {
        background-color: #fff;
      }
    }

    tfoot {
      background: #fff;
      position: sticky;
      bottom: 0;

      td {
        padding: 1rem;
        border-top: 2px solid #edf2f9;
      }

      tr:hover {
        background-color: initial;
      }
    }

    @include respond-to('mobile') {
      min-width: 900px;

      th,
      td {
        font-size: $font-size-text-mobile;
        padding: 0.75rem;
      }
    }

    @include respond-to('tablet') {
      min-width: 1000px;

      th,
      td {
        font-size: $font-size-text-tablet;
        padding: 0.75rem;
      }
    }
  }

  .sticky-col {
    position: sticky;
    z-index: 2;
    background-clip: padding-box;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);

    &.last-sticky {
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: -1px;
        width: 5px;
        border-left: 1px solid #e9ecef;
        // Mặc định có gradient
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0) 100%);
        transition: background 0.2s ease;
      }

      // Ẩn gradient khi KHÔNG is-scrollable
      &.is-scrollable::after {
        background: none;
      }
    }

    // Generate dynamic sticky columns
    @for $i from 0 through $max-sticky-columns {
      &.sticky-col-#{$i} {
        left: $sticky-column-width * $i;
        z-index: 3 + $i;
      }
    }
  }

  thead th.sticky-col {
    z-index: 4;

    @for $i from 0 through $max-sticky-columns {
      &.sticky-col-#{$i} {
        z-index: 11 + $i; // Ensure headers stay above body cells
      }
    }
  }

  // Add responsive styles
  @include respond-to('mobile') {
    height: calc(100vh - 150px); // Adjust height for mobile

    .table-wrapper {
      min-width: 100%;
    }
  }

  @include respond-to('tablet') {
    height: calc(100vh - 180px); // Adjust height for tablet
  }
}
