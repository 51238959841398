@import '../../../assets/css/base/functions';
@import '../../../assets/css/base/variables';
@import '../../../assets/css/base/mixins';

@import '../../../styles/breakpoints.scss';
@import '../../../styles/font-size-utility.scss';

.created-confirm-page {
  font-family: $font-family-other;
  width: 100%;
  padding-top: 2rem;

  .wrapper-user-info {
    .user-info {
      margin-right: 15px;
    }
    h3 {
      font-size: 0.75rem;
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
      font-size: 0.6875rem;
    }
  }

  .group-list {
    &__item {
      border: none;
      flex-wrap: wrap;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      background-color: #fff;
      border-radius: 15px;
      margin-bottom: 1rem;
      padding: 1rem;
      color: gray;
    }
    &__des {
      h4 {
        margin-top: 10px;
        color: #51aaaa;
        @extend %text-responsive;
      }
      p {
        @extend %text-responsive;
      }
    }
    &__detail {
      .text-blue {
        color: #51aaaa;
      }
      p {
        margin-bottom: 5px;
      }
    }
    &__btn {
      display: flex;
      flex-direction: column;
      width: fit-content;
      margin-left: auto;
      font-size: 0.6875rem;

      .txt-date {
        color: #f06a0f;
      }

      .txt-number {
        background: #f06a0f;
        color: #fff;
        padding: 3px 11px;
        border-radius: 5px;
        display: inline-block;
        width: fit-content;
        margin-left: auto;
      }
    }
  }

  .image-gallery-slides {
    img {
      height: 300px;
    }
  }

  .detail-list-news {
    font-size: 0.75rem;
    color: #51aaaa;
    margin-top: 15px;
    &__item {
      color: #51aaaa !important;

      h3 {
        font-weight: 500 !important;
      }

      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
      }
    }
  }

  input {
    &:focus {
      box-shadow: none !important;
    }
  }

  .nav-amount {
    background: rgba(#21cdca, 0.1);
    padding: 20px;
    margin: 0px !important;

    .c-txt-org {
      color: #079d9a;
    }
    .note {
      font-size: rem(10);
      color: #079d9a;
    }
    & ~ .c-form {
      margin-top: 30px;

      textarea {
        height: 79px;
        margin-top: 10px;
        margin-bottom: 20px;
        resize: none;
        overflow: auto;

        &.form-control.form-control--gray.form-control--outline {
          background: none;
          border: 1px solid #ccc;
        }
      }
    }
  }
  .is-close {
    display: none;
  }

  .btn.disabled {
    background: #797979;
    border-color: #797979;
    color: white;
  }

  @media screen and (max-width: 768px) {
    padding: 2rem 1rem 1rem 1rem;

    .icon-min,
    .icon-plus {
      height: 18px !important;
      width: 18px !important;
    }
  }
}
