$danger: #d33;
$slate-gray: #64748b;

.shop-detail {
  // View selector component
  .view-selector {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1.5rem;

    &__btn {
      display: inline-flex;
      align-items: center;
      padding: 0.5rem 1rem;
      border: 1px solid #e5e7eb;
      background: transparent;
      color: #6b7280;
      font-size: 0.675rem;
      // font-weight: 500;
      border-radius: 0.25rem;
      transition: all 0.2s ease;

      // Modifiers
      &--list {
        &:hover {
          background: rgba($slate-gray, 0.05);
          border-color: $slate-gray;
          color: $slate-gray;
        }
      }

      &--grid {
        &:hover {
          background: rgba($slate-gray, 0.05);
          border-color: $slate-gray;
          color: $slate-gray;
        }
      }

      &--active {
        background: $slate-gray;
        border-color: $slate-gray;
        color: #fff;

        &:hover {
          background: darken($slate-gray, 5%);
          color: #fff;
        }
      }
    }

    &__icon {
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0.5rem;
    }

    &__text {
      line-height: 1;
    }

    .shop-detail__clear-cart-btn {
      display: inline-flex;
      align-items: center;
      padding: 0.5rem 1rem;
      border: 1px solid $danger;
      background: transparent;
      color: $danger;
      font-size: 0.675rem;
      border-radius: 0.25rem;
      transition: all 0.2s ease;
      margin-left: auto;

      &:hover {
        color: darken($danger, 10%);
        border-color: darken($danger, 10%);
        background: rgba($danger, 0.05);
      }

      &:active {
        background: darken(rgba($danger, 0.05), 10%);
        color: darken($danger, 10%);
        border-color: darken($danger, 10%);
      }
    }

    .shop-detail__clear-cart-text {
      line-height: 1;
    }

    .shop-detail__clear-cart-icon {
      margin-right: 0.5rem;
      width: 0.75rem;
      height: 0.75rem;
    }

    // Responsive
    @include respond-to('mobile') {
      gap: 0.5rem;

      &__btn {
        padding: 0.5rem 0.75rem;
        font-size: 0.813rem;
      }

      .shop-detail__clear-cart-btn {
        padding: 0.5rem 0.75rem;
        font-size: 0.813rem;
      }

      &__icon {
        margin-right: 0.375rem;
      }
    }
  }
}
