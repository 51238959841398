$primary: #15cdca;
$warning: #ff6903;
$slate-gray: #536980;

.shop-detail {
  // Components
  &-banner {
    $self: &;
    margin-bottom: 1.5rem;

    // Header styles
    &__header {
      margin-bottom: 1rem;

      &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    // Title styles
    &__title {
      font-size: 1.5rem;
      font-weight: 700;
      margin: 0;
      line-height: 1.2;
      color: $warning;
      @include text-ellipsis(1);

      @include respond-to('tablet') {
        font-size: 1.75rem;
      }
    }

    // Sign in link
    &__sign-in {
      font-weight: 400;
      color: $slate-gray;
      text-decoration: none;
      transition: color 0.2s ease;

      &:hover {
        color: darken($slate-gray, 5%);
      }
    }

    // Media section
    &__media {
      margin-bottom: 1.5rem;
      height: 500px;

      @include respond-to('mobile') {
        height: 300px;
      }
    }

    // Description section
    &__description {
      margin-bottom: 1rem;

      &-title {
        font-size: 1rem;
        font-weight: 600;
        color: $primary;
        line-height: 1.4;
        text-transform: uppercase;
        display: flex;
        align-items: center;

        @include text-ellipsis(1);
      }

      &-icon {
        margin-right: 0.25rem;
        font-size: 1rem;
      }

      &-content {
        font-size: 0.875rem;
        color: $slate-gray;
        line-height: 1.4;
        margin-bottom: 0;
      }
    }
  }

  .external-link {
    position: relative;
    z-index: 9999;

    .dropdown-menu {
      z-index: 9999;
      overflow: hidden;
      @extend %text-responsive;

      .dropdown-item {
        padding: 0.25rem 0.5rem;
      }
    }
  }

  .image-gallery {
    width: 100%;
    height: 100%;
  }

  .image-gallery-slides {
    border-radius: 0.5rem;
  }

  .image-gallery-slide {
    height: 100%;
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .image-gallery-image {
    width: auto;
    height: 500px;
  }

  .image-gallery-slide-wrapper,
  .image-gallery-content {
    height: 100%;
  }

  .slider {
    &__container {
      width: 100%;
      height: 100%;
      max-height: 500px;
      position: relative;
      overflow: hidden;
      border-radius: 0.5rem;
      z-index: 1; // Thêm z-index thấp hơn cho slider
    }

    &__single {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        object-fit: contain;
      }
    }

    &__no-image {
      width: 100%;
      height: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.5rem;
      background-color: #536980;
    }

    // Custom navigation buttons
    &__nav {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 4;
      width: 40px;
      height: 40px;
      padding: 0;
      border: none;
      border-radius: 50%;
      background: transparent;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      // Icon color
      svg {
        color: $primary;

        &:hover {
          color: darken($primary, 10%);
        }

        &:active {
          color: darken($primary, 20%);
        }
      }

      // Positioning
      &--prev {
        left: 16px;
      }

      &--next {
        right: 16px;
      }
    }

    // Responsive
    @media screen and (max-width: 768px) {
      &__container,
      &__single,
      &__no-image,
      &__image {
        height: 300px;

        img {
          object-fit: cover;
        }
      }

      .image-gallery-slide,
      .image-gallery-image {
        height: 300px;
        width: auto;
      }
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
      &__container,
      &__single,
      &__no-image,
      &__image {
        height: 400px;
      }

      .image-gallery-slide,
      .image-gallery-image {
        height: 400px;
      }
    }
  }
}
