@import '../../../../assets/css/base/functions';
@import '../../../../assets/css/base/variables';
@import '../../../../assets/css/base/mixins';
@import '../../../../styles/breakpoints.scss';

.mobile-drawer {
  position: fixed;
  top: 0;
  right: -100%;
  width: 85%;
  max-width: 320px;
  height: 100vh;
  background: white;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
  box-shadow: -2px 0 12px rgba(0, 0, 0, 0.1);

  &.open {
    right: 0;
  }

  &__header {
    background: $primary;
    padding: 1rem;
    position: relative;

    .close-button {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      padding: 0.5rem;
      cursor: pointer;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: all 0.2s ease;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }

      &:active {
        transform: translateY(-50%) scale(0.95);
      }
    }
  }

  &__content {
    height: calc(100% - 64px); // Subtract header height
    overflow-y: auto;
  }

  &__user {
    background: linear-gradient(to right, darken($primary, 5%), $primary);
    padding: 1.5rem 1rem;
    display: flex;
    align-items: center;
    color: white;

    .avatar-top__img {
      width: 50px;
      height: 50px;
      border: 2px solid rgba(255, 255, 255, 0.8);
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .user-name {
      margin-left: 1rem;
      font-weight: 500;
      font-size: 1rem;
      color: white;
    }
  }

  &__menu {
    padding: 0.5rem 0;

    .menu-divider {
      height: 1px;
      background: #eee;
      margin: 0.5rem 0;
    }

    a,
    .logout-button {
      display: flex;
      align-items: center;
      padding: 0.875rem 1rem;
      color: #444;
      text-decoration: none;
      cursor: pointer;
      transition: all 0.2s ease;
      font-size: 0.9375rem;

      svg {
        font-size: 1.25rem;
        margin-right: 0.75rem;
        color: $primary;
      }

      &:hover {
        background: rgba($primary, 0.05);
        color: $primary;
      }

      &:active {
        background: rgba($primary, 0.1);
      }
    }

    .logout-section {
      margin-top: 0.5rem;
      padding-top: 0.5rem;
      border-top: 1px solid #eee;

      .logout-button {
        color: #64748b;

        svg {
          color: #64748b;
        }

        &:hover {
          background: rgba(#64748b, 0.05);
          color: #64748b;
        }

        &:active {
          background: rgba(#64748b, 0.1);
        }
      }
    }
  }
}

.mobile-drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  backdrop-filter: blur(2px);
  transition: all 0.3s ease-in-out;
}
