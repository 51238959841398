// src/components/ErrorBoundary/styles.scss
.error-boundary {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #f8f9fa;
  padding: 20px;

  .error-content {
    text-align: center;
    padding: 40px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    animation: fadeIn 0.5s ease-in;
  }

  .error-icon {
    font-size: 64px;
    color: #dc3545;
    margin-bottom: 20px;
  }

  .error-title {
    font-size: 24px;
    color: #343a40;
    margin-bottom: 16px;
  }

  .error-message {
    color: #6c757d;
    margin-bottom: 24px;
    line-height: 1.5;
  }

  .reload-button {
    background: #0badad;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.2s ease;

    &:hover {
      background: darken(#0badad, 10%);
    }

    &:active {
      transform: translateY(1px);
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 576px) {
  .error-boundary {
    padding: 16px;

    .error-content {
      padding: 24px;
    }

    .error-title {
      font-size: 20px;
    }
  }
}
