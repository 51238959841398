@import '../../styles/breakpoints.scss';
@import '../../styles/font-size-utility.scss';

.time-range-select {
  .select-header {
    background: #fff;
    border: 1px solid #ced4da;

    &:hover {
      border-color: #15cdca;
    }

    &.open {
      border-color: #15cdca;
    }
  }

  .time-slots-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    max-height: 200px;
    // overflow-y: auto;
  }

  .time-slot-item {
    display: flex;
    align-items: center;
    padding: 0px 12px;
    min-height: 34px;
    height: 34px;
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
    overflow: hidden;

    &:hover:not(.disabled) {
      background-color: #f5f5f5;
      border-color: #15cdca;
    }

    &.selected {
      background: #e3f2fd;
      border: 2px solid #15cdca !important;

      &.disabled {
        &::after {
          border-left: 20px solid transparent;
          border-top: 20px solid #15cdca;
          rotate: 90deg;
        }
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-top: 20px solid #15cdca;
        rotate: 90deg;
      }

      &::before {
        content: '✔';
        position: absolute;
        bottom: -1px;
        right: 0px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        font-size: 12px;

        @include respond-to('mobile') {
          bottom: 2px;
        }
      }

      .time-text {
        color: #15cdca;
        font-weight: 500;
      }
    }

    &.disabled {
      background-color: #f5f5f5;
      border-color: #e5e7eb;
      cursor: not-allowed;
      &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        background: transparent;
        border-radius: 0px;
      }

      .time-icon {
        // background-color: #e5e7eb;

        svg {
          color: #9ca3af;
        }
      }

      .time-text {
        color: #9ca3af;
      }
    }

    .time-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 34px;
      // background-color: #f8f9fa;
      border-radius: 6px;
      margin-right: 8px;
      color: #64748b;
      transition: all 0.2s ease;

      &.disabled {
        // background-color: #e5e7eb;
        color: #9ca3af;
      }

      @include respond-to('mobile') {
        height: 24px;
        width: 24px;
      }
    }

    .time-text {
      flex: 1;
      font-size: 15px;
      color: #475569;

      &.disabled {
        color: #9ca3af;
      }

      @extend %text-responsive;
    }
  }

  @media (max-width: 480px) {
    .time-slot-item {
      padding: 5px 8px;
      .time-icon {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
      .time-text {
        font-size: 10px;
      }
    }
  }
}
