.list_date-fulfillment {
  max-height: calc(100vh - 545px);
  overflow-y: auto;

  // Modern scrollbar styling (Chrome, Safari, Edge)
  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 100px;
    border: 2px solid transparent;
    background-clip: padding-box;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  // Firefox scrollbar
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;

  &-item {
    padding: 0 9px;
    border-radius: 6px;
    width: 90%;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    border: 1px solid #f06a0f;
    color: #f06a0f;

    &:last-child {
      margin-bottom: 0;
    }

    > span {
      font-weight: 600;
      font-size: 0.53rem;
    }
  }
}

.active-s {
  color: white;
  background-color: #f06a0f;
}
