$primary-color: #15cdca;

@import '../../../styles/breakpoints.scss';

#digistall {
  .fulfillment {
    @include respond-to('mobile') {
      padding: 1rem !important;
    }

    .form-group {
      label {
        color: #64748b;
      }
    }

    .form-control-sm {
      &:active,
      &:focus {
        box-shadow: none;
        border-color: $primary-color;
      }
    }

    .fulfillment-options {
      @include respond-to('mobile') {
        flex-direction: column;

        .col-5 {
          width: 100%;
          margin-bottom: 1rem;
        }
      }

      @include respond-to('desktop') {
        justify-content: space-between;
      }
    }
  }

  .form-check-input:active,
  .form-check-input:focus {
    border: 1px solid $primary-color;
  }

  .time-modal,
  .date-modal {
    .title {
      font-weight: bold;
      color: #f06a0f;
    }

    .btn--org:hover {
      background-color: #f06a0f !important;
      color: white;
    }

    .react-date-picker__wrapper {
      border: 0;
    }
  }

  .btn-submit:hover {
    background-color: #e0620e !important;
    color: white !important;
  }

  .delivery-options {
    font-size: 12px;
    background-color: #f7f6f7;

    @include respond-to('mobile') {
      padding: 1rem !important;
    }

    .form-check {
      min-height: 8px !important;
    }

    .form-check-label {
      font-weight: 600;
    }

    .btn-update {
      border: 1px solid #079d9a !important;

      @include respond-to('mobile') {
        width: 100%;
        margin-top: 0.5rem;
      }

      &:hover {
        background-color: white !important;
        border: 1px solid #079d9a !important;
        color: #079d9a !important;
      }
    }

    .row {
      @include respond-to('mobile') {
        .col-6 {
          width: 100%;
        }

        button {
          width: 100%;
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  .opening-hours {
    font-size: 12px;

    &__date {
      justify-content: space-between;
    }

    @include respond-to('mobile') {
      font-size: 14px;
      margin-bottom: 1rem;

      .col-12:first-child {
        flex-direction: column;
        align-items: flex-start !important;

        > span:first-child {
          margin-bottom: 0.5rem;
        }

        .date-wrapper {
          width: 100%;
          justify-content: space-between;
        }

        .date-open,
        .date-close {
          &::after {
            font-size: 12px;
            left: 20px;
          }
        }

        .date-open {
          left: 0;
        }

        .date-close {
          left: -30px;
        }
      }
    }

    @include respond-to('desktop') {
      &__holiday {
        display: flex;
        justify-content: flex-end;
      }
    }

    label {
      cursor: pointer;
      user-select: none;
    }
    input:checked + span::before {
      content: '';
    }

    .date-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .date-open {
      width: 10px;
      height: 10px;
      background-color: lightblue;
      border: 2px solid gray;
      margin-right: 50px;
      display: inline-block;
      left: -50px;

      &::after {
        content: 'Available';
        position: absolute;
        top: -3px;
        left: 13px;
        line-height: 1;
      }
    }

    .date-close {
      width: 10px;
      height: 10px;
      background-color: gray;
      border: 1px solid gray;
      margin-right: 40px;
      display: inline-block;
      left: -30px;

      &::after {
        content: 'Not Available';
        position: absolute;
        top: -3px;
        left: 13px;
        line-height: 1;
        width: 85px;
      }
    }

    .date-of-week {
      border: 2px solid black;
      height: 40px;
      color: black;
      font-weight: 600;
    }
  }

  .delivery-hour-wrapper {
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    gap: 1rem;
  }

  .delivery-time-slot {
    @include respond-to('mobile') {
      &__options {
        gap: 8px;
      }
    }
  }

  .delivery-time-slot__options {
    display: flex;
    flex-wrap: wrap;
    margin-top: 14px;
    @include respond-to('mobile') {
      justify-content: center;
    }

    @include respond-to('desktop') {
      justify-content: space-between;
    }
  }

  .btn-add-zones {
    width: 212px;
    @include respond-to('mobile') {
      width: 100% !important;
    }

    @include respond-to('tablet') {
      width: auto !important;
    }
  }
}
