$primary-color: #0badad;

#digistall {
  // Account
  .setting-account {
    position: relative;
    height: 450px;

    .btn-wrap {
      position: absolute;
      bottom: 2rem;
      right: 1rem;
    }

    .custom-input {
      background-color: white;
      width: 100%;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid gray;
      padding-bottom: 5px;
    }

    .btn-save {
      &:disabled {
        border: 1px solid $primary-color !important;
      }
    }
  }
}
