// Base ----------------------------------------------------------------
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap');
@import 'base/functions';
@import 'base/variables';
@import 'base/mixins';
@import 'base/root';
@import 'base/reboot';
@import 'base/type';
@import 'base/utilities';

// End Base ------------------------------------------------------------

// Start component -----------------------------------------------------
@import 'components/images';
@import 'components/code';
@import 'components/grid';
@import 'components/tables';
@import 'components/forms';
@import 'components/fonts';
@import 'components/icons';
@import 'components/buttons';
@import 'components/transitions';
@import 'components/dropdown';
@import 'components/button-group';
@import 'components/input-group';
@import 'components/custom-forms';
@import 'components/nav';
@import 'components/navbar';
@import 'components/card';
@import 'components/breadcrumb';
@import 'components/pagination';
// @import "components/badge";
// @import "components/jumbotron";
@import 'components/alert';
@import 'components/progress';
@import 'components/media';
@import 'components/list-group';
@import 'components/close';
@import 'components/modal';
@import 'components/tooltip';
@import 'components/popover';
@import 'components/carousel';
@import 'components/slick';
@import 'components/slick-lightbox';
@import 'components/print';
// Extend
@import 'components/select';
// @import "components/range";
@import 'components/scroll';
// @import "components/datetime";
@import 'components/notify';
// @import "components/waypoint";

// End component -------------------------------------------------------

// Start Modules -------------------------------------------------------
// End Modules ---------------------------------------------------------

// Start Tempmlate
// End Tempmlate

// Page Modules -------------------------------------------------------
@import 'pages/_form';

// Custom ---------------------------------------------------------
@include media-breakpoint-up(md) {
  html,
  body {
    font-size: 20px;
  }
}

#digistall {
  height: 100%;
  a {
    &:hover {
      color: inherit;
      text-decoration: none !important;
    }

    &:focus {
      outline: none !important;
    }
  }

  // .primary-background {
  //   background-color: #15cdca !important ;
  // }

  .btn:focus,
  .btn.focus {
    box-shadow: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // input[type='number'] {
  //   -moz-appearance: textfield;
  // }
  .container {
    // max-width: 375px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;

    @include media-breakpoint-up(md) {
      max-width: 640px;
    }
  }

  // @note duplicate style in sign in page
  .p-login {
    background-color: #15cdca;

    @include media-breakpoint-up(md) {
      .page-login {
        max-width: 640px;
        margin: 0 auto;
      }
    }

    @include media-breakpoint-down(sm) {
      background: white;

      .page-login {
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }
  .main-logo {
    a {
      span {
        display: block;
        font-size: rem(11);
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .a-link {
    font-size: rem(16);
    color: #fff;
    text-decoration: underline;
  }

  // title ---------------------------------------------------------
  .ttl-grp {
    font-size: rem(22);
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    margin: 0;
    line-height: 1;
    padding-top: 13px;
  }
  .ttl-company {
    margin: 0;
    padding: 0;
  }

  img {
    max-width: 100%;
  }

  .c-ttl {
    font-family: $font-family-other;
    font-size: rem(25);
    color: #ff6903;
    font-weight: normal;

    a {
      font-size: rem(25);
      color: #f06a0f;
      display: inline-block;
      margin: 19px 0px 37px 0px;
      text-decoration: underline;
    }

    &--01 {
      font-size: rem(20);

      span {
        display: block;
        font-size: rem(18);
      }
    }

    &--02 {
      font-size: rem(28);
      margin: 0;
    }
  }
  .c-ttl-blue {
    color: #51aaaa;
    font-size: rem(14);

    a {
      color: #51aaaa;
      text-decoration: underline;
    }
  }

  .c-txt-org {
    font-size: rem(13);
    color: #ff6903;
  }
  .c-txt-blue {
    color: #079d9a !important;
  }
  .c-txt-gray {
    font-size: rem(13);
    color: #7c797a !important;
  }

  .c-txt-12 {
    font-size: rem(12);
    margin: 0;
    line-height: calc(22 / 12);
  }

  .c-txt-18 {
    font-size: rem(18);
    margin: 10px 0 20px 0;
    line-height: calc(25 / 18);
  }

  .c-txt-13 {
    font-size: rem(12);
    margin: 0;
    line-height: calc(22 / 12);
  }

  .c-txt-9 {
    font-size: rem(9);
    margin: 0;
    line-height: calc(22 / 12);
  }

  .txt-org {
    font-weight: bold;
    color: #f06a0f !important;
  }
  .font-date {
    font-size: 1rem;
    margin-top: -4px !important;
  }
  .c-txt-14 {
    font-size: rem(14);
    line-height: calc(22 / 12);
    font-weight: bold;
    color: #f06a0f;
    small {
      display: block;
    }
    &.c-ttl-blue {
      color: #51aaaa;
    }
    span {
      font-weight: normal;
    }

    a {
      color: inherit;
    }
  }
  .text-border {
    background: none;
    text-transform: uppercase;
    font-size: 1.125rem;
    font-weight: bold;
    height: 80px;
    border-radius: 20px;
    padding: 10px;
    border: 1px solid #7c797a;
    color: #7c797a;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    text-align: center;
    display: flex;
    span {
      color: #07c3c3;
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
      b {
        font-size: 0.75rem;
        display: block;
        color: #7c797a;
        text-transform: initial;
      }
    }
  }
  .text-underline {
    text-decoration: underline !important;
  }

  .link-share {
    font-size: rem(10);
    font-weight: bold;
    color: #7e7e7e;
    margin-top: 1rem;
  }

  .link-org {
    font-weight: bold;
    color: #f06a0f;
  }

  .c-txt-15 {
    font-size: rem(15);
  }
  .c-txt-25 {
    font-size: rem(25);
  }
  a.c-txt-15 {
    text-decoration: underline;
  }

  .c-image {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.7);
    overflow: hidden;

    a {
      justify-content: center;
      display: flex;
      height: 100%;
      align-items: center;
    }

    img {
      @include avatarSize;
    }
  }

  .line-text {
    text-align: center;
    font-size: rem(16);
    text-transform: uppercase;
    color: #7c797a;
    margin: 40px 0 20px 0;

    span {
      display: inline-block;
      position: relative;
      padding: 0 10px;

      &::after,
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        height: 1px;
        width: 30px;
        background-color: #07c3c3;
      }

      &:before {
        left: 100%;
      }

      &:after {
        right: 100%;
      }
    }
  }

  .txt-number--org {
    color: #ff6903;
    font-size: rem(11);

    .icon-user {
      width: 19px;
      height: 15px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='14.705' viewBox='0 0 19 14.705'%3E%3Cg id='Icon_feather-user-check' data-name='Icon feather-user-check' transform='translate(-1 -5)'%3E%3Cpath id='Path_384' data-name='Path 384' d='M13.632,27.353V25.735A3.235,3.235,0,0,0,10.4,22.5H4.735A3.235,3.235,0,0,0,1.5,25.735v1.618' transform='translate(0 -8.148)' fill='none' stroke='%23f6b20c' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cpath id='Path_385' data-name='Path 385' d='M13.22,7.735A3.235,3.235,0,1,1,9.985,4.5,3.235,3.235,0,0,1,13.22,7.735Z' transform='translate(-2.419 1)' fill='none' stroke='%23f6b20c' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3Cpath id='Path_386' data-name='Path 386' d='M25.5,15.118l1.618,1.618L30.353,13.5' transform='translate(-11.059 -4.001)' fill='none' stroke='%23f6b20c' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }

  .t-intro {
    color: #f06a0f;
    font-size: 13px;
    text-align: center;
    position: absolute;
    width: 194px;
    height: 151px;
    padding-top: 69px;
    left: 13px;
    padding-left: 10px;
    padding-right: 10px;

    &--01 {
      background: url(../images/bg_intro.png) no-repeat;
      left: 9%;
    }

    &--02 {
      background: url(../images/bg_intro_02.png) no-repeat;
      width: 221px;
      height: 229px;
      padding-top: 145px;
      padding-left: 20px;
      padding-right: 20px;
      left: 44%;
    }

    &--05 {
      background: url(../images/bg_intro_05.png) no-repeat;
      width: 293px;
      height: 213px;
      padding-top: 118px;
      padding-left: 20px;
      padding-right: 20px;
      right: 13%;
      left: auto;
    }

    &--03 {
      background: url(../images/bg_intro_03.png) no-repeat;
      width: 293px;
      height: 259px;
      padding-top: 45px;
      padding-left: 20px;
      padding-right: 20px;
      bottom: 0px;
      top: inherit;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    &--04 {
      background: url(../images/bg_intro_04.png) no-repeat;
      width: 293px;
      height: 259px;
      padding-top: 45px;
      padding-left: 20px;
      padding-right: 20px;
      bottom: 0px;
      top: inherit;
      left: inherit;
      right: 10%;
    }
  }
  // Contents ---------------------------------------------------------
  .main-content {
    background-color: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    // padding-top: 20px;
    margin-top: -2px;
    position: relative;
    z-index: 2;
    // padding-bottom: 15px;

    @include media-breakpoint-up(md) {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }

  .main-height {
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;

    &.main-wellcome {
      .section-content .container {
        .box-center-welcome01 {
          .btn--main {
            margin-bottom: 3vh;
          }
        }
      }
    }

    header,
    footer,
    .section-content {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .section-content {
      padding-bottom: 60px;
      padding-top: 90px;
      overflow: hidden;
      .container {
        padding-bottom: 20px;
        display: flex;
        height: 100%;
      }

      &--top {
        .container {
          position: relative;

          flex-wrap: wrap;

          > div {
            flex: 0 0 100%;
            max-width: 100%;
          }

          .row-top {
            align-self: flex-start;
          }

          .row--bot,
          .row-bot {
            align-self: flex-end;

            margin-top: 10px;
          }
          .row-bot {
            &.is-fixed {
              position: fixed;
              bottom: 60px;
              background: #fff;
              left: 0;
              right: 0;
              max-width: 640px;
              margin: 0 auto;
              padding-left: 20px;
              padding-right: 20px;
              z-index: 99;
              border-top: 1px solid #f2f0f0;
              border-bottom: 1px solid #f2f0f0;
              padding-top: 10px;
              padding-bottom: 10px;
            }
          }
        }
        &.is-content-fixed {
          padding-bottom: 300px;
        }
      }

      &--welcome {
        position: relative;

        &:after {
          height: 50%;
          background-color: rgba($color: #868384, $alpha: 0.12);
          bottom: 20px;
          left: 0;
          right: 0;
          content: '';
          position: absolute;
        }

        > * {
          position: relative;
          z-index: 2;
        }
      }

      &--profile {
        padding-top: 92px;

        .c-form.c-form--profile {
          margin-top: -50px;
          margin-bottom: 20px;
        }
      }
    }
  }

  .nav-ttl {
    background-color: white;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  // group-list
  // .group-list {
  //   margin-top: 50px;

  //   &__item {
  //     display: flex;
  //     align-items: center;
  //     border-top: 1px solid rgba($color: #707070, $alpha: 0.3);
  //     padding-top: 20px;
  //     padding-bottom: 30px;
  //     justify-content: space-between;
  //     padding-left: 20px;
  //     padding-right: 20px;
  //     position: relative;
  //     @include media-breakpoint-down(sm) {
  //       margin-left: -15px;
  //       margin-right: -15px;
  //     }

  //     .overlap {
  //       position: absolute;
  //       top: 0;
  //       left: 0;
  //       right: 0;
  //       bottom: 0;
  //       z-index: 6;
  //     }

  //     &--pending {
  //       * {
  //         color: #8f8c8d !important;
  //       }
  //     }
  //   }

  //   &__img {
  //     flex: 0 0 60px;
  //     max-width: 60px;
  //     padding-right: 15px;
  //   }

  //   &__info {
  //     // color: #51AAAA;
  //     flex: 1;

  //     h3 {
  //       font-size: rem(12);
  //       font-weight: bold;
  //       margin: 0;

  //       a {
  //         color: inherit;
  //       }
  //     }

  //     p {
  //       margin: 0;
  //       font-size: rem(11);

  //       a {
  //         color: inherit;
  //       }

  //       span {
  //         font-size: rem(9);
  //         display: inline-block;
  //         padding-right: 10px;
  //       }
  //     }
  //   }

  //   &__btn {
  //     flex: 0 0 200px;
  //     max-width: 200px;
  //     align-self: flex-start;
  //     @include media-breakpoint-down(sm) {
  //       flex: 0 0 150px;
  //       max-width: 150px;
  //     }
  //   }

  //   &--event {
  //     margin-top: 0px;
  //     width: 100%;

  //     .txt-date {
  //       font-size: rem(11);
  //       display: block;
  //       // color: #7C797A;
  //       color: #f06a0f;
  //     }

  //     .txt-number {
  //       font-size: rem(11);
  //       background: #f06a0f;
  //       color: #fff;
  //       padding: 3px 11px;
  //       border-radius: 5px;
  //       display: inline-block;
  //     }

  //     h4 {
  //       color: #51aaaa;
  //       font-size: rem(14);
  //       font-weight: bold;

  //       a {
  //         color: inherit;
  //       }

  //       span {
  //         font-weight: normal;
  //       }
  //     }

  //     .group-list {
  //       &__item {
  //         border: none;
  //         flex-wrap: wrap;
  //         box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  //         background-color: #fff;
  //         border-radius: 15px;
  //         margin-top: 5px;

  //         &--nobackground {
  //           box-shadow: 0 0px 0px rgba(0, 0, 0, 0.16);
  //           background-color: #fff;
  //           border-radius: 0px;
  //         }

  //         &:first-child {
  //           @include media-breakpoint-down(sm) {
  //             border-top-left-radius: 0;
  //             border-top-right-radius: 0;
  //           }
  //         }

  //         &.is-closed {
  //           background-color: #f2f1f1;
  //           color: #7c797a;
  //           opacity: 0.5;

  //           * {
  //             color: #7c797a !important;
  //             &.txt-number {
  //               color: white !important;
  //             }
  //           }
  //         }

  //         .link-share {
  //           // position: absolute;
  //           right: 26px;
  //           bottom: 10px;
  //           z-index: 7;
  //         }
  //       }

  //       &__des {
  //         flex: 0 0 100%;
  //         max-width: 100%;
  //         // padding-left: 60px;
  //         margin-top: 10px;
  //         font-size: rem(16);

  //         figure {
  //           img {
  //             width: 100%;
  //           }
  //         }

  //         p {
  //           // line-height: (24/12);
  //           // display: -webkit-box;
  //           // -webkit-line-clamp: 2;
  //           // -webkit-box-orient: vertical;
  //           // text-overflow: ellipsis;
  //           // overflow: hidden;
  //           // max-height: 60px;
  //           margin: 0;
  //           font-size: rem(15);
  //           text-align: justify;
  //           word-break: break-word;
  //           a {
  //             color: inherit;
  //           }

  //           @include media-breakpoint-down(md) {
  //             // max-height: 48px;
  //           }
  //         }
  //       }

  //       &__detail {
  //         font-size: rem(15);
  //         // padding-left: 60px;
  //         margin-top: 10px;
  //         width: 100%;
  //         .row {
  //           & + .row {
  //             margin-top: 5px;
  //           }
  //         }
  //         p {
  //           margin: 0;
  //           font-size: rem(15);
  //         }

  //         .col-3 {
  //           font-weight: bold;
  //           font-size: rem(14);
  //           flex: 0 0 30%;
  //           max-width: 30%;
  //         }
  //         .col-9 {
  //           flex: 0 0 70%;
  //           max-width: 70%;
  //         }

  //         .text-blue {
  //           font-size: rem(11);
  //           color: #51aaaa;
  //         }

  //         .row {
  //           & ~ .row {
  //             // margin-top: 10px
  //           }
  //         }
  //       }
  //     }
  //     &.group-list--event02 {
  //       .group-list__info {
  //         color: #7c797a;
  //       }
  //       .txt-date {
  //         color: #f06a0f;
  //       }
  //       .txt-number {
  //         background: #f06a0f;
  //         color: #fff;
  //         padding: 3px 11px;
  //         border-radius: 5px;
  //         display: inline-block;
  //       }
  //       .group-list__des {
  //         padding-left: 0;
  //         figure {
  //           margin-left: -20px;
  //           margin-right: -20px;
  //         }
  //         h4 {
  //           color: #51aaaa;
  //         }
  //       }
  //       .group-list__detail {
  //         padding-left: 0;
  //       }
  //     }
  //     &.group-list--event01 {
  //       box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  //       background-color: #fff;
  //       border-radius: 15px;
  //       padding: 10px;
  //       margin-left: -15px;
  //       margin-right: -15px;
  //       width: auto;
  //       p {
  //         line-height: (24/12);
  //         display: -webkit-box;
  //         -webkit-line-clamp: 3;
  //         -webkit-box-orient: vertical;
  //         text-overflow: ellipsis;
  //         overflow: hidden;
  //         max-height: (24/12) * 3rem;
  //       }
  //       & ~ .group-list--event01 {
  //         margin-top: 20px;
  //       }
  //       .group-list__item {
  //         box-shadow: 0 3px 6px rgb(0 0 0 / 0%);
  //         margin-left: 0;
  //         margin-right: 0;
  //         padding: 0;
  //       }
  //       .btn--solid.btn--solid03 {
  //         margin-top: 20px;
  //         margin-bottom: 20px;
  //         color: #656565;
  //         border: 1px solid #ababab;
  //       }
  //       .group-list__info p {
  //         a {
  //           text-decoration: underline;
  //         }
  //       }
  //       .price {
  //         font-size: 10px;
  //         font-weight: normal;
  //         margin: 4px 0 0 0;
  //       }
  //     }
  //   }

  //   &--mygroup {
  //     margin-top: 0;
  //     margin-bottom: 30px;

  //     h3 {
  //       font-size: rem(14);
  //       color: #f06a0f;
  //     }

  //     .group-list__item {
  //       &:first-child {
  //         border-top: none;
  //       }

  //       &:last-child {
  //         border-bottom: 1px solid rgba(112, 112, 112, 0.3);
  //       }
  //     }

  //     .group-list__info {
  //       p {
  //         span {
  //           font-size: rem(12);

  //           &.t-member {
  //             color: #da5039;
  //           }

  //           &.t-deal {
  //             color: #51aaaa;
  //           }
  //         }
  //       }
  //     }

  //     .group-list__btn {
  //       flex: 0 0 40px;
  //       max-width: 40px;
  //     }
  //   }
  // }

  // box-question
  .box-question {
    margin-top: 30px;

    > p {
      font-weight: normal;
    }
  }

  // welcome-group
  .welcome-group {
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    text-align: center;
    color: #f06a0f;
    position: relative;
    overflow: hidden;
    margin-top: 18px;
    padding-top: 13px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    &-top {
      position: relative;
      &:before {
        top: -91px;
        right: -60px;
        left: -60px;
        bottom: -60px;
        // height: 87%;
        position: absolute;
        content: '';
        background: rgb(134 131 132 / 0.25);
        border-radius: 50%;
      }

      > * {
        position: relative;
        z-index: 2;
      }
    }

    &__img {
      width: 203px;
      height: 203px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.5);
      margin: 0 auto;
      display: flex;
      align-items: center;
      position: relative;

      &:after {
        bottom: -20px;
        top: -20px;
        right: -20px;
        left: -20px;
        position: absolute;
        content: '';
        background: rgb(255 255 255 / 20%);
        border-radius: 50%;
      }
    }

    .c-image {
      width: 107px;
      height: 107px;
      max-width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      position: relative;
    }
    .c-image-edit {
      position: relative;
      width: 107px;
      margin: 0 auto;
      .icon-edit-image {
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;
      }
      .icon-whatapp {
        position: absolute;
        bottom: -11px;
        right: -11px;
        cursor: pointer;
      }
    }
    h4 {
      font-size: rem(14);
      font-weight: bold;
      margin: 20px auto;
    }

    p {
      font-size: rem(13);
      line-height: calc(24 / 13);
    }

    .link-circle {
      width: 56px;
      height: 56px;
      margin: 44px auto 18px auto;
      border-radius: 50%;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.welcome-group--white {
      background-color: white;
      box-shadow: none;
      margin: 10px 0 0;
      padding: 0;
      border-radius: 0;

      &:after,
      &:before {
        display: none;
      }

      .welcome-group__img {
        width: auto;
        height: auto;
        margin-bottom: 20px;
        background: none;

        &:after,
        &:before {
          display: none;
        }
      }
    }
    &.welcome-group--blue {
      h4,
      p {
        color: #079d9a;
      }
    }

    .welcome-number {
      ul {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: center;
        justify-content: center;

        li {
          padding: 0 20px;
          flex: 0 0 50%;
          &:first-child {
            position: relative;

            &:after {
              content: '';
              position: absolute;
              top: 50%;
              width: 2px;
              height: 24px;
              margin-top: -12px;
              background: #bce0fd;
              right: 0;
            }
          }
        }

        span {
          display: block;
          text-transform: uppercase;
          color: #f06a0f;
          font-size: rem(10);
          font-weight: bold;

          &:first-child {
            font-size: rem(20);
            color: #51aaaa;
            font-weight: normal;
          }
        }
      }
    }
  }

  // mainscreen
  .mainscreen {
    .c-form--search {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  // nav-profile
  .profile-avatar-bg {
    @include media-breakpoint-down(sm) {
      margin-left: -15px;
      margin-right: -15px;
      max-width: calc(100% + 30px) !important;
      flex: 0 0 calc(100% + 30px) !important;
    }

    img {
      width: 100%;
    }
  }

  .profile-avatar {
    .upload-image {
      background: white;
      margin-top: 0;
      > img {
        @include avatarSize;
      }
    }
  }

  .nav-profile {
    border-bottom: 1px solid #ccc;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    padding-top: 15px;

    ul {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        flex: 0 0 25%;
        max-width: 25%;
        flex-wrap: wrap;
        text-align: center;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: 2px;
          height: 24px;
          background-color: #f6b20c;
          top: 0;
          right: 0;
        }

        a {
          display: block;
          font-size: rem(10);
          color: #f06a0f;
        }

        i {
          margin: 0 auto;
        }

        span {
          display: block;
          margin-top: 10px;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  // noti-list
  .noti-list {
    &-item {
      font-size: 10px;
      border-bottom: 1px solid #f2f0f0;
      padding-bottom: 10px;
      padding-top: 10px;

      &.highlight {
        color: #f06a0f;
      }
    }
  }

  .nav-tab {
    display: flex;
    margin: 0 -3px;
    padding: 0;
    list-style: none;
    justify-content: center;
    li {
      max-width: 25%;
      flex: 0 0 25%;
      padding: 0 3px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        color: #7e7e7e;
        font-weight: bold;
        font-size: rem(10);
        border-radius: 20px;
        background-color: #fff;
        box-shadow: 0 3px 6px rgba($color: #000, $alpha: 0.16);

        &:hover {
          color: #fff;
          background-color: #07c3c3;
        }
      }
    }
  }

  .list-event {
    margin-top: 20px;
    margin-bottom: 20px;

    &__item {
      font-size: rem(12);
      margin-left: -15px;
      margin-right: -15px;
      padding: 10px 15px;
      border-bottom: 1px solid rgba($color: #868384, $alpha: 0.9);

      .row {
        align-items: center;
      }

      h4 {
        font-size: rem(12);
        position: relative;
        margin: 0;
        padding-right: 13px;

        a {
          color: inherit;
        }
      }

      .icon-new {
        position: absolute;
        right: 0;
        top: 0;
      }

      span {
        font-size: rem(10);
        padding-right: 8px;
      }

      .n-paid {
        color: #ff6903;
      }

      .paid {
        color: #51aaaa;
      }

      a.paid {
        &::before {
          content: '';
          display: inline-block;
          width: 20px;
          height: 20px;
          vertical-align: middle;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.363' height='14.422' viewBox='0 0 15.363 14.422'%3E%3Cpath id='Icon_feather-paperclip' data-name='Icon feather-paperclip' d='M17.16,8.176l-6.693,5.8a4.864,4.864,0,0,1-6.184,0,3.439,3.439,0,0,1,0-5.355l6.693-5.8a3.243,3.243,0,0,1,4.122,0,2.293,2.293,0,0,1,0,3.57l-6.7,5.8a1.621,1.621,0,0,1-2.061,0,1.146,1.146,0,0,1,0-1.785l6.184-5.349' transform='translate(-2.503 -1.409)' fill='none' stroke='%23079d9a' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A");
        }
      }

      a.paid-combined {
        color: #51aaaa;
        &::before {
          content: '';
          display: inline-block;
          width: 30px;
          height: 20px;
          vertical-align: middle;
          background-position: left, right;
          background-repeat: no-repeat, no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.363' height='14.422' viewBox='0 0 15.363 14.422'%3E%3Cpath id='Icon_feather-paperclip' data-name='Icon feather-paperclip' d='M17.16,8.176l-6.693,5.8a4.864,4.864,0,0,1-6.184,0,3.439,3.439,0,0,1,0-5.355l6.693-5.8a3.243,3.243,0,0,1,4.122,0,2.293,2.293,0,0,1,0,3.57l-6.7,5.8a1.621,1.621,0,0,1-2.061,0,1.146,1.146,0,0,1,0-1.785l6.184-5.349' transform='translate(-2.503 -1.409)' fill='none' stroke='%23079d9a' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A"),
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.363' height='14.422' viewBox='0 0 15.363 14.422'%3E%3Cpath id='Icon_feather-paperclip' data-name='Icon feather-paperclip' d='M17.16,8.176l-6.693,5.8a4.864,4.864,0,0,1-6.184,0,3.439,3.439,0,0,1,0-5.355l6.693-5.8a3.243,3.243,0,0,1,4.122,0,2.293,2.293,0,0,1,0,3.57l-6.7,5.8a1.621,1.621,0,0,1-2.061,0,1.146,1.146,0,0,1,0-1.785l6.184-5.349' transform='translate(-2.503 -1.409)' fill='none' stroke='%23079d9a' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E%0A");
        }
      }
    }
    &-note {
      font-size: 0.7rem;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: bold;
      .icon-link {
        width: 20px;
        height: 20px;
      }
      & ~ .list-event {
        margin-top: 10px;
        .list-event__item:first-child {
          border-top: 1px solid rgba($color: #868384, $alpha: 0.9);
        }
      }
    }
  }

  .price {
    font-size: rem(16);
    font-weight: bold;
    margin-bottom: 20px;

    p {
      margin: 0;
    }

    span {
      display: inline-block;
      padding-left: 20px;

      &.t-not-yet {
        color: #51aaaa;
      }

      &.t-done {
        color: #51aaaa;
      }
    }
    p.t-done {
      color: #51aaaa;
      font-weight: bold;
    }
    &.havebg {
      background-color: #ecfbfb;
      margin-left: -20px;
      margin-right: -20px;
      padding: 20px;
      font-weight: normal;
      span {
        padding-left: 0;
      }
    }
  }

  // news-group
  .news-group {
    background: rgb(33 205 202 / 8%);
    margin-top: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: -20px;
    .row {
      margin: 0 -4px;
    }

    .col-6 {
      padding: 0 8px;
      margin-bottom: 20px;
    }

    &__item {
      background: #fff;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      overflow: hidden;
      height: 100%;
      border: 1px solid #51aaaa;
      display: flex;
      // flex-direction: column;
      flex-wrap: wrap;
      > * {
        max-width: 100%;
        width: 100%;
      }
      &.is-closed {
        background: rgb(124 121 122 / 0.32);
        border: 1px solid #7c797a;
        * {
          color: #7c797a !important;
        }

        i {
          filter: grayscale(100%);
        }
      }
    }

    figure {
      margin: 0;
      padding: 0;
      align-self: flex-end;
      img {
        width: 100%;
      }
    }

    .text-info {
      font-size: rem(11);
      padding: 12px;
      align-self: flex-end;
      p {
        line-height: calc(24 / 12);
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: 48px;
        margin: 0;
        color: #7c797a;
      }
    }

    &__ttl {
      position: relative;
      padding: 12px;
      padding-bottom: 0;
      margin-bottom: 10px;
      align-self: flex-start;
      h3 {
        font-size: rem(13);
        color: #51aaaa;
        margin: 0;
        font-weight: bold;
      }

      p {
        margin: 0;
        font-size: rem(12);
        color: #51aaaa;
      }

      span.txt-number {
        position: absolute;
        right: 12px;
        bottom: 0;
        color: #51aaaa;
        font-size: rem(11);
      }
    }
  }

  // /list-user
  .list-user {
    font-size: rem(13);
    margin-top: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: -15px;
    margin-right: -15px;
    background: rgba(33, 205, 202, 0.08);
    margin-bottom: -20px;
    &.list-user--havelink {
      a {
        color: #079d9a;
        &:hover {
          color: #383e3e;
        }
      }
    }
    &__item {
      border-bottom: 1px solid rgba($color: #079d9a, $alpha: 0.3);
      padding: 10px 15px;

      .row {
        align-items: center;
      }

      * {
        color: #079d9a;

        a {
          color: white;

          &.link-share {
            color: #7e7e7e;
            text-decoration: underline;
          }
        }
      }
    }
  }

  // modal

  .modal.fade.show.modal-nested {
    z-index: 1051;
    background: rgb(0 0 0 / 0.6);
    border-radius: 13px;
  }

  .modal-content {
    &.modal-content-height {
      max-height: 70vh;
      overflow: auto;
    }
    &.modal-content--yellow {
      background-color: rgba($color: #fff, $alpha: 0.95);

      .form-control--gray {
        background: #f2f1f1;
        border-radius: 30px;
        border: none;
        margin-top: 14px;
      }
      .detail-list-news__item {
        p {
          margin: 0;
        }
      }
      .form-control--80 {
        height: 80px;
        font-size: 12px;
        color: #f06a0f;
      }
      .form-group {
        margin: 30px 0;
      }

      .welcome-group.welcome-group--white {
        background: none;

        &::before {
          display: none;
        }
      }
    }

    .form-control--org {
      color: #ff6903 !important;

      &::-webkit-input-placeholder {
        /* Edge */
        color: #ff6903 !important;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #ff6903 !important;
      }

      &::placeholder {
        color: #ff6903 !important;
      }
    }

    &--list {
      overflow: auto;
      max-height: 70vh;
    }

    .modal-content-bot {
      padding-left: 20px;
      padding-right: 20px;
    }

    &--upload {
      padding: 20px;
      // width: 85%;
      margin: 0 auto;

      @include media-breakpoint-up(sm) {
        left: -7px;
      }

      .c-form.c-form--line {
        .form-group {
          margin-left: auto;
          margin-right: auto;

          label {
            color: #f06a0f !important;
            font-size: rem(13);
          }

          .form-control {
            text-align: left;
          }
        }
      }
    }

    .detail-list-news {
      color: #7c797a;
    }

    .price {
      font-weight: normal;
    }

    .wrapform {
      width: 190px;
      margin: 0 auto;
    }
  }

  .modal-group {
    .modal-content {
      background: none;

      .container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .modal-select {
    display: block !important;
    z-index: -1;

    &.fade.show {
      z-index: 1051;
    }
  }
  .modalImg {
    .modal-content {
      padding: 0;
    }
  }
  .bootstrap-select > .dropdown-toggle {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
  }

  .bootstrap-select.show {
    button ~ .dropdown-menu.show {
      position: absolute !important;
    }
  }

  .bootstrap-select.checked .dropdown-toggle .filter-option-inner-inner {
    color: #51aaaa;
  }
  // form
  .c-form {
    .form-group {
      &--select {
        label {
          font-size: rem(14);
          margin: 0 0 10px 0;
        }

        .bootstrap-select > .dropdown-toggle {
          height: 34px;
          border-radius: 0;

          .filter-option-inner-inner {
            height: 34px;
            display: flex;
            align-items: center;
          }

          &:focus {
            outline: none !important;
          }

          &:hover {
            background-color: #fff;
          }
        }
      }
      .sys {
        color: #f06a0f;
      }
    }

    &.c-form--line {
      margin-top: 20px;
      margin-bottom: 20px;

      .form-group {
        // border-bottom: 1px solid #7C797A;

        label {
          font-size: rem(12);
        }
      }

      .have-icon {
        .form-control {
          padding-right: 20px;
        }

        > i {
          position: absolute;
          right: 9px;
          top: 37px;
          @include media-breakpoint-down(sm) {
            top: 28px;
          }
        }
      }

      .col-9 {
        .form-group {
          .form-control {
            text-align: left;
          }
        }
      }
    }
  }

  .list-item-add {
    padding: 20px;
    border-bottom: 1px solid rgba($color: #868384, $alpha: 0.5);

    figure {
      margin: 0;

      .img-plugs {
        display: block;
        border: 1px solid #868384;
        height: 60px;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21'%3E%3Cg id='Group_3463' data-name='Group 3463' transform='translate(0.5 0.5)' opacity='0.8'%3E%3Cline id='Line_33' data-name='Line 33' y2='20' transform='translate(10)' fill='none' stroke='%23707070' stroke-linecap='round' stroke-width='1'/%3E%3Cline id='Line_34' data-name='Line 34' y2='20' transform='translate(20 10) rotate(90)' fill='none' stroke='%23707070' stroke-linecap='round' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");
      }

      img {
        width: 100%;
        border: 1px solid #868384;
      }
    }

    .c-txt-12 {
      color: #f06a0f;
      font-size: rem(12);
      font-weight: bold;
    }

    p {
      color: #68a4c4;
      font-size: rem(11);
    }

    .group-btn-link {
      color: #f06a0f;
    }
  }

  .box-intro {
    height: 250px;
  }

  // detail-list-news
  .detail-list-news {
    font-size: rem(12);
    color: #51aaaa;
    &--border {
      padding-top: 20px;
    }
    &__item {
      border-bottom: 1px solid rgba(#079d9a, 0.3);
      margin-left: -15px;
      margin-right: -15px;
      padding: 15px;
      table {
        width: 100%;
        td {
          padding-top: 6px;
          padding-bottom: 6px;
          vertical-align: top;
        }
        h3 {
          display: flex;
        }
        .img-thb {
          margin-right: 10px;
          width: 91px;
          background-color: #ccc;
        }
      }
      img {
        // width: 100%;
        width: 100px;
        height: auto;
      }

      h3 {
        font-size: rem(12);
        margin: 0;
        font-weight: bold;
        input {
          font-weight: bold;
        }
        a {
          color: inherit;
        }
      }
      p {
        position: relative;
        &.text-sefl {
          margin: 0;
          .t-before-ipt {
            position: absolute;
            left: 0;
            top: 5px;
            & ~ .form-control--edit {
              padding-left: 50px;
            }
          }
        }
      }

      &:last-child {
        border-bottom: none;
      }
      .btn-action {
        text-align: right;
        a {
          display: inline-block;
          font-size: 0.75rem;
          color: #7c797a;
          vertical-align: middle;
          margin-left: 8px;
        }
      }

      &--gray {
        p {
          margin-bottom: 0;
        }
        .btn-action {
          filter: grayscale(100%);
        }
      }
      &--background {
        background-color: #ecfbfb;
        padding: 10px;
        z-index: 3;
        .c-form {
          color: #179d9a;
          label {
            margin: 0;
          }

          .form-control {
            border: 1px solid #179d9a;
            color: #179d9a;
          }
          .row {
            & ~ .row {
              margin-top: 10px;
            }
            .col-3 {
              align-self: center;
              margin-bottom: 0;
            }
            &.row-price {
              a {
                color: #179d9a;
                display: inline-block;
              }
              .col-10 {
                padding-right: 130px;
                position: relative;
                @include media-breakpoint-down(sm) {
                  padding-right: 120px;
                }
              }
              .btn-action {
                position: absolute;
                right: 8px;
                top: 9px;
              }
            }
          }
        }
        &.-gray {
          background-color: rgb(134 131 132 / 5%);
          padding-top: 30px;
          padding-bottom: 30px;
          .c-form {
            filter: grayscale(100%);
          }
        }
      }
    }

    &__des {
      color: #f06a0f;
      margin-top: 20px;

      p {
        margin: 0;
      }
    }

    &.detail-list-news--org {
      background: rgba($color: #21cdca, $alpha: 0.09);
      color: #939091;
      padding: 20px;
      border-radius: 15px;
      font-size: rem(11);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      margin-bottom: 20px;
      .form-control {
        border-color: #21cdca;
      }
      @include media-breakpoint-down(sm) {
        margin-left: -15px;
        margin-right: -15px;
      }

      &.is-closed {
        background-color: #f2f1f1;
        color: #7c797a;

        * {
          color: #7c797a !important;
        }
      }
    }
  }

  .btn--main {
    flex-wrap: wrap;

    & ~ p {
      margin-top: 10px;
    }

    span {
      max-width: 100%;
      flex: 0 0 100%;

      &:first-child {
        font-weight: bold;
      }
    }
  }

  .btn--gray {
    & ~ p {
      margin-top: 10px;
    }
  }

  .nav-amount {
    background: rgba(#21cdca, 0.1);
    padding: 20px;
    margin-left: -15px;
    margin-right: -15px;
    .c-txt-org {
      color: #079d9a;
    }
    .note {
      font-size: rem(10);
      color: #079d9a;
    }
    & ~ .c-form {
      margin-top: 30px;

      textarea {
        height: 79px;
        margin-top: 10px;
        margin-bottom: 20px;
        resize: none;
        overflow: auto;

        &.form-control.form-control--gray.form-control--outline {
          background: none;
          border: 1px solid #ccc;
        }
      }
    }
  }

  .sum {
    color: #7c797a;
    font-size: rem(10);
    font-weight: bold;

    p {
      margin: 0;
    }

    .c-txt-org {
      font-size: rem(15);
    }

    &-text {
      font-weight: bold;
      font-size: rem(15);
    }
  }

  .quantity {
    display: flex;
    align-items: center;
    &-right {
      justify-content: flex-end;
    }
    span {
      &.icon-plus,
      &.icon-min {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        border: 1px solid rgb(112 112 112 / 0.6);
        font-size: 0;
        background-size: 13px;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
      }

      &.icon-plus {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='11' viewBox='0 0 11 11'%3E%3Cg id='Group_3271' data-name='Group 3271' transform='translate(1.5 1.5)' opacity='0.8'%3E%3Cline id='Line_33' data-name='Line 33' y2='8' transform='translate(4)' fill='none' stroke='%23707070' stroke-linecap='round' stroke-width='3'/%3E%3Cline id='Line_34' data-name='Line 34' y2='8' transform='translate(8 4) rotate(90)' fill='none' stroke='%23707070' stroke-linecap='round' stroke-width='3'/%3E%3C/g%3E%3C/svg%3E%0A");
      }

      &.icon-min {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='3' viewBox='0 0 11 3'%3E%3Cg id='Group_3271' data-name='Group 3271' transform='translate(1.5 1.5)' opacity='0.8'%3E%3Cline id='Line_34' data-name='Line 34' y2='8' transform='translate(8 0) rotate(90)' fill='none' stroke='%23707070' stroke-linecap='round' stroke-width='3'/%3E%3C/g%3E%3C/svg%3E%0A");
      }

      &.icon-input {
        .form-control {
          font-size: rem(16);
          font-weight: bold;
          color: #51aaaa;
          background: none;
          border: none;
          border-radius: 0;
          width: 30px;
          text-align: center;
          padding: 0;
          &::placeholder {
            color: #51aaaa;
          }
          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #51aaaa;
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            color: #51aaaa;
          }
          &:-ms-input-placeholder {
            /* IE 10+ */
            color: #51aaaa;
          }
          &:-moz-placeholder {
            /* Firefox 18- */
            color: #51aaaa;
          }
        }
      }
    }

    &-text {
      font-weight: bold;
      font-size: rem(15);
      color: #f06a0f;
    }
  }

  .btn-link {
    margin-top: 20px;
    text-align: right;
    a {
      display: inline-block;
      font-size: rem(12);
      color: #7c797a;
      vertical-align: middle;
      &:hover {
        color: #0badad;
      }
      & ~ a {
        &:before {
          content: '|';
          margin: 0 10px;
          display: inline-block;
          color: #7c797a !important;
        }
      }
    }
  }
  input.form-control.form-control--gray.form-control--radius {
    background-color: #f2f1f1;
  }

  .datepicker-days {
    font-size: 14px;
  }

  .timepicker {
    font-size: 14px;
  }

  .bootstrap-datetimepicker-widget {
    .btn {
      position: relative;
      border-radius: 4px;

      .fa {
        width: auto;
        height: auto;
        background: none !important;
        color: #f06a0f !important;
      }
    }
  }
  .col-6-date {
    .bootstrap-datetimepicker-widget {
      @include media-breakpoint-down(md) {
        left: calc(-100% - 6px) !important;
      }
    }
  }
  .form-group-time {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .form-group-time__item {
    width: 45%;
    border: 1px solid #ced4da;
  }
  .form-group-time__item select.form-control,
  .form-group-time__item select.select2-search__field,
  .form-group-time__item select.select2-selection--single,
  .form-group-time__item select.select2-selection--multiple {
    -webkit-box-shadow: 0 0px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.16);
    height: 30px !important;
  }
  .bootstrap-datetimepicker-widget {
    width: calc(100% + 6px) !important;

    @include media-breakpoint-down(md) {
      width: calc(200% + 6px) !important;
    }
    border: 1px solid #ccc;
    // border-top: magenta;
    margin-left: -3px;
    margin-right: -3px;
    margin-top: -4px;
    z-index: 9;
    background: #fff;
    padding: 9px;
  }

  .box-time {
    .bootstrap-datetimepicker-widget {
      width: 80px !important;
    }
  }

  .btn-primary {
    background: #0badad !important;
    border-radius: 3px;
    color: #fff;
    border: none;
  }

  .main-wellcome-img {
    max-width: 375px;
    margin: 0 auto;

    img {
      height: 100%;
    }
  }

  select.form-control {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    height: 25px !important;
    border-radius: 0px;
    font-size: 11px;
    color: #7e7e7e;
    position: relative;
    width: 100%;
    text-align: center;
    border: none;
    white-space: nowrap;
    padding: 0 11px;
    line-height: 1.4;
  }
  .image--screenshot {
    width: 80%;
    margin: 0 auto;
    img {
      max-height: 58vh;
    }
  }
  .form-control {
    &--edit {
      height: 23px;
      font-size: rem(12);
      color: #0badad;
      border: none;
      padding: 0;
      border-bottom: 1px solid #ccc;
      border-radius: 0;
      &:focus {
        color: #0badad;
      }
    }
  }
  .mygroup {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    &.c-form {
      .form-group {
        label {
          color: #ff6903;
          font-size: rem(10);
        }
        .form-control {
          height: 30px !important;
          border: none;
          box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
          border-radius: 0;
          font-size: rem(12);
        }
      }
    }
  }
  .box-setting {
    background: rgb(21 205 202 / 15%);
    padding: 20px;
    border-radius: 10px;
    .c-form {
      .form-group {
        label {
          margin-bottom: 5px;
        }
        select.form-control {
          height: 40px !important;
          border: 1px solid #079d9a;
          font-size: rem(14);
          color: #079d9a;
        }
      }
    }
  }
  .box-welcome {
    picture {
      display: block;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 30px;
    }
    h4 {
      font-size: rem(18);
      color: #079d9a;
      font-weight: bold;
      margin: 0 0 20px 0;
    }
    p {
      font-size: rem(14);
      color: #079d9a;
      margin: 0;
      &.c-txt-18 {
        font-size: rem(17);
      }
      a {
        color: #f06a0f;
        text-decoration: underline;
        &:hover {
          color: #079d9a;
        }
      }
      .txt-hightlight {
        color: #f06a0f;
      }
    }
  }
  .payment {
    .c-form {
      .havebg {
        background-color: #f2f0f0;

        .text-small {
          font-size: rem(10);
        }
      }
    }
  }
  .form-code {
    display: none;
    &.active {
      display: block;
    }
  }
  @include media-breakpoint-down(md) {
    @media all and (orientation: landscape) {
      .main-height .section-content--top.is-content-fixed {
        padding-bottom: 60px;
      }
      .main-height .section-content--top .container .row-bot.is-fixed {
        position: static;
      }
    }
  }

  .btn-small {
    width: 80%;
    margin: 0 auto;
    .btn {
      height: 30px;
    }
  }
  .box-delivery {
    background: #eaeaea;
    margin-left: -20px;
    margin-right: -20px;
    padding: 5px 20px;
    margin-top: 14px;
    font-size: 11px;

    .slider-toggle {
      padding: 0;
      margin: 0;
      background: #fff;
      padding: 5px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        display: block;
        font-size: 10px;
        letter-spacing: -0.05em;
        padding-right: 4px;
      }
      .slider {
        &:after {
          content: 'Off';
          font-size: 12px;
          position: absolute;
          right: 8px;
          top: 2px;
          color: #fff;
        }
      }
      input:checked + .slider {
        &:after {
          content: 'On';
          left: 8px;
          right: 0;
        }
      }
    }
    label {
      display: block;
      margin: 0;
    }
    .form-control {
      width: 86%;
    }
    .ipt-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .welcome-other {
    .main-img {
      margin-left: -15px;
      margin-right: -15px;
      text-align: center;
    }
    .txt-org {
      font-weight: normal;
    }
    .btn--solid {
      &:hover {
        .icon-google.blue {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.243' height='17.617' viewBox='0 0 17.243 17.617'%3E%3Cpath id='Path_349' data-name='Path 349' d='M122.8,228.084l-2.473,2.473c-.056-.047-.133-.115-.213-.181a5.012,5.012,0,0,0-6.262-.07,5.36,5.36,0,1,0,6.458,8.556,3.93,3.93,0,0,0,1.39-2.257c.013-.053.015-.11.026-.2h-4.776v-3.418h8.3c.045.442.112.889.131,1.338a9.131,9.131,0,0,1-1.358,5.477,7.572,7.572,0,0,1-4.924,3.38,8.747,8.747,0,0,1-6.622-.991,8.55,8.55,0,0,1-4.253-6.485,8.775,8.775,0,0,1,14.331-7.846C122.642,227.938,122.727,228.019,122.8,228.084Z' transform='translate(-108.146 -225.816)' fill='%23fff'/%3E%3C/svg%3E%0A");
        }
        .icon-phone.blue {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.359' viewBox='0 0 14 24.359'%3E%3Cpath id='Icon_awesome-mobile-alt' data-name='Icon awesome-mobile-alt' d='M11.9,0H2.1A2.2,2.2,0,0,0,0,2.284V22.075a2.2,2.2,0,0,0,2.1,2.284h9.8A2.2,2.2,0,0,0,14,22.075V2.284A2.2,2.2,0,0,0,11.9,0ZM7,22.836a1.528,1.528,0,0,1,0-3.045,1.528,1.528,0,0,1,0,3.045ZM11.9,17.7a.551.551,0,0,1-.525.571H2.625A.551.551,0,0,1,2.1,17.7V2.855a.551.551,0,0,1,.525-.571h8.75a.551.551,0,0,1,.525.571Z' fill='%23fff'/%3E%3C/svg%3E%0A");
        }
        .icon-fb.blue {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.5' height='18.371' viewBox='0 0 10.5 18.371'%3E%3Cpath id='Path_161' data-name='Path 161' d='M265.515,271.849h-2.16c-.917,0-.855.083-.83-.846.024-.908.052-1.817.063-2.725,0-.3.108-.417.418-.41.759.017,1.518,0,2.277.01.225,0,.295-.071.293-.294-.007-.823-.013-1.647.012-2.469a4.907,4.907,0,0,1,.549-2.123,4.081,4.081,0,0,1,3.622-2.211c.957-.064,1.923-.007,2.885-.006.258,0,.382.086.369.38-.043.972-.067,1.944-.083,2.917-.005.316-.122.429-.444.416-.544-.023-1.091-.02-1.635,0a1.58,1.58,0,0,0-.737.164c-.207.128-.461.377-.473.587-.053.86-.022,1.725-.022,2.621.108.006.221.017.334.019.866.017,1.731.038,2.6.045.279,0,.388.1.347.387-.157,1.088-.307,2.178-.456,3.268-.03.223-.172.267-.364.266-.695,0-1.39,0-2.084,0h-.377c-.007.149-.017.273-.018.4q-.035,3.175-.068,6.35c-.006.521-.012.526-.517.526h-2.918c-.451,0-.461-.008-.468-.472q-.048-3.207-.093-6.414C265.53,272.118,265.522,272,265.515,271.849Z' transform='translate(-262.514 -260.749)' fill='%23fff'/%3E%3C/svg%3E%0A");
        }
      }
    }
  }
  .sub-item {
    background: #fff;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 20px;
    margin-top: 10px;
    border-left: 3px solid;
    display: none;
    &.is-active {
      display: block;
    }
    .sub-item-div-last {
      border-top: 1px solid #ddd;
      margin-top: 10px;
      padding-top: 10px;
    }
    &.c-form {
      .row {
        margin-top: 10px;
      }
      .col-6,
      .col-12 {
        padding-left: 20px;
        .sub-txt {
          position: absolute;
          left: 3px;
          font-size: 1rem;
          top: 8px;
          color: #7c797a;
        }
        a {
          color: #333;
          display: block;
          padding-top: 11px;
        }
      }
      .form-control {
        font-size: 0.8rem;
        &-bg {
          background-color: #ecfbfb;
          border-radius: 0;
          border: none;
        }
        &-line {
          border-radius: 0;
          border: none;
          border-bottom: 1px solid #ccc;
          padding-left: 0;
        }
      }
      .slider-toogle {
        background: none;
        padding: 0;
        margin: 0;
      }
      .btn-action-01 {
        text-align: right;
        filter: grayscale(100%);
        a {
          display: inline-block;
          margin-right: 10px;
        }
        .btn-add-01 {
          opacity: 0.2;
        }
      }
    }
  }
  .detail-list-news-item {
    display: none;
    &.is-active {
      display: block;
    }
    & + .detail-list-news-item {
      margin-top: 20px;
    }
  }
  .c-form.c-form-select-list {
    .form-item {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .form-control {
      height: 35px !important;
      width: 90%;
    }
  }
  .btn-purchase {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    background: #ff0404;
    border-radius: 10px;
    padding: 10px;
    font-size: rem(14);
    height: 45px;
    small {
      display: block;
    }
    &-02 {
      background: #ff6903;
    }
    &-03 {
      background: #15cdca;
    }
    &-04 {
      background: #079d9a;
    }
    &-05 {
      background: #f39c12;
    }
  }
  .paragraph-pre-wrap {
    white-space: pre-line;
  }
  .react-datepicker-wrapper {
    input {
      width: 100% !important;
      border: none;
      padding: 0.375rem 0.75rem !important;
      border-bottom: 1px solid #ccc;
      color: #495057 !important;

      &:focus {
        border: none;
        outline: none;
      }
    }
  }
  .add-delivery-modal {
    .input-date-time input {
      width: 100% !important;
      border: none !important;
      padding: 0.375rem 0.75rem !important;
      border: 1px solid #ccc !important;
      color: #495057 !important;
      border-radius: 10px;
      &:focus {
        border: 1px solid rgb(99, 98, 98) !important;
        outline: none !important;
      }
    }
    select {
      width: 100%;
      padding: 0.375rem 0.75rem !important;
      border: 1px solid #ccc !important;
      color: #495057 !important;
      border-radius: 10px;
      &:focus {
        border: 1px solid rgb(99, 98, 98) !important;
        outline: none !important;
      }
    }
  }
  .date-picker-for-shop {
    display: flex;
    align-items: center;
    padding: 0 8px;
    border-radius: 3px;
    box-shadow: rgb(0 0 0 / 20%) 0px 0px 20px;
    cursor: pointer;
    input {
      border: none;
    }
  }
}
