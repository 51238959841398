@import './colors.scss';

#digistall {
  .get-shop-opening {
    .shop-item {
      border-radius: 12px;
      border: none;
      transition: all 0.25s ease;
      background: $bg-white;
      box-shadow: 0 2px 8px $shadow-light;
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      min-height: 380px;
      margin-bottom: 1rem;

      &:hover {
        box-shadow: 0 12px 24px $shadow-dark;
        transform: translateY(-4px);
      }

      a {
        margin-right: 1rem;
        color: $text-light;
        transition: all 0.2s ease-in-out;
      }

      img {
        object-fit: cover;
        width: 100%;
      }

      .wrap-banner {
        width: 100%;
        min-height: 200px;

        /* No banner image placeholder */
        .slider__no-image {
          // background-image: url('/path/to/no-banner-placeholder.svg') !important;
          background-size: cover !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
          min-height: 200px !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          color: #94a3b8 !important;
          font-size: 16px !important;
          background-color: #536980 !important;
        }
      }

      .image-gallery-swipe,
      .image-gallery-slides,
      .image-gallery-slide,
      .image-gallery-content,
      .image-gallery-slide-wrapper,
      .slider__no-image,
      .slider__container,
      .slider__single {
        height: 100%;

        .image-gallery {
          height: 100%;
        }

        img {
          height: 100%;
          object-fit: cover;
        }
      }

      .carousel-item,
      .wrap-banner {
        img {
          height: 250px;
          object-fit: cover;
          transition: transform 0.5s ease;
        }

        button {
          display: none;
        }

        position: relative;
        border-radius: 12px 0 0 0;
        overflow: hidden;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 60%;
          background: linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent);
          opacity: 0;
          transition: opacity 0.3s ease;
        }

        &:hover::after {
          opacity: 1;
        }
      }

      .shop-content {
        font-size: 16px;
        padding: 1.5rem;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 20px;

        .shop-info {
          flex: 1;

          .shop-name {
            font-weight: 600;
            font-size: 1.25rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0.75rem;
            color: #2c3e50;
            text-decoration: none;
            transition: color 0.2s ease;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;

            &:hover {
              color: $secondary-color;
            }

            a {
              font-size: 1.125rem;
              font-weight: 600;
              color: #2c3e50;
              text-decoration: none;
              line-height: 1.4;

              &:hover {
                color: $secondary-color;
              }
            }

            .badge {
              margin-left: 0.5rem;
              padding: 0.25rem 0.5rem;
              font-size: 0.75rem;
              border-radius: 12px;
              background: $secondary-color;
              color: white;
              font-weight: 500;
            }
          }

          .shop-description {
            overflow-wrap: break-word;
            font-size: 0.75rem;
            line-height: 1.5;
            color: #64748b;
          }
        }

        .shop-footer {
          padding-top: 0.5rem;
          margin-top: 0.5rem;
          border-top: 1px solid #e5e7eb;
          display: flex;
          flex-direction: column;

          .shop-social-links {
            display: flex;
            gap: 0.75rem;
            align-items: center;

            .social-link {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 32px;
              height: 34px;
              border-radius: 8px;
              background: $bg-light;
              color: $text-secondary;
              transition: all 0.2s ease;
              margin: 0;

              &:hover {
                transform: translateY(-2px);
                background: $bg-white;
                box-shadow: 0 2px 8px $shadow-medium;
              }

              &.disabled {
                background: $bg-light;
                color: $text-secondary !important;

                &:hover {
                  transform: none;
                  box-shadow: none;
                }
              }

              svg {
                width: 16px;
                height: 16px;
              }

              &.whats-app {
                color: $social-whatsapp;
                &:hover {
                  background: rgba($social-whatsapp, 0.1);
                }
              }

              &.instagram {
                color: $social-instagram;
                &:hover {
                  background: rgba($social-instagram, 0.1);
                }
              }

              &.facebook {
                color: $social-facebook;
                &:hover {
                  background: rgba($social-facebook, 0.1);
                }
              }

              &.domain-link {
                color: $social-domain;
                &:hover {
                  background: rgba($social-domain, 0.1);
                }
              }
            }
          }

          .author-created {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 16px;
            width: 100%;
            justify-content: space-between;
          }

          .author {
            text-align: right;
            display: flex;
            align-items: center;
            font-size: 0.9rem;
            color: $text-secondary;
            text-align: right;

            small {
              display: block;
              color: $text-muted;
              font-size: 0.75rem;
              line-height: 1.5;
            }

            .author-avatar {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              margin-right: 0.5rem;
            }
          }

          .created-date {
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            small {
              color: $text-muted;
              font-size: 0.75rem;
              line-height: 1.5;

              &:first-child {
                color: $text-secondary;
                font-weight: 500;
              }
            }
          }
        }

        .badge {
          position: absolute;
          top: 12px;
          right: 12px;
          padding: 6px 12px;
          border-radius: 20px;
          font-weight: 500;
          font-size: 0.8rem;
          background: $secondary-color;
          box-shadow: 0 2px 4px rgba($brand-secondary, 0.3);
        }
      }
    }
  }
}
