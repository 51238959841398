@import '../../assets/css/base/functions';
@import '../../assets/css/base/variables';
@import '../../assets/css/base/mixins';

@import '../../styles/breakpoints.scss';
@import '../../styles/font-size-utility.scss';
@import './styles/modal-schedule-delivery.scss';

$orange: #f06a0f;

.events-page {
  padding: 1rem;

  &__title {
    color: $orange;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  .events-page__create-wrapper {
    .events-page__create-button {
      color: $orange;
    }

    @extend %text-responsive;
  }
}

.event-filter-status {
  select {
    color: #495057;
    width: 100%;
    font-size: 0.75rem;

    &:focus {
      border: 1px solid $orange !important;
      outline: none;
    }

    /* Custom arrow */
    appearance: none;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"%3E%3Cpath d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" fill="%23fd7e14" /%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 12px;
    padding-right: 25px;
  }
}

.event-search {
  input {
    font-size: 16px;

    &:focus {
      border: 1px solid $orange !important;
    }
  }
}

.event-view {
  .btn-view {
    background-color: white;
    // color: white;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 0.75rem;
    cursor: pointer;

    // $orange
    &.active {
      background-color: $orange;
      border: 1px solid $orange !important;
      color: white;
    }

    &:hover {
      background-color: $orange;
      color: white;
      border: 1px solid $orange !important;
    }
  }
}

.event-list {
  font-family: $font-family-other;

  .header-info {
    color: gray;
  }

  .group-list {
    margin-top: 50px;

    &__item {
      display: flex;
      align-items: start;
      border-top: 1px solid rgba($color: #707070, $alpha: 0.3);
      padding-top: 20px;
      padding-bottom: 30px;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;
      position: relative;
      margin-bottom: 1rem;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      background-color: #fff;
      border-radius: 15px;
      gap: 1rem;
      // width: 500px;

      @include media-breakpoint-down(sm) {
        margin-left: -15px;
        margin-right: -15px;
      }

      .overlap {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 6;
      }

      &--pending {
        * {
          color: #8f8c8d !important;
        }
      }

      .txt-number {
        font-size: 12px;
      }
    }

    &__img {
      flex: 0 0 60px;
      max-width: 60px;
      padding-right: 15px;
    }

    &__info {
      // color: #51AAAA;
      flex: 1;
      word-break: break-word;
      padding-left: 1rem;

      h3 {
        font-size: rem(12);
        font-weight: 500;
        margin: 0;

        a {
          color: inherit;
        }
      }

      p {
        margin: 0;
        font-size: rem(11);

        a {
          color: inherit;
        }

        span {
          font-size: rem(9);
          display: inline-block;
          padding-right: 10px;
        }
      }
    }

    &__btn {
      display: flex;
      flex-direction: column;
      width: fit-content;
      margin-left: auto;
      font-size: 0.6875rem;

      .txt-date {
        color: $orange;
      }

      .txt-number {
        background: $orange;
        color: #fff;
        padding: 3px 11px;
        border-radius: 5px;
        display: inline-block;
        width: fit-content;
        margin-left: auto;
      }

      @include media-breakpoint-down(sm) {
        flex: 0 0 150px;
        max-width: 150px;
      }
    }

    &--event {
      // margin-top: 0px;
      width: 100%;

      .txt-date {
        font-size: rem(11);
        display: block;
        // color: #7C797A;
        color: $orange;
      }

      .txt-number {
        font-size: rem(11);
        background: $orange;
        color: #fff;
        padding: 3px 11px;
        border-radius: 5px;
        display: inline-block;
      }

      h4,
      .delivery-info {
        color: #51aaaa;
        font-size: rem(14);
        font-weight: bold;

        a {
          color: inherit;
        }

        span {
          font-weight: normal;
        }
      }

      .group-list {
        &__item {
          border: none;
          flex-wrap: wrap;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
          background-color: #fff;
          border-radius: 15px;
          margin-bottom: 1rem;

          .text-right {
            font-size: 0.7rem;
            color: rgb(7, 157, 154);
            // line-height: 1;
          }

          &--nobackground {
            box-shadow: 0 0px 0px rgba(0, 0, 0, 0.16);
            background-color: #fff;
            border-radius: 0px;
          }

          &:first-child {
            @include media-breakpoint-down(sm) {
              border-top-left-radius: 0;
              border-top-right-radius: 0;
            }
          }

          &.is-closed {
            background-color: #f2f1f1;
            color: #7c797a;
            opacity: 0.5;

            h3,
            h4,
            svg,
            .text-right,
            .txt-date {
              color: #7c797a !important;
            }

            .btn-purchase {
              color: #e4e4e4 !important;

              &.txt-number {
                color: white !important;
              }
            }
          }

          .link-share {
            // position: absolute;
            right: 26px;
            bottom: 10px;
            z-index: 7;
          }
        }

        &__des {
          flex: 0 0 100%;
          max-width: 100%;
          // padding-left: 60px;
          margin-top: 10px;
          font-size: rem(16);

          a.btn-purchase:hover {
            color: #fff !important;
          }

          figure {
            text-align: center;

            img {
              // width: 100%;
              width: auto;
              height: 250px;
            }
          }

          p {
            // line-height: (24/12);
            // display: -webkit-box;
            // -webkit-line-clamp: 2;
            // -webkit-box-orient: vertical;
            // text-overflow: ellipsis;
            // overflow: hidden;
            // max-height: 60px;
            margin: 0;
            font-size: rem(15);
            text-align: justify;
            word-break: break-word;
            margin-bottom: 10px;

            a {
              color: inherit;
            }

            @include media-breakpoint-down(md) {
              // max-height: 48px;
            }
          }
        }

        &__detail {
          font-size: rem(15);
          // padding-left: 60px;
          margin-top: 10px;
          width: 100%;

          .row {
            & + .row {
              margin-top: 5px;
            }
          }

          p {
            margin: 0;
            font-size: rem(15);
          }

          .col-3 {
            font-weight: bold;
            font-size: rem(14);
            flex: 0 0 30%;
            max-width: 30%;
          }

          .col-9 {
            flex: 0 0 70%;
            max-width: 70%;
          }

          .text-blue {
            font-size: rem(11);
            color: #51aaaa;
          }

          .row {
            & ~ .row {
              // margin-top: 10px
            }
          }
        }
      }

      &.group-list--event02 {
        .group-list__info {
          color: #7c797a;
        }

        .txt-date {
          color: $orange;
        }

        .txt-number {
          background: $orange;
          color: #fff;
          padding: 3px 11px;
          border-radius: 5px;
          display: inline-block;
        }

        .group-list__des {
          padding-left: 0;
          overflow-wrap: break-word;

          figure {
            margin-left: -20px;
            margin-right: -20px;
          }

          h4 {
            margin-top: 10px;
            color: #51aaaa;
          }

          .image-gallery-slide img {
            width: auto;

            @media (max-width: 767px) {
              height: 280px !important;
            }

            @media (min-width: 768px) {
              height: 320px !important;
            }

            @media (min-width: 992px) {
              height: 380px !important;
            }
          }
        }

        .group-list__detail {
          padding-left: 0;
        }
      }

      &.group-list--event01 {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        background-color: #fff;
        border-radius: 15px;
        padding: 10px;
        margin-left: -15px;
        margin-right: -15px;
        width: auto;

        p {
          line-height: (24/12);
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
          max-height: (24/12) * 3rem;
        }

        & ~ .group-list--event01 {
          margin-top: 20px;
        }

        .group-list__item {
          box-shadow: 0 3px 6px rgb(0 0 0 / 0%);
          margin-left: 0;
          margin-right: 0;
          padding: 0;
        }

        .btn--solid.btn--solid03 {
          margin-top: 20px;
          margin-bottom: 20px;
          color: #656565;
          border: 1px solid #ababab;
        }

        .group-list__info p {
          a {
            text-decoration: underline;
          }
        }

        .price {
          font-size: 10px;
          font-weight: normal;
          margin: 4px 0 0 0;
        }
      }
    }

    &--mygroup {
      margin-top: 0;
      margin-bottom: 30px;

      h3 {
        font-size: rem(14);
        color: $orange;
      }

      .group-list__item {
        &:first-child {
          border-top: none;
        }

        &:last-child {
          border-bottom: 1px solid rgba(112, 112, 112, 0.3);
        }
      }

      .group-list__info {
        p {
          span {
            font-size: rem(12);

            &.t-member {
              color: #da5039;
            }

            &.t-deal {
              color: #51aaaa;
            }
          }
        }
      }

      .group-list__btn {
        flex: 0 0 40px;
        max-width: 40px;
      }
    }

    &__des {
      width: 100%;

      h4 {
        button {
          font-size: 0.875rem;
          color: #51aaaa;
          font-weight: 500;
        }
      }

      .paragraph-pre-wrap {
        .read-more {
          font-size: 0.875rem;
          color: #51aaaa;
          font-weight: 500;
          display: inline;
          cursor: pointer;
          text-decoration: underline;
          padding-left: 6px;
        }
        button {
          font-size: 0.9375rem;
          color: gray;
        }
      }

      .image-gallery {
        img {
          width: 100%;
          object-fit: contain;
        }

        &.img--grid {
          // CSS for grid view
          height: 250px;
          img {
            height: 250px;
          }
        }

        &.img--list {
          // CSS for list view
          height: 200px;
          img {
            height: 200px;
          }
        }
      }
    }

    &__footer {
      background-color: #51aaaa;
      height: 50px;

      .wrap-button-item {
        span {
          transition: all 0.3s ease-in-out;
        }
        &:not(:last-child) {
          border-right: 1px solid white !important;
        }

        &:last-child {
          border-right: none;
        }

        &:hover {
          span {
            transform: translateY(-2px);
          }
        }
      }

      button {
        color: white;
        text-decoration: underline;
        border-top: none;
        border-bottom: none;
        border-left: none;

        &:active,
        &:focus {
          outline: none;
          color: white;
          text-decoration: underline;
        }

        & ~ button {
          margin-top: 0;
        }
      }
    }
  }
}

.event-item {
  .nav-tab li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    color: #7e7e7e;
    font-weight: bold;
    font-size: 0.625rem;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    margin: 0 10px;
    cursor: pointer;
  }

  h4 {
    word-break: break-all;
    button {
      text-align: left;
    }
  }

  /* form delivery start*/
  .icon-truct {
    margin-left: 2.2rem;
    font-size: 3rem;
    color: rgb(7, 157, 154);
    line-height: 0;
    z-index: 1;
    margin-bottom: -7px;
  }

  .delivery-form {
    background: #079d9a;
    padding: 0 1rem 1rem 1rem;
    border-radius: 1rem;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0;

      .header-left {
        line-height: 1.2;
        display: flex;
        flex-direction: column;
        color: white;

        &--text {
          font-size: 1.65rem;
          font-weight: 700;
        }

        &--date {
          font-size: 1rem;
        }
      }

      .header-right {
        display: flex;
        line-height: 1.2;
        color: white;
        font-size: 1rem;

        &--text {
          display: flex;
          flex-direction: column;
          margin-right: 0.1rem;
          align-content: space-between;
        }

        &--img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          background: #fff;
          object-fit: cover;

          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }

    .form-input {
      margin-bottom: 1rem;

      label {
        display: flex;
        align-items: center;
        font-size: 0.75rem;
        font-weight: 700;
        margin-bottom: 0 !important;
        color: white;
      }
    }

    .form-input-size {
      display: block;
      width: 100%;
      height: 2rem;
      padding: 0.3rem 0.4rem;
      border-radius: 0.2rem;
      outline: 0;
      border-style: none;
      margin-top: 0.4rem;
      background-color: white;
    }

    .button-submit {
      text-align: right;

      button {
        background-color: #15cdca;
        color: white;
        font-size: 0.75rem;
        border-radius: 1.5rem;
        outline: none;
        border-style: none;
        padding: 0.5rem 3rem;
      }
    }
  }
  /* form delivery end*/

  /* list check box purchase detail*/
  .list-check-box-container {
    border: none;
    flex-wrap: wrap;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    background-color: #fff;
    border-radius: 15px;
    margin-top: 5px;
    padding: 20px;
  }

  .text-color-blue {
    color: rgb(7, 157, 154);
    font-size: 1.1rem !important;
    line-height: 1;
    cursor: pointer;
    border: 2px solid;
    text-align: center;
    padding: 10px 0;
    border-radius: 5px;
  }

  .text-add-delivery {
    line-height: 1rem;
    cursor: pointer;
    text-decoration: underline;
    font-size: 0.875rem;
    color: rgb(7, 157, 154);
  }

  .box-same-delivery-current-order {
    background: rgba(33, 205, 202, 0.09);
    color: #939091;
    padding: 20px;
    border-radius: 15px;
    font-size: 0.6875rem;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    margin-bottom: 20px;

    &__item-order {
      margin-bottom: 10px;
    }
  }

  .list-check-box-container {
    border: none;
    flex-wrap: wrap;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    background-color: #fff;
    border-radius: 15px;
    margin-top: 5px;
    padding: 15px;

    .box-order-with-delivery {
      border: 1px solid #51aaaa;
      border-radius: 5px;
      padding: 5px;
      margin-bottom: 10px;

      &__item-order {
        margin-bottom: 4px;
      }
    }
  }

  .text-cancel-delivery {
    margin-top: 10px;
    font-size: 0.6rem;
    font-weight: bold;
    color: rgb(7, 157, 154);
    text-decoration: underline;
    cursor: pointer;
  }

  .input-delivery-date {
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    border-radius: 10px;

    .form-control {
      border: none !important;
    }
  }

  .group-hour-delivery {
    display: flex;
    justify-content: space-between;

    @media (max-width: 340px) {
      flex-direction: column;

      .input-delivery-hour {
        margin-top: 0.2rem;

        input {
          width: 100%;
        }
      }

      .icon-cross {
        display: none;
      }
    }

    .input-delivery-hour {
      div {
        font-size: 0.775rem;
      }

      input {
        box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
        border-radius: 10px;
        padding: 0.375rem 0.75rem !important;
        border: none !important;
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-position: 90% center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 172 172'%3E%3Cpath d='M0,172v-172h172v172z' fill='none'/%3E%3Cg fill='%23495057'%3E%3Cpath d='M86,14.33333c-39.49552,0 -71.66667,32.17115 -71.66667,71.66667c0,39.49552 32.17115,71.66667 71.66667,71.66667c39.49552,0 71.66667,-32.17115 71.66667,-71.66667c0,-39.49552 -32.17115,-71.66667 -71.66667,-71.66667z'/%3E%3C/g%3E%3C/svg%3E");
        box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
        border-radius: 10px;
        padding: 0.375rem 2.3rem 0.375rem 0.75rem !important;
        border: none !important;
        color: #495057;

        &:focus {
          outline: none;
          border: none;
        }
      }
    }
  }

  .input-delivery-group {
    select {
      color: #495057;
      box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
      border-radius: 10px;
      padding: 0.375rem 0.75rem !important;
      border: none !important;
      width: 100%;

      &:focus {
        border: none !important;
        outline: none;
      }
    }
  }

  .pay-together {
    font-style: italic;
    font-size: 0.675;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  // .pagination-container {
  //   display: flex;
  //   justify-content: center;
  //   margin-top: 20px;
  // }

  // .pagination-item {
  //   border: 1px solid;
  //   width: 30px;
  //   height: 30px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   border-radius: 50%;
  //   margin: 0 5px;
  //   cursor: pointer;

  //   &.selected {
  //     background-color: #51aaaa;
  //     color: #fff;
  //     border: 1px solid #51aaaa;

  //     &:hover {
  //       background-color: #51aaaa;
  //       color: #fff;
  //       border: 1px solid #51aaaa;
  //       transition: all 0.3s ease-in-out;
  //     }
  //   }

  //   &.disabled {
  //     background-color: lightgray;
  //     color: white;
  //     border: 1px solid lightgray;
  //     cursor: not-allowed;
  //   }

  //   &:hover {
  //     border: 1px solid #51aaaa;
  //     color: #51aaaa;
  //     transition: all 0.3s ease-in-out;
  //   }
  // }
}

.modal-event-note {
  .note-list {
    color: gray;

    .btn-action {
      cursor: pointer;

      .btn-remove {
        width: 17px;
      }
    }
  }
  .add-more {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .btn-add-more {
    font-size: 0.75rem;
    margin-left: auto;
    color: gray;
    cursor: pointer;

    &:hover {
      color: #51aaaa;
      text-decoration: underline;
    }
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.datepicker-wrapper {
  position: relative;
  width: 100%;

  .calendar-icon {
    position: absolute;
    right: 10px;
    top: 40%;
    transform: translateY(-50%);
    color: #6c757d; // Adjust color as needed
    pointer-events: none; // Prevent icon from blocking clicks
  }

  input {
    cursor: pointer;
  }
}

.react-loading-skeleton {
  background-color: #e3e3e3 !important;

  &::after {
    background-image: linear-gradient(90deg, #e3e3e3, #f4f4f4, #e3e3e3) !important;
  }
}
