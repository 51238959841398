@import '../../assets/css/base/functions';
@import '../../assets/css/base/variables';
@import '../../assets/css/base/mixins';

#digistall {
  .page-login {
    font-family: $font-family-sans-serif;
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
    background-color: #15cdca;

    .checklist {
      li.valid {
        color: green;
      }
    }
    .errorMessage {
      color: #ff9900;
    }

    &--screen {
      min-height: auto !important;
    }

    > div {
      // max-width: 100%;
      flex: 0 0 100%;
    }

    .main-login-top {
      justify-content: center;
      align-self: center;
      max-width: 100%;
      flex: 0 0 100%;
    }

    .main-login-bot {
      margin-bottom: 20px;
      margin-top: 30px;
      align-self: end;
      max-width: 100%;
      flex: 0 0 100%;
      align-self: flex-end;
      font-size: 14px;
      color: #7c797a;

      .row {
        margin-left: -6px;
        margin-right: -6px;
        margin-top: 10px;
        margin-bottom: 20px;

        .col-6 {
          padding-left: 3px;
          padding-right: 3px;
        }
      }
    }

    .main-logo {
      text-align: center;
      margin: 20px 0 20px 0;
    }

    .main-img {
      margin-left: -15px;
      margin-right: -15px;
      margin-top: 10px;
      text-align: center;
    }
    .main-content {
      .c-form--connect,
      #postalCode {
        input:focus {
          box-shadow: 0 3px 6px rgb(0 0 0 / 16%) !important;
        }
      }
    }
    @include media-breakpoint-up(md) {
      .page-login {
        max-width: 640px;
        margin: 0 auto;
      }
    }

    @include media-breakpoint-down(sm) {
      background: white;

      .page-login {
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }

  .c-form {
    .upload-image {
      box-shadow: 0 3px 6px rgb(0 0 0 / 0.16);
      width: 107px;
      height: 107px;
      border-radius: 50%;
      margin: 0 auto;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      &-txt {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 12px;
        color: #7e7e7e;
        flex-direction: column;
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        span {
          display: block;
          margin-top: 10px;
        }
      }

      &--square {
        box-shadow: none;
        width: 74px;
        height: 74px;
        background: rgb(246 178 12 / 0.15);
        border-radius: 0;
        padding: 10px;

        .u-file-upload__item {
          color: #f06a0f;
          font-size: 10px;
        }
      }
      &--modal {
        box-shadow: none;
        width: 143px;
        height: 81px;
        background: rgb(246 178 12 / 0.15);
        border-radius: 0;
        background: #fff;
        border: 1px solid #868384;
      }
    }

    .u-file-upload__item .u-file-upload__wrap input {
      width: 100%;
      height: 100%;
      opacity: 0;
      padding-left: 100px;
      margin-right: -100px;
      cursor: pointer;
    }

    .u-file-upload__item input {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      background-color: transparent;
      color: transparent;
      right: 0;
      bottom: 0;
      border-radius: 30px;
      opacity: 0;
      cursor: pointer;
      width: 100%;
    }

    .upload-name {
      text-align: center;
      font-size: rem(20);
      font-weight: bold;
      color: #f06a0f;
    }

    &--email,
    &--search,
    &--profile {
      margin-top: 50px;

      .form-group {
        margin-top: 20px;

        // .form-control {
        //   border-radius: 30px;
        //   border: none;
        //   box-shadow: 0 3px 6px rgb(0 0 0 / 0.16);
        //   font-size: rem(12);
        //   height: 40px;
        // }
      }
    }

    &--group {
      .form-group {
        label {
          color: #f06a0f;
          font-size: rem(14);
        }

        & ~ .c-txt-13 {
          margin-top: 25px;
        }
      }
    }

    &--org {
      background: #fef4da;
      padding: 10px;
    }

    &--search {
      .form-group {
        position: relative;
        margin-top: 0;

        .icon-search {
          top: 13px;
          left: 10px;
          position: absolute;
        }

        .form-control {
          padding-left: 30px;
        }
      }
    }

    // input:checked + .slider {
    //   background-color: #15cdca;
    // }

    // input:checked + .slider:before {
    //   -webkit-transform: translateX(24px);
    //   -ms-transform: translateX(24px);
    //   transform: translateX(24px);
    // }
  }

  // .p-login {
  //   background-color: #15cdca !important;

  //   @include media-breakpoint-up(md) {
  //     .page-login {
  //       max-width: 640px;
  //       margin: 0 auto;
  //     }
  //   }

  //   @include media-breakpoint-down(sm) {
  //     background: white;

  //     .page-login {
  //       max-width: 100%;
  //       margin: 0 auto;
  //     }
  //   }
  // }
}
