$warning: #ff6903;
$dark: #121212;
$slate-gray: #64748b;

.shop-detail {
  // Product Card Component
  .product-card {
    $this: &;

    // Base styles
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: center;
    transition: all 0.3s ease;
    position: relative;
    margin-bottom: 1rem;

    &:hover {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);

      img {
        transform: scale(1.05);
      }
    }

    // Elements
    &__image {
      border-radius: 0.5rem 0.5rem 0 0;
      overflow: hidden;
      position: relative;

      // Modifiers
      &--grid {
        height: 200px;

        @include respond-to('mobile') {
          height: 120px;
        }
      }

      &--list {
        border-radius: 0.5rem 0 0 0.5rem;
        height: 100%;
        width: 250px;

        // Add mobile specific styles
        @include respond-to('mobile') {
          width: 100px; // Reduce width on mobile
          min-width: 100px;
          min-height: 100px; // Ensure minimum height
          max-height: 150px;
        }
      }
    }

    &__no-image {
      align-items: stretch;
      display: flex;
      height: 100%;
      width: 100%;

      svg {
        flex: 1;
        height: 100%;
        min-width: 0;
        width: 100%;
      }
    }

    &__content {
      background: #fff;
      border-radius: 0 0 0.5rem 0.5rem;
      padding: 0px 1rem 1rem 1rem;
      flex: 1;
      display: flex;
      flex-direction: column;

      &-header {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      &-title {
        color: $dark;
        font-size: 1rem;
        font-weight: 600;
        max-width: 100%;
        word-break: break-word;
        text-align: start;
        @include text-ellipsis(2);
        @include respond-to('mobile') {
          min-height: auto;
        }
      }

      &-price {
        color: $warning;
        font-size: 1.15rem;
        font-weight: 700;
        text-align: end;
        min-width: fit-content;
        margin-bottom: 0.5rem;

        span {
          display: inline-block;
        }
      }

      &-description {
        color: $slate-gray;
        font-size: 0.675rem;
        line-height: 1.3;
        word-break: break-word;
        text-align: start;
        margin-bottom: 0.5rem;
        min-height: 35.09px;
        @include text-ellipsis(2);

        @include respond-to('mobile') {
          min-height: auto;
        }
      }

      &--list {
        width: 200px;
        min-height: 200px;
        justify-content: space-between;
        padding: 0.5rem 1rem 1rem 1rem;
      }

      &--grid {
        .product-card__content-description {
          margin-bottom: 0.75rem;
          text-align: center;
        }

        .product-card__content-title {
          margin-bottom: 0;
          min-height: 52.5px;
          text-align: center;
        }

        .product-card__content-price {
          text-align: center;
        }
      }
    }

    &__quantity-control {
      align-items: center;
      display: flex;
      height: 40px;

      .icon-min,
      .icon-plus {
        height: 20px;
        width: 20px;
      }

      .icon-input {
        input {
          color: $warning !important;
        }
      }

      &--list {
        margin-top: 0.5rem;
        justify-content: start;
      }

      &--grid {
        justify-content: center;
      }
    }

    &__actions {
      &--list {
        margin-bottom: 0;
        margin-top: 0.75rem;
        display: flex;
        gap: 1rem;
      }

      &--grid {
        margin-bottom: 0;
        margin-top: auto;
      }

      // @include respond-to('mobile') {
      //   height: 30px;
      //   font-size: 0.625rem;
      // }
    }

    &__add-another {
      @include respond-to('mobile') {
        font-size: 0.625rem;
        height: 30px;
      }
    }

    &__delete-btn {
      position: absolute;
      top: 4px;
      right: 4px;
      width: 24px;
      height: 24px;
      border-radius: 0.375rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s ease;
      color: $dark;

      &:hover {
        background-color: $warning;
        color: white;
      }

      &:active {
        transform: scale(0.95);
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__button {
      &--list {
        margin-bottom: 0;
        margin-top: 0.75rem;
      }

      &--grid {
        margin-top: auto;
      }
    }

    &__option-box {
      &--list {
        margin-top: 0.5rem;
      }

      &--grid {
        text-align: left;
        margin-bottom: 0.75rem;
      }
    }

    &__option-group {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__option-title {
      font-size: 0.65rem;
      font-weight: 600;
      color: $dark;
      margin-bottom: 0.25rem;
      text-transform: capitalize;
    }

    &__option-items {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
    }

    &__option-item {
      width: fit-content;
      padding: 0.25rem;
      border: 1px solid #e5e7eb;
      border-radius: 0.375rem;
      cursor: pointer;
      transition: all 0.2s ease;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.1rem;
      position: relative;
      overflow: hidden;

      &:hover {
        border-color: $warning;
      }

      &--active {
        border-color: $warning;
      }

      // Add new styles for check icon
      &--active::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 12px 12px;
        border-color: transparent transparent $warning transparent;
      }

      // Add new styles for check icon
      &--active::before {
        content: '✓';
        position: absolute;
        right: 2px;
        bottom: 0;
        font-size: 5px;
        color: white;
        z-index: 1;
      }
    }

    &__option-name {
      font-size: 0.6rem;
      font-weight: 500;
      color: $dark;
      text-align: center;
    }

    &__option-price {
      font-size: 0.6rem;
      color: $slate-gray;
      text-align: center;
    }

    // Grid view specific styles
    &--grid {
      height: 100%;

      .product-card__content {
        padding: 1rem;

        &-title {
          font-size: 0.875rem;

          @include respond-to('mobile') {
            font-size: 0.75rem;
            min-height: 35.5px;
          }
        }

        &-price {
          text-align: center;
        }
      }

      .product-card__quantity-control {
        justify-content: center;
      }

      .product-card__actions {
        text-align: center;
      }
    }

    // Responsive
    @include respond-to('mobile') {
      &__button {
        font-size: 0.625rem;
        height: 30px;
      }

      &__quantity-control {
        height: 30px;
      }

      &__content {
        &-title {
          font-size: 0.75rem;
        }

        &-price {
          font-size: 1rem;
        }

        &--list {
          #{$this}__content-body {
            flex-direction: column;
            gap: 0.75rem;
          }

          #{$this}__content-price {
            font-size: 1.25rem;
          }
        }
      }
    }
  }
}
