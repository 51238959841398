.modal-subscription-success {
  .modal-dialog {
    max-width: 400px;
  }

  .modal-content {
    border: none;
    border-radius: 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }

  .modal-header {
    border: none;
    padding: 0 1rem;
    background: linear-gradient(135deg, #2ecc71, #27ae60);

    button.close {
      padding: 0.75rem;
      margin: 0;
      opacity: 0.5;
      transition: opacity 0.2s;
      color: white;

      &:hover {
        opacity: 1;
      }
    }
  }

  .success-icon-wrapper {
    text-align: center;
    padding: 2rem 0;
    background: linear-gradient(135deg, #2ecc71, #27ae60);
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: radial-gradient(circle at center, rgba(255, 255, 255, 0.2) 0%, transparent 70%);
    }

    svg {
      filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
      animation: scaleIn 0.5s ease-out;
    }
  }

  .modal-body {
    padding: 2rem;

    .title {
      font-size: 1.5rem;
      font-weight: 700;
      color: #2c3e50;
      margin-bottom: 1rem;
      animation: fadeInUp 0.5s ease-out 0.2s both;
    }

    .message {
      color: #7f8c8d;
      line-height: 1.5;
      margin-bottom: 2rem;
      animation: fadeInUp 0.5s ease-out 0.3s both;
    }

    button {
      width: 100%;
      padding: 0.75rem;
      animation: fadeInUp 0.5s ease-out 0.4s both;

      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 4px 12px rgba(46, 204, 113, 0.2);
      }
    }
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
