@import '../../../assets/css/base/functions';
@import '../../../assets/css/base/variables';
@import '../../../assets/css/base/mixins';

.create-event {
  font-family: $font-family-other;
  color: gray;

  &__title {
    color: rgb(255, 153, 0);
    font-size: 24px;
  }

  .upload-excel-group {
    font-size: 12px;
    display: flex;
    .register-schedule-event {
      flex-basis: 70%;
      color: #d1274d;
      text-decoration: underline;
      cursor: pointer;
      text-align: right;
    }
  }

  .upload-excel {
    flex-basis: 30%;
    font-size: 12px;
    cursor: pointer;
    a {
      color: rgb(23, 162, 184);
    }
  }

  .form-group {
    label {
      margin-bottom: 0.25rem;
    }

    .form-control {
      &:focus {
        box-shadow: none !important;
        border: 1px solid #ced4da;
      }
    }

    &--select {
      .form-control {
        &--height {
          &:focus {
            box-shadow: 0 3px 6px rgb(0 0 0 / 16%) !important;
            border: none;
          }
        }
      }
    }
  }

  .payment {
    .c-form {
      .havebg {
        padding: 20px;
        margin-bottom: 10px;
      }
      .text-right {
        display: flex;
        align-items: center;
        .form-control {
          margin-left: 10px;
          &:focus {
            box-shadow: none !important;
            border: 1px solid #ced4da;
          }
        }
      }
    }
  }

  .error {
    color: rgb(255, 153, 0);
  }

  .react-datepicker-wrapper input {
    border: 1px solid #ced4da !important;
    transition: all 0.15s ease-in-out;

    &:hover {
      border: 1px solid #0badad !important;
    }
  }

  .select-header,
  .options-container .option {
    font-size: 0.75rem;
  }

  .custom-select {
    min-width: auto;
  }
}
