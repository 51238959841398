$orange: #f06a0f;

#digistall {
  .order-management-header {
    &__title {
      font-weight: bold;
      color: $orange;
      font-size: 1.5rem;
    }

    &__search {
      &-input {
        width: 100%;
      }
    }

    &__filters {
      &-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
      }
    }

    &__create {
      @extend %text-responsive;
    }

    &__render-selecting {
      @extend %text-responsive;
      strong {
        color: $orange;
      }
    }

    &__buttons {
      display: flex;
      gap: 1rem;
      align-items: center;
    }

    &__select {
      min-width: 150px;
    }

    &__details {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      margin-bottom: 1rem;
    }

    .select-filter {
      width: 100%;
      margin: 0px;
      display: flex;
      gap: 1rem;

      .filter-row {
        display: flex;
        gap: 1rem;

        &.button-modal {
          flex-direction: row;
          margin-bottom: 1rem;
        }

        &.multi-select {
          flex-direction: row;

          .select-status,
          .select-fulfillment {
            min-width: 200px;
          }

          .select__control {
            margin-right: 0px;
          }
        }

        .custom-button {
          flex: 1;
        }
      }
    }

    .tab-event {
      .dropdown-menu {
        &.show {
          z-index: 9999;
        }
      }
    }

    // Mobile styles
    @include respond-to('mobile') {
      &__search {
        flex-direction: column-reverse;
        gap: 1rem;
      }

      &__filters-container {
        flex-direction: column;

        .select-filter {
          width: 100%;
          margin: 0px;
          flex-direction: column;
          gap: 0px;

          .filter-row {
            display: flex;
            gap: 1rem;

            &.button-modal {
              flex-direction: row;
              margin-bottom: 1rem;
            }

            &.multi-select {
              flex-direction: column;

              .select__control {
                margin-right: 0px;
              }
            }

            .custom-button {
              flex: 1;
            }
          }
        }
      }

      &__buttons {
        flex-direction: row;
        width: 100%;

        .order-management-header__button {
          width: 100% !important;
          text-align: center;
        }

        .order-management-header__create {
          margin-left: auto;
        }
      }

      &__select {
        width: 100%;
      }

      &__tabs {
        flex-direction: row;
        align-items: stretch;
      }

      &__details {
        margin: 1rem auto;
      }
    }

    // Tablet styles
    @include respond-to('tablet') {
      &__filters-container {
        flex-direction: row;

        .filter-row {
          &.multi-select {
            flex-direction: row !important;
          }
        }
      }

      &__buttons {
        flex-direction: row;
        width: auto;
      }

      &__select {
        width: auto;
      }

      &__search {
        flex-direction: row;
        gap: 1rem;
      }

      // &__search-input {
      //   width: 300px;
      // }
    }

    // Desktop styles
    @include respond-to('desktop') {
      &__search-input {
        width: 400px;
      }

      &__filters-container {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
