.form-contact-details__container {
  background-color: white;
  border-radius: 0.25rem;

  @extend %padding-responsive;
  @extend %text-responsive;

  &--margin-top {
    @include respond-to('mobile') {
      margin-top: 1rem; // Mobile margin-top
    }

    @include respond-to('tablet') {
      margin-top: 1.5rem; // Tablet margin-top
    }

    // @include respond-to('desktop') {
    //   margin-top: 2rem; // Desktop margin-top
    // }
  }

  .form-group {
    margin-bottom: 0.5rem;
  }

  .form-group:last-child {
    margin-bottom: 0;
  }
}

.form-contact-details__title {
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #64748b;

  @include respond-to('mobile') {
    font-size: $font-size-title-mobile; // Mobile font size
  }

  @include respond-to('tablet') {
    font-size: $font-size-title-tablet; // Tablet font size
  }

  @include respond-to('desktop') {
    font-size: $font-size-title-desktop; // Desktop font size
  }
}
