.payment-code__verified {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  min-height: 200px;
  margin: 2.3rem auto 0 auto;
  background: #ffffff;
  border-radius: 0.25rem;
  padding: 2rem;
  transition: all 0.3s ease;
  box-sizing: border-box;
  border: 2px solid;

  @media screen and (min-width: 1024px) {
    min-height: 300px;
  }

  @media screen and (max-width: 1023px) {
    width: 100%;
    margin: 1rem 0 0;
  }

  .icon-wrapper {
    border-radius: 50%;
    padding: 1rem;
    margin-bottom: 1rem;

    svg {
      width: 48px;
      height: 48px;
      filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1));
    }
  }

  .status-text {
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;

    &::after {
      content: '';
      display: block;
      width: 40px;
      height: 2px;
      background: currentColor;
      margin: 0.5rem auto 0;
      border-radius: 2px;
    }
  }

  .status-title {
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;

    &::after {
      content: '';
      display: block;
      width: 40px;
      height: 2px;
      background: currentColor;
      margin: 0.5rem auto 0;
      border-radius: 2px;
    }
  }

  .status-message {
    font-size: 1rem;
    text-align: center;
    margin-top: 0.5rem;
    color: #6c757d;
  }
}
