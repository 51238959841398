// Variables
$primary: #15cdca;
$secondary: #29aba8;
$danger: #d33;
$warning: #ff6903;
$dark: #121212;
$slate-gray: #64748b;
$hover-color: #f8f9fa;

@import '../../../../styles/breakpoints.scss';
@import '../../../../styles/font-size-utility.scss';
@import '../../../../styles/padding-utility.scss';

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin text-ellipsis($lines) {
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  @if $lines > 1 {
    display: -webkit-box;
  }
}

// ---- import styles from components ----
@import './shop-banner.scss';
@import './product-card.scss';
@import './social-media.scss';
@import './view-selector.scss';
@import './product-container.scss';
@import './button-back.scss';
@import './modal-congratulation-create-shop.scss';
@import './skeleton.scss';

// Base styles
#digistall {
  .shop-detail {
    display: flex;
    flex-direction: column;

    // Common styles
    // img {
    //   width: 100%;
    //   // height: 100%;
    //   object-fit: cover;
    //   object-position: center;
    // }

    img,
    svg {
      transition: transform 0.3s ease;
    }

    .container {
      max-width: 1024px;
      margin: 0 auto;
      padding: 0 1rem;

      @include respond-to('tablet') {
        padding: 0 2rem;
      }
    }

    // Layout
    &-body {
      padding: 2rem;
      padding-bottom: 5rem;
      height: 100%;

      .btn-checkout {
        margin-top: 2rem;
      }

      @include respond-to('mobile') {
        padding: 1rem 1rem 5rem 1rem;
      }

      @include respond-to('tablet') {
        .btn-checkout {
          width: 50%;
        }
      }

      @include respond-to('desktop') {
        .btn-checkout {
          width: 50%;
        }
      }
    }

    .react-loading-skeleton {
      background-color: #e5e7eb;

      &::after {
        background-image: linear-gradient(90deg, #e5e7eb, #f3f4f6, #e5e7eb);
      }
    }

    // Product card skeleton
    .product-card {
      background: #fff;
      border-radius: 0.5rem;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      height: 100%;
    }
  }
}
