.payment-code {
  .tabs {
    .nav-item {
      flex: 1;
      text-align: center;
      cursor: pointer;

      .nav-link {
        font-weight: 600;
        border-radius: 0;
        width: 100%;
        &:hover {
          color: #679e9c;
        }
        &:not(.active) {
          opacity: 0.7;
        }
      }
    }

    .active {
      background-color: #edfbfb;
      color: #679e9c;
      border-color: #679e9c;
    }

    .tab-content {
      padding-top: 1rem;
      .tab-pane {
        background-color: white;
        color: #919192;
      }
    }

    .form-delivery-details,
    .pickup-location {
      .form-control,
      .form-select-sm {
        box-shadow: rgba(0, 0, 0, 0.1) 0 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        font-size: 16px;
        padding-left: 1rem;
        height: 45px;
      }
      .form-select-sm {
        box-shadow: rgba(0, 0, 0, 0.1) 0 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        font-size: 16px;
        padding-left: 1rem;
        height: 45px;
        color: #919192;
        /** for the dropdown indicator */
        appearance: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='red' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 1rem center;
        background-size: 1em;
      }

      .react-datepicker__input-container input {
        padding-left: 2rem !important;
      }
    }
  }
}
