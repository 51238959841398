.container-fluid.admin-console.mobile {
  padding: 0px;
}

.main-layout {
  display: flex;
  min-width: auto;
  width: 100%;
  overflow-x: hidden;

  .main-content.admin-console {
    flex: 1;
    padding: 0;
    margin-left: 277px;
    // width: calc(100% - 280px); // Account for sidebar width
    overflow-x: auto; // Enable horizontal scroll
    border-radius: 0 !important;

    // When sidebar is collapsed
    &.main-content--collapsed {
      margin-left: 122px;
      // width: calc(100% - 125px);
    }

    // Mobile styles
    &.mobile {
      padding: 0;
      margin-left: 0;
      width: 100%;
    }
  }

  @media (min-width: 1024px) {
    min-width: 1200px;
  }
}

@media (max-width: 480px) {
  .main-content {
    padding: 0 15px;
  }
}
