$status-colors: (
  cancel: red,
  unpaid: #ffc107,
  paid: green,
  verified: rgb(78, 151, 246),
  fulfilled: rgb(143, 71, 244),
  collected: #28a745,
);

#digistall {
  .order-records {
    @each $status, $color in $status-colors {
      .#{$status}-status {
        .select-header {
          border-color: $color;
          box-shadow: none !important;

          svg {
            color: $color;
          }
        }

        .selected-value {
          color: $color !important;
        }
      }
    }
  }
}
