$orange-color: #f6660c;
$primary-color: #07c3c3;

@import '../../styles/breakpoints.scss';
@import '../../styles/font-size-utility.scss';

#digistall {
  .profile-page {
    margin-top: 68px;
    padding-bottom: 50px;
    padding-top: 1rem;
    .c-form.c-form--profile {
      margin-top: -50px;
      // margin-bottom: 20px;
    }

    .c-form.c-form--email {
      padding: 0;
      margin: 0;
    }

    .c-form .upload-image {
      width: 107px;
    }

    .form-group {
      margin-top: 10px !important;
      margin-bottom: 0.75rem; // Unified spacing
      position: relative;

      .form-label {
        display: block;
        margin-bottom: 0.25rem; // Unified label spacing
        font-size: 0.875rem;
        font-weight: 500;
        color: #2d3748;
        letter-spacing: 0.01em;
      }

      .text-danger {
        position: absolute;
        bottom: -18px;
        left: 4px;
        font-size: 0.75rem;
        color: #e53e3e;
      }
    }

    // Specific margin for the header text
    .c-txt-14 {
      margin-bottom: 1.5rem;
      font-weight: 500;
      color: #2d3748;
    }

    // Add some spacing between form and buttons
    .row--bot {
      margin-top: 2rem;
    }

    .profile-header {
      margin-bottom: 2rem;
      padding: 1.5rem;
      border-radius: 12px;
      background: linear-gradient(to right, #07c3c3, #08a8a8);
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);

      .title {
        color: white;
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }

      .email {
        color: rgba(255, 255, 255, 0.9);
        font-size: 0.875rem;
        font-weight: 400;
      }
    }

    .profile-header-minimal {
      margin-bottom: 1.5rem;
      padding: 0.75rem 0;
      border-bottom: 1px solid #e2e8f0;
      display: flex;
      align-items: center;
      gap: 0.75rem;

      .icon {
        color: $primary-color;
        @extend %title-responsive;
      }

      .title {
        color: $primary-color;
        font-weight: 600;
        width: 250px;
        @extend %title-responsive;

        @include respond-to('mobile') {
          width: 210px;
        }
      }

      .divider {
        color: #cbd5e0;
        font-size: 0.875rem;
      }

      .email {
        color: #718096;
        @extend %text-responsive;
      }
    }
  }
  .noti-list-item {
    .text-message {
      p {
        margin-bottom: 0;
      }
    }
  }
  .noti-list {
    padding: 0.5rem;

    &-item {
      padding: 0.75rem;
      margin-bottom: 0.75rem;
      border-radius: 6px;
      background: #fff;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
      transition: all 0.2s ease;
      border: 1px solid #e2e8f0;
      font-size: 0.8125rem;

      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      }

      // .notification-header {
      //   display: flex;
      //   align-items: center;
      //   margin-bottom: 0.35rem;
      //   gap: 0.5rem;

      //   .title {
      //     color: $primary-color;
      //     font-weight: 600;
      //     margin: 0;
      //   }

      //   .date {
      //     color: #718096;
      //     margin-left: auto;
      //   }

      //   .noti-icon {
      //     font-size: 0.875rem;
      //     transition: color 0.2s ease;

      //     &.system {
      //       color: #0bc5ea; // Bright cyan - for system notifications
      //     }
      //     &.info {
      //       color: #4299e1; // Bright blue - for information
      //     }
      //     &.warning {
      //       color: #ed8936; // Bright orange - for warnings
      //     }
      //     &.success {
      //       color: #48bb78; // Bright green - for success
      //     }
      //     &.default {
      //       color: #f1c40f; // Bright purple - for default/others
      //     }

      //     // Hover effects
      //     &:hover {
      //       &.system {
      //         color: #00b5d8;
      //       }
      //       &.info {
      //         color: #3182ce;
      //       }
      //       &.warning {
      //         color: #dd6b20;
      //       }
      //       &.success {
      //         color: #38a169;
      //       }
      //       &.default {
      //         color: #f1c40f;
      //       }
      //     }
      //   }
      // }

      // .text-message {
      //   color: #4a5568;
      //   font-size: 0.75rem;
      //   line-height: 1.4;
      //   margin: 0;

      //   p {
      //     margin: 0;
      //   }
      // }

      .sender {
        margin-top: 0.35rem;
        color: $orange-color;
        font-size: 0.675rem;
        font-weight: 500;
      }
    }
  }
  .action-link {
    display: flex;
    padding: 0;
    list-style: none;
    width: 100%;
    justify-content: center;
    margin: 8px 0;
  }
  .logout-btn {
    border: 2px solid $orange-color;
    border-radius: 30px;
    margin: 12px 0;
    cursor: pointer;
    &:hover {
      background-color: $orange-color;
      li {
        color: white;
      }
    }
  }
  .action-link li {
    font-size: 14px;
    color: $orange-color;
    margin-right: 15px;
    font-weight: 600;
    cursor: pointer;
  }
  .switch-version {
    > p {
      text-align: center;
      color: #f6b20c;
      font-size: 0.6rem;
      margin-bottom: 6px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .upgrade-premium {
    display: flex;
    justify-content: center;
    width: 100%;
    > p {
      font-size: 0.8rem;
      color: $orange-color;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  // .pagination-container {
  //   display: flex;
  //   list-style-type: none;
  //   justify-content: center;
  //   .pagination-item {
  //     height: 34px;
  //     text-align: center;
  //     margin: auto 4px;
  //     color: rgba(0, 0, 0, 0.87);
  //     box-sizing: border-box;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     border: 1px solid rgba(0, 0, 0, 0.3);
  //     letter-spacing: 0.01071em;
  //     border-radius: 16px;
  //     line-height: 1.43;
  //     font-size: 13px;
  //     min-width: 32px;
  //     transition: all 0.2s ease-in-out;
  //     &.dots:hover {
  //       background-color: transparent;
  //       cursor: default;
  //     }
  //     &:hover {
  //       background-color: rgba(0, 0, 0, 0.04);
  //       border-color: rgba(0, 0, 0, 0.04);
  //       cursor: pointer;
  //     }
  //     &.selected {
  //       background-color: $orange-color;
  //       border-color: $orange-color;
  //       color: white;
  //     }
  //     &.disabled {
  //       pointer-events: none;
  //       color: gray;
  //       &:hover {
  //         background-color: transparent;
  //         cursor: default;
  //       }
  //     }
  //   }
  // }

  .invoice {
    h3 {
      font-size: 24px;
    }

    h5 {
      font-size: 20px;
    }

    p,
    span,
    strong {
      font-size: 16px;
    }

    .subscription {
      margin-top: 68px;
      padding-bottom: 50px;
      padding-top: 1rem;
      &-layout {
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 80vh;
      }
      &-select-price {
        &-btn {
          display: flex;
          align-items: center;
          width: 100%;
          height: 77px;
          border: 1px solid $primary-color;
          margin-top: 18px;
          border-radius: 4px;
          > input {
            width: 10%;
            &:checked {
              background-color: $primary-color !important;
              border-color: $primary-color !important;
            }
          }

          &-text {
            display: flex;
            flex-direction: column;
            p {
              color: $primary-color;
              font-weight: 500;
              margin: 0;
            }
            span {
              font-size: 0.8rem;
            }
          }
        }
      }
      &-info {
        height: 260px;
        border-radius: 4px;
        border: 1px solid #73d042;
        position: relative;
        margin-top: 40px;
        &-check-icon {
          color: #73d042;
          font-size: 1.6rem;
          position: absolute;
          top: -12%;
          left: 46%;
          background-color: white;
        }
        &-text {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          p {
            text-align: center;
            width: 274px;
            font-size: 0.8rem;
            margin: 30px auto 0 auto;
            color: black;
          }
          &-desc {
            display: flex;
            flex-direction: column;
            font-size: 0.8rem;
            padding: 8px;
          }
        }
      }

      &-actions {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
    }

    .subscription-check-price {
      width: 10%;
      padding-left: 2.5em;
    }

    .subscription-check-price-input:checked {
      background-color: $primary-color !important;
      border-color: $primary-color !important;
    }

    .subscription-check-price-input:focus {
      border-color: transparent;
      box-shadow: none;
    }
  }

  .modal-cancel {
    .modal-content {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      .modal-header {
        .modal-title {
          text-align: left !important;
        }
      }
    }
    .btn-no {
      border: 1px;
      outline: none;
      // border: none;
      padding: 0.375rem 0.75rem;
      border-radius: 3px;
    }
    .btn-yes {
      outline: none;
      border: none;
      padding: 0.375rem 1rem;
      border-radius: 3px;
    }
  }

  .change-password-page {
    .text-underline {
      font-size: 1rem;
      font-weight: 500;
      color: #2d3748;
      margin-bottom: 0.5rem;
      text-align: center;
    }

    .errorMessage {
      display: block;
      text-align: center;
      margin-bottom: 1rem;
      font-size: 0.875rem;
    }
  }
}

.modal-payment-sub {
  button.close {
    display: none;
  }

  .modal-content {
    overflow: hidden;
    padding-top: 0 !important;
  }
}
