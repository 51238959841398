$social-colors: (
  'facebook': #165ef0,
  'whatsapp': #26ce54,
  'email': #fda460,
  'instagram': #e1306c,
  'home': #15cdca,
);

.shop-detail {
  // Social media icons
  .social-media {
    @include flex-center;
    gap: 1rem;

    a {
      color: inherit;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.8;
      }
    }

    @each $name, $color in $social-colors {
      .#{$name} {
        color: $color;
      }
    }
  }
}
