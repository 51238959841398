@import '../../../assets/css/base/functions';
@import '../../../assets/css/base/variables';
@import '../../../assets/css/base/mixins';
@import '../../../styles/breakpoints';
@import '../../../styles/font-size-utility';
@import '../../../styles/padding-utility';

.create-item {
  padding: 1rem;

  .container-input-select input {
    width: auto !important;
  }

  input:focus {
    outline: none !important;
  }

  &__title {
    @extend %title-responsive;
    color: #51aaaa;
    margin-bottom: 0px;
  }

  &__subtitle {
    @extend %subtitle-responsive;
    height: fit-content;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    color: #51aaaa;
  }

  &__upload {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
    align-items: center;
    justify-content: center;

    &-placeholder {
      position: relative;
      width: 100%;
      height: 150px;
      border: 1px dashed #ccc;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        .create-item__upload-text {
          color: #51aaaa;
        }

        .create-item__upload-placeholder-image {
          opacity: 0.7;
        }
      }
    }

    &-placeholder-image {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 64px;
      height: 64px;
      opacity: 0.5;
    }

    &-text {
      @extend %text-responsive;
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      text-align: center;
      color: #666;
      transition: color 0.3s ease;
      font-weight: 500;

      &:hover {
        color: #51aaaa;
      }
    }

    &-input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;

      input[type='file'] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 2;
      }
    }
  }

  &__images {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;

    &-wrapper {
      position: relative;
    }
  }

  &__image {
    position: relative;
    aspect-ratio: 1;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 107px;
    width: 107px;

    &:hover {
      box-shadow: 0 4px 8px rgba(21, 205, 202, 0.15);
    }

    &-main {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-cancel {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 24px;
      height: 24px;
      padding: 4px;
      background: rgba(255, 255, 255, 0.95);
      border-radius: 50%;
      cursor: pointer;
      transition: transform 0.2s ease;
      border: 1px solid rgba(21, 205, 202, 0.2);

      &:hover {
        background: #51aaaa;
        transform: scale(1.1);

        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;

    .create-item__title {
      color: #51aaaa;
    }
  }

  &__image-info {
    @extend %text-responsive;
    color: #666;

    .create-item__image-remain {
      color: #f06a0f;
      font-weight: 500;
      text-decoration: underline;
    }
  }

  &__footer-buttons {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .form-control:focus {
    box-shadow: none;
  }

  .sub-item {
    margin-top: 1rem;
  }

  .product-item {
    padding: 1rem;
    background-color: #ecfbfb;
    border-radius: 8px;
    margin-bottom: 1rem;

    &__header {
      display: flex;
      gap: 2rem;
    }

    &__upload-section {
      flex: 0 0 100px; // Changed from 200px
    }

    &__form {
      flex: 1;
    }

    &__label {
      margin-bottom: 0.25rem;
    }

    &__upload {
      position: relative;
      width: 100px; // Changed from 200px
      height: 100px; // Changed from 200px
      border: 1px dashed #179d9a;
      overflow: hidden;
      transition: all 0.3s ease;
      background-color: white;

      // &:hover {
      //   background-color: rgba(21, 205, 202, 0.04);
      // }

      &-placeholder-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 32px; // Thêm mới - kích thước nhỏ hơn cho no-image
        height: 34px; // Thêm mới
        opacity: 0.5;
      }

      img.product-item__image {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &--no-image {
          // Thêm modifier mới cho no-image
          width: 35px;
          height: auto;
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &-text {
        @extend %text-responsive;
        position: absolute;
        bottom: 18px; // Changed from 10px
        left: 0;
        right: 0;
        text-align: center;
        background: transparent;
        padding: 3px; // Changed from 5px
        font-size: 12px; // Changed from 14px
        color: #64748b;
      }
    }

    &__input-file {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    &__remove-image {
      position: absolute;
      top: 4px; // Changed from 8px
      right: 4px; // Changed from 8px
      width: 16px; // Changed from 24px
      height: 16px; // Changed from 24px
      background: rgba(255, 255, 255, 0.95);
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 10px; // Changed from 14px
        height: 1.5px; // Changed from 2px
        background: #64748b;
        transform: rotate(45deg);
        transition: all 0.2s ease;
      }

      &::after {
        transform: rotate(-45deg);
      }

      &:hover {
        transform: scale(1.1);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
        &::before,
        &::after {
          background: #dc3545;
        }
      }
    }

    &__content {
      flex: 1;
      gap: 1rem;
      width: 50%;
    }

    &__actions-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 40px;
      // margin-top: 25px;
    }

    &__action-btn {
      font-size: 24px; // Default cho desktop & tablet
      color: #64748b;
      cursor: pointer;
      transition: all 0.2s ease;
      margin-left: 0.75rem;

      &:hover {
        transform: scale(1.15); // Tăng scale khi hover
      }

      &--add:hover {
        color: #51aaaa;
      }

      &--copy:hover {
        color: #51aaaa;
      }

      &--remove:hover {
        color: #ef4444;
      }
    }

    &__arrows {
      display: flex;
      flex-direction: column;
    }

    &__arrow {
      color: #51aaaa;
      cursor: pointer;
      transition: all 0.2s ease;
      font-size: 60px;

      &--up {
        transform: translateY(15px);
      }

      &--down {
        transform: translateY(-15px);
      }

      &:hover {
        color: darken(#51aaaa, 5%);
      }
    }

    &__row {
      position: relative;

      &--price {
        display: flex;
        align-items: flex-start;
        gap: 1rem;
        justify-content: space-between;
        // transform: translateY(-16px);
        padding: 0 3px;

        .col-sm-9,
        .col-10 {
          display: flex;
          flex-direction: row;
        }
      }
    }

    &__text-add-subitem {
      transform: translate(3px, 6px);
      display: block;
      cursor: pointer;
    }

    @media (max-width: 768px) {
      padding: 0.75rem 0.75rem 0 0.75rem;

      &__header {
        flex-direction: column;
        gap: 1rem;
      }

      &__upload-section {
        flex: none;
        width: 100%;
        display: flex;
        justify-content: center;
      }

      &__upload {
        width: 100%;
        height: 120px;
        margin: 0 auto;
      }

      &__content {
        width: 100%;
      }

      &__form {
        flex: none;
      }

      &__row {
        label {
          margin-bottom: 0.25rem;
          font-size: 0.875rem;
        }
      }

      &__actions-wrapper {
        justify-content: center;
      }

      &__actions {
        gap: 1.5rem;
      }

      &__arrow {
        font-size: 26px;
      }

      &__action-btn {
        font-size: 45px;
      }

      &___text-add-subitem {
        transform: translate(3px, -42px);
      }
    }

    @media (max-width: 480px) {
      padding: 0.75rem 0.75rem 0 0.75rem;

      &__upload {
        width: 100%;
        height: 100px;
      }

      &__action-btn,
      &__arrow {
        font-size: 32px; // Điều chỉnh cho mobile
        padding: 0.5rem;
      }
    }
  }

  @media (max-width: 768px) {
    &__upload-placeholder {
      width: 100%;
      margin: 0 auto;
    }

    &__images {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }

    &__subtitle {
      margin-bottom: 1rem;
    }
  }
}

.sub-item {
  &__action-btn {
    font-size: 24px; // Default size for desktop
    color: #64748b;
    cursor: pointer;

    transition: all 0.2s ease;
    margin-left: 0.75rem;

    &:hover {
      transform: scale(1.15);
    }

    &--add:hover {
      color: #51aaaa;
    }

    &--copy:hover {
      color: #51aaaa;
    }

    &--remove:hover {
      color: #ef4444;
    }

    @media (max-width: 768px) {
      font-size: 20px; // Tablet size
    }

    @media (max-width: 480px) {
      font-size: 18px; // Mobile size
      margin-left: 0.5rem;
    }
  }
}
