.checkbox-wrapper-46 {
  font-weight: 600;
  @extend %text-responsive;

  input[type='checkbox'] {
    display: none;
    visibility: hidden;
  }

  .cbx {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;

    span {
      display: inline-block;
      vertical-align: middle;
      transform: translate3d(0, 0, 0);

      &:first-child {
        position: relative;
        width: 18px;
        height: 18px;
        border-radius: 3px;
        transform: scale(1);
        vertical-align: middle;
        border: 1px solid #9098a9;
        transition: all 0.2s ease;

        svg {
          position: absolute;
          top: 3px;
          left: 2px;
          fill: none;
          stroke: #ffffff;
          stroke-width: 2;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-dasharray: 16px;
          stroke-dashoffset: 16px;
          transition: all 0.3s ease;
          transition-delay: 0.1s;
          transform: translate3d(0, 0, 0);
        }

        &:before {
          content: '';
          width: 100%;
          height: 100%;
          background: $primary-color;
          display: block;
          transform: scale(0);
          opacity: 1;
          border-radius: 50%;
        }
      }

      &:last-child {
        padding-left: 8px;
      }
    }

    &:hover span:first-child {
      border-color: $primary-color;
    }
  }

  .inp-cbx:checked + .cbx {
    span:first-child {
      background: $primary-color;
      border-color: $primary-color;
      animation: wave-46 0.4s ease;

      svg {
        stroke-dashoffset: 0;
      }

      &:before {
        transform: scale(3.5);
        opacity: 0;
        transition: all 0.6s ease;
      }
    }
  }

  @keyframes wave-46 {
    50% {
      transform: scale(0.9);
    }
  }
}
