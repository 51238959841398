@import './styles/custom-image-zoom.scss';
@import './styles/custom.scss';

html,
body,
#root {
  height: 100%;
  // font-size: 10px;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

iframe {
  pointer-events: none;
}
