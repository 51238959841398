@import '../../../styles/breakpoints.scss';
@import '../../../styles/font-size-utility.scss';

$slate: #64748b;

.modal-calendar-orders,
.modal-fulfillment-date,
.modal-gift-info {
  .select-range-date {
    .rdrCalendarWrapper,
    .rdrMonth {
      font-size: 0.7rem;
      width: 100%;
    }

    .rdrDayToday .rdrDayNumber span:after {
      background-color: $primary-color;
    }
  }

  .btn-submit {
    background-color: $primary-color;
    color: white;
    border: 1px solid $primary-color;
    text-transform: uppercase;
    font-weight: normal !important;

    &:hover {
      background: darken($primary-color, 10%) !important;
      border: 1px solid darken($primary-color, 10%);
      box-shadow: none;
      color: white;
    }

    &:active,
    &:focus {
      background: darken($primary-color, 15%) !important;
      border: 1px solid darken($primary-color, 15%);
      box-shadow: none;
      color: white;
    }
  }

  .range-date-button {
    font-size: 14px;

    .btn-cancel {
      margin-top: 0;
      background: transparent;
      border: none;
      font-weight: normal !important;
      width: 100px;
      color: #7c797a;
      box-shadow: none;
    }
  }
}

.modal-payment {
  .modal-content {
    // color: $secondary-color;
    border: none;
    box-shadow: none;
    border-radius: 15px;
  }

  .btn-submit {
    background: $secondary-color !important;
    border: 1px solid $secondary-color !important;
    box-shadow: none;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: darken($secondary-color, 5%) !important;
      border: 1px solid darken($secondary-color, 5%);
    }

    &:active,
    &:focus {
      background: darken($secondary-color, 10%) !important;
      border: 1px solid darken($secondary-color, 10%);
      box-shadow: none !important;
    }
  }

  .btn-cancel {
    background: transparent;
    color: #7c797a;
    border: 0;
    box-shadow: none;
    margin-top: 0;
    font-weight: 500;
    font-size: 16px;

    &:focus,
    &:active {
      background: transparent !important;
      border: none !important;
      box-shadow: none !important;
      color: #7c797a !important;
    }
  }

  .text-payment {
    font-size: 24px;
  }

  .wrap-img {
    height: 300px;

    img {
      object-fit: contain;
    }
  }
}

.modal-reason-cancel {
  input:checked {
    background-color: $primary-color;
    border-color: $primary-color;
    color: white;
  }

  input:active,
  input:focus {
    border-color: $primary-color;
  }
}

.product-details-modal {
  width: 800px;
  max-width: 800px;
  color: $slate;

  @include respond-to('mobile') {
    width: auto;
    max-width: auto;
  }

  @include respond-to('tablet') {
    width: 95%;
    max-width: 95%;
  }

  .modal-body {
    padding: 0 0 1rem 0;
  }

  // Add new styles for product details table
  .wrapper-table {
    // Add custom scrollbar styles
    padding: 0 1rem;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      border-radius: 4px;

      &:hover {
        background: #a8a8a8;
      }
    }

    .custom-table {
      table {
        th,
        td {
          white-space: normal;
          word-wrap: break-word;
          word-break: break-word;
        }

        th {
          &.product-details__header--image {
            width: 100px;
          }
          &.product-details__header--name {
            width: 300px;
          }
          &.product-details__header--price {
            width: 120px;
          }
          &.product-details__header--quantity {
            width: 100px;
          }
          &.product-details__header--total {
            width: 120px;
          }
        }

        td {
          &.product-details__cell {
            padding: 0.75rem;
            vertical-align: middle;

            &--image {
              .product-details__image {
                width: 60px;
                height: 60px;
                object-fit: cover;
                border-radius: 4px;
              }
            }

            &--name {
              .product-name {
                font-size: 0.875rem;
                font-weight: 500;
                margin-bottom: 0.5rem;
              }
            }

            &--price,
            &--total {
              font-weight: 500;
              text-align: left;
              white-space: normal;
              word-wrap: break-word;
            }

            &--quantity {
              text-align: left;
              white-space: normal;
              word-wrap: break-word;
            }
          }
        }
      }
    }
  }

  .modal-footer {
    .fees-summary {
      width: 100%;

      &__content {
        // max-width: 300px;
        // margin-left: auto;
        width: 100%;
      }

      .fees-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0;
        color: $slate;
        font-size: 0.8rem;

        &.total {
          border-top: 2px solid #e2e8f0;
          margin-top: 0.5rem;
          padding-top: 1rem;
          font-weight: 600;
          font-size: 0.9rem;

          span:first-child {
            color: $primary-color;
          }

          .total-amount {
            color: $primary-color;
          }
        }

        span:first-child {
          color: #64748b;
        }
      }
    }
  }
}
