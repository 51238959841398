.modal-schedule-delivery {
  .modal-content {
    border-radius: 15px;
    border: none;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  }

  .modal-header {
    @extend %title-responsive;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1.25rem 1.5rem;
    font-weight: 600;
    color: #333;
    s .close {
      opacity: 0.5;
      transition: opacity 0.2s;
      &:hover {
        opacity: 1;
      }
    }
  }

  .modal-body {
    padding: 1rem;

    .select-header {
      margin-bottom: 0px !important;
    }

    .text-between,
    .text-to {
      @extend %text-responsive;
    }

    .delivery-options {
      .delivery-fee,
      .sub-txt {
        @extend %text-responsive;
      }
    }

    .input-delivery-group {
      select {
        @extend %text-responsive;
        height: 45px;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
        transition: all 0.2s ease;

        &:hover {
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
        }

        &:focus {
          box-shadow: 0 0 0 2px rgba(81, 170, 170, 0.2);
        }
      }
    }

    .input-delivery-date {
      .form-control {
        @extend %text-responsive;
        height: 45px;
        border-radius: 8px;
        padding: 0.5rem 1rem;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
        transition: all 0.2s ease;

        &:hover {
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
        }

        &:focus {
          box-shadow: 0 0 0 2px rgba(81, 170, 170, 0.2);
        }
      }
    }

    .group-hour-delivery {
      margin: 1.5rem 0;

      .input-delivery-hour {
        flex: 1;

        div {
          @extend %text-responsive;
          color: #666;
          margin-bottom: 0.5rem;
        }

        select {
          @extend %text-responsive;
          width: 100%;
          height: 45px;
          border-radius: 8px;
          padding: 0.5rem 1rem;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
          transition: all 0.2s ease;

          &:hover {
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
          }

          &:focus {
            box-shadow: 0 0 0 2px rgba(81, 170, 170, 0.2);
          }
        }

        &.text-responsive {
          @extend %text-responsive;
        }
      }

      .icon-cross {
        margin: 0 1rem;
        color: #666;
      }
    }

    .sub-item-option {
      p.text-responsive {
        @extend %text-responsive;
        color: #333;
        margin-bottom: 1rem;
        font-weight: 500;
      }

      .sub-item-info {
        margin-bottom: 1rem;

        .sub-txt {
          color: #666;
          margin-right: 0.5rem;
        }

        .form-control.text-responsive {
          @extend %text-responsive;
          height: 45px;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
          transition: all 0.2s ease;

          &:hover {
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
          }

          &:focus {
            box-shadow: 0 0 0 2px rgba(81, 170, 170, 0.2);
          }
        }
      }

      .btn-remove {
        cursor: pointer;
        opacity: 0.6;
        transition: opacity 0.2s;

        &:hover {
          opacity: 1;
        }
      }

      .btn-add-more {
        @extend %text-responsive;
        color: #51aaaa;
        padding: 0.5rem 0;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          color: darken(#51aaaa, 10%);
          text-decoration: underline;
        }
      }
    }

    .btn--org {
      @extend %text-responsive;
      width: 100%;
      height: 45px;
      border-radius: 8px;
      font-weight: 500;
      transition: all 0.2s ease;

      &.btn--org02 {
        background-color: #51aaaa;
        border: none;
        color: white;

        &:hover {
          background-color: darken(#51aaaa, 5%);
          transform: translateY(-1px);
        }

        &:active {
          transform: translateY(0);
        }
      }
    }
  }
}
