$primary-color: #15cdca;
$slate-color: #64748b;

@import '../../../styles/breakpoints.scss';
@import '../../../styles/padding-utility.scss';
@import '../../../styles/font-size-utility.scss';

@import './styles/contact-details.scss';
@import './styles/tabs.scss';
@import './styles/bill.scss';
@import './styles/payment-qrcode.scss';
@import './styles/payment-code-verified.scss';

#digistall {
  @media screen and (min-width: 1024px) {
    .payment-code-skeleton {
      max-width: 1200px;
    }
  }

  .payment-code {
    background: #edfbfb;
    color: $slate-color;
    font-size: 16px;
    padding-bottom: 50px;
    padding-top: 1rem;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: row;
    align-items: stretch;

    @media screen and (min-width: 1024px) {
      max-width: 1200px;
      flex-wrap: nowrap !important;
      justify-content: space-between;
      gap: 1rem;
    }

    @include respond-to('mobile') {
      flex-direction: column;
    }

    &__contact-summary {
      display: flex;
      flex-direction: column;
    }

    .payment-code__contact-summary,
    .payment-code__qr-code--wrapper {
      flex: 0 0 50% !important;
    }

    .contact-details-summary-skeleton,
    .contact-details-skeleton,
    .summary-skeleton,
    .qr-code-skeleton {
      width: 100%;
    }

    .qr-code-skeleton {
      margin-top: 2.4rem;
    }

    &__contact-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }

    &__title {
      font-weight: 600;
      margin-bottom: 0;
      text-transform: uppercase;
      color: $slate-color;

      @extend %title-responsive;
    }

    &__back-button {
      background: transparent;
      border: 0;
      display: flex;
      align-items: center;
      color: #495057;
      text-decoration: underline;
      @extend %text-responsive;
    }

    &__edit-button {
      text-decoration: underline;
      background: transparent;
      border: 0;
      color: $primary-color;

      @extend %text-responsive;
    }

    &__qr-code {
      background: white;
      // padding: 1rem;
      border-radius: 0.25rem;
      margin-top: 1rem;
      @extend %padding-responsive;

      @media screen and (min-width: 1024px) {
        width: 96%;
        margin-top: 2.4rem;
      }
    }
  }
}
