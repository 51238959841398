.select-payment-method {
  &__title {
    margin-bottom: 0.5rem;
  }

  &__options {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    .form-group {
      display: none;
    }

    @include respond-to('tablet') {
      flex-direction: row;
      gap: 1rem;
    }

    @include respond-to('desktop') {
      flex-direction: row;
      gap: 1rem;
    }
  }

  &__option {
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    position: relative;
    overflow: hidden;

    &.active {
      border: 2px solid #15cdca !important;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 0;
        border-left: 30px solid transparent;
        border-top: 30px solid #15cdca;
        rotate: 90deg;
      }

      &::before {
        content: '✔';
        position: absolute;
        bottom: -3px;
        right: 3px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        @include respond-to('mobile') {
          bottom: 2px;
        }
      }
    }

    @include respond-to('mobile') {
      width: 100%;
      margin-bottom: 0.5rem;
    }

    @include respond-to('tablet') {
      width: calc(50% - 0.5rem);
      margin-bottom: 0;
    }

    @include respond-to('desktop') {
      width: calc(50% - 1rem);
      margin-bottom: 0;
    }
  }

  &__option-content {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;

    @include respond-to('mobile') {
      height: 40px;
    }

    @include respond-to('tablet') {
      height: 50px;
    }

    @include respond-to('desktop') {
      height: 50px;
    }
  }

  &__label {
    width: 100%;
    padding: 0.5rem;
    font-weight: normal;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 100%;
  }

  &__logo {
    object-fit: contain;

    @include respond-to('mobile') {
      width: 150px;
      transform: translateX(-45px);
    }

    @include respond-to('tablet') {
      width: 180px;
      transform: translateX(-55px);
    }

    @include respond-to('desktop') {
      width: 200px;
      transform: translateX(-60px);
    }
  }

  &__icon-text {
    display: flex;
    align-items: center;
    transform: translateY(3px);
  }

  &__icon {
    margin-right: 0.5rem;
  }
}
