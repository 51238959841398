$primary-color: #15cdca;
$secondary-color: #fd6a57;
$border-color: #ddd;
$slate: #64748b;

@import './styles/shop-list.scss';
@import './styles/shop-item.scss';

@import '../../../styles/breakpoints.scss';

#digistall {
  .get-shop-opening {
    .bg-no-img {
      height: 250px;
    }

    .select-status {
      font-size: 16px;
      color: $secondary-color;
      width: 120px;
      border-radius: 8px;
      padding: 8px;
      transition: all 0.3s ease;

      &:focus {
        outline: none;
        border: 1px solid $secondary-color !important;
        box-shadow: 0 0 0 2px rgba(255, 105, 3, 0.2);
      }
    }

    .page-item.active .page-link {
      background-color: $secondary-color;
      border-color: $secondary-color;
    }

    .page-link {
      color: #51aaaa;

      &:focus {
        box-shadow: none;
      }
    }

    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      height: 60px;
    }

  }

  // Style cho search input
  .search-container {
    position: relative;
    // max-width: 600px;
    margin: 0 auto;

    .icon-wrapper {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 60px;
      background-color: $primary-color;
      border-radius: 0 12px 12px 0;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background-color: darken($primary-color, 5%);
      }

      .icon-search {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url('data:image/svg+xml,%3Csvg id=%27Symbol_62_7%27 data-name=%27Symbol 62 %E2%80%93 7%27 xmlns=%27http://www.w3.org/2000/svg%27 width=%2716.079%27 height=%2716%27 viewBox=%270 0 16.079 16%27%3E%3Crect id=%27Rectangle_176%27 data-name=%27Rectangle 176%27 width=%2716%27 height=%2716%27 fill=%27none%27/%3E%3Cpath id=%27Path_99%27 data-name=%27Path 99%27 d=%27M15.9,14.5l-3.3-3.3A6.847,6.847,0,0,0,14,7,6.957,6.957,0,0,0,7,0,6.957,6.957,0,0,0,0,7a6.957,6.957,0,0,0,7,7,6.847,6.847,0,0,0,4.2-1.4l3.3,3.3ZM2,7A4.951,4.951,0,0,1,7,2a4.951,4.951,0,0,1,5,5,4.951,4.951,0,0,1-5,5A4.951,4.951,0,0,1,2,7Z%27 transform=%27translate%280.179 0.067%29%27 fill=%27%23ffffff%27/%3E%3C/svg%3E%0A');
      }
    }

    .search-input {
      width: 100%;
      padding: 12px 48px 12px 20px; // Adjust right padding for icon
      border-radius: 12px;
      border: 2px solid #e0e0e0;
      background: white;
      font-size: 1rem;
      color: #2d3436;
      transition: all 0.3s ease;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);

      &:focus {
        border: 2px solid $primary-color !important;
        background: #fff;
        outline: none;
      }

      &::placeholder {
        color: #94a3b8;
      }

      @media screen and (max-width: 576px) {
        padding: 8px 40px 8px 16px;
        font-size: 0.875rem;
      }
    }
  }

  @include respond-to('tablet') {
    .logo {
      margin-bottom: 2rem;
    }
  }

  @media screen and (max-width: 576px) {
    .view-toggle-container {
      justify-content: center;
      margin-bottom: 0.75rem;

      .view-toggle-label {
        font-size: 0.85rem;
      }

      .view-toggle {
        .view-btn {
          width: 36px;
          height: 36px;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .shop-name {
      font-size: 16px !important;
      a {
        font-size: 1.1rem !important;
      }
    }

    .shop-description {
      font-size: 14px !important;
      font-size: 0.875rem !important;
      -webkit-line-clamp: 2;
    }

    .bg-no-img {
      height: 155px;
    }

    .shop-item {
      .carousel-item,
      .wrap-banner {
        img {
          height: 125px;
        }
      }

      .link-icons {
        font-size: 16px;
      }
    }

    .author {
      font-size: 12px;
    }
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
