.reset-password {
  .form-group {
    margin-top: 0px !important;
  }

  .c-txt-org,
  .c-txt-gray {
    margin-bottom: 5px;
  }
}
