$primary-color: #15cdca;

.tabs {
  flex: 1;
  color: $primary-color;

  .nav-tabs {
    margin-bottom: 1rem;
  }

  &__nav-item {
    text-align: center;
    cursor: pointer;
    flex: 1;

    @include respond-to('mobile') {
      font-size: 0.5rem;
    }

    @include respond-to('tablet') {
      font-size: 0.75rem;
    }
  }

  &__nav-link {
    font-weight: 600;
    border-radius: 0;
    width: 100%;
    border: 1px solid #e9e9e9 !important;
    background-color: white;

    &:hover {
      color: #679e9c;
    }

    &--inactive {
      opacity: 0.7;
    }

    a {
      width: inherit;
    }

    span,
    svg {
      color: #64748b;
    }
  }

  &__nav-link--active {
    background-color: #edfbfb;
    color: $primary-color;
    border: 1px solid #edfbfb;

    span,
    svg {
      color: $primary-color;
    }
  }

  &__tab-content {
    padding-top: 1rem;

    @include respond-to('mobile') {
      padding-top: 0.5rem;
    }

    @include respond-to('tablet') {
      padding-top: 1rem;
    }

    @include respond-to('desktop') {
      padding-top: 1.5rem;
    }

    &__tab-pane {
      background-color: white;
      color: #919192;
    }
  }
}
