#digistall {
  .get-shop-opening {
    .shop-list {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 24px 0;
    }

    .skeleton-item {
      .skeleton {
        background: linear-gradient(
          90deg,
          rgba(226, 232, 240, 0.8) 25%,
          rgba(226, 232, 240, 0.4) 37%,
          rgba(226, 232, 240, 0.8) 63%
        );
        background-size: 400% 100%;
        animation: skeleton-loading 1.4s ease infinite;
      }

      .wrap-banner.skeleton {
        height: 200px;
        width: 100%;
      }

      .shop-name.skeleton {
        height: 24px;
        width: 70%;
        margin-bottom: 1rem;
        border-radius: 4px;
      }

      .shop-description {
        .skeleton {
          height: 16px;
          margin-bottom: 0.5rem;
          border-radius: 4px;

          &:first-child {
            width: 90%;
          }
          &:last-child {
            width: 75%;
          }
        }
      }

      .social-link.skeleton {
        width: 32px;
        height: 34px;
        border-radius: 8px;
      }

      .author-avatar.skeleton {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }

    @keyframes skeleton-loading {
      0% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0 50%;
      }
    }
  }
}
