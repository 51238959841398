$breakpoints: (
  'mobile': 320px,
  'tablet': 768px,
  'desktop': 1024px,
);

// Mixins
@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @if $breakpoint == 'desktop' {
      @media screen and (min-width: map-get($breakpoints, $breakpoint)) {
        @content;
      }
    } @else {
      @media screen and (min-width: map-get($breakpoints, $breakpoint)) and (max-width: map-get($breakpoints, if($breakpoint == 'mobile', 'tablet', if($breakpoint == 'tablet', 'desktop', null)))) {
        @content;
      }
    }
  }
}
